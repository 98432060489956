import { Column, Row } from '@yolaw/ui-kit-components';
import { ChevronLeft, CloseFlat } from '@yolaw/ui-kit-icons';
import React, { useEffect } from 'react';
import styled, { css } from 'styled-components';
import { CONTENT_MAX_WIDTH } from '../../constants';
import { ModalName } from '../../contexts/app';
import useZenApp from '../../hooks/useZenApp';

const MainContainer = styled.div`
  ${({ theme }) => css`
    .modal-container:before {
      content: '';
      display: none;
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 10;

      @media (min-width: ${theme.breakpoints.m}px) {
        background-color: rgba(0, 0, 0, 0.6);
      }
    }
    .opened:before {
      display: block;
    }
    .opened .modal-dialog {
      opacity: 1;

      @media (min-width: ${theme.breakpoints.m}px) {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }

      @media (max-width: ${theme.breakpoints.m}px) {
        top: 0;
        left: 0;
      }
    }
    .modal-dialog {
      background: #fff;
      position: fixed;
      top: -100%;
      max-width: ${CONTENT_MAX_WIDTH}px;
      z-index: 11;
      opacity: 0;
      transition: opacity 0.3s ease-out;

      @media (min-width: ${theme.breakpoints.m}px) {
        border-radius: ${theme.borderRadius.m}px;
        padding: ${theme.spacing.l}px;
        max-height: 80%;
      }

      @media (max-width: ${theme.breakpoints.m}px) {
        padding: ${theme.spacing.xs}px;
        height: 100%;
        width: 100%;
      }
    }
    .modal-body {
      overflow: auto;
      padding: 0 ${theme.spacing.xxs}px;
    }
  `}
`;

const BackIcon = styled(ChevronLeft)`
  visibility: hidden;
  ${({ onClick }) =>
    onClick &&
    css`
      visibility: visible;
      cursor: pointer;
    `}
`;

const CloseIcon = styled(CloseFlat)`
  cursor: pointer;
`;

type BaseModalProps = {
  id: ModalName;
  HeaderTag?: React.ReactNode;
  onClickBack?: VoidFunction;
  /** Callback when the modal is closed */
  onClose?: VoidFunction;
};

const BaseModal = ({
  id,
  children,
  HeaderTag,
  onClickBack,
  onClose
}: React.PropsWithChildren<BaseModalProps>) => {
  const app = useZenApp();

  const closeModal = () => {
    if (onClose) onClose();
    app.modal.closeModal();
  };

  useEffect(() => {
    if (app.state.openingModal?.name) {
      // Prevent the body from being scrolled when the modal is visible.
      document.body.style.setProperty('overflow', 'hidden');
    } else {
      document.body.style.setProperty('overflow', 'unset');
    }
  }, [app.state.openingModal?.name]);

  return (
    <MainContainer>
      <div id={id} className="modal-container" aria-hidden="true">
        <Column className="modal-dialog">
          <Row className="modal-header">
            <BackIcon onClick={onClickBack} />
            {HeaderTag}
            <CloseIcon onClick={closeModal} />
          </Row>
          <div className="modal-body">{children}</div>
        </Column>
      </div>
    </MainContainer>
  );
};

export default React.memo(BaseModal);
