import { AxiosResponse } from 'axios';
import zenstartApi from '../legalzenApi';

// Project onboarding
const get = (projectId: number): Promise<AxiosResponse<OnboardingQuestionnaire>> =>
  zenstartApi.get(`/projects/${projectId}/onboarding/`);

const patch = (
  projectId: number,
  data: Partial<OnboardingQuestionnaire>
): Promise<AxiosResponse<QuestionnaireAnswers>> =>
  zenstartApi.patch(`/projects/${projectId}/onboarding/`, data);

const OnboardingApiEndpoint = {
  get,
  patch
};

export default OnboardingApiEndpoint;
