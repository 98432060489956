import { Text } from '@yolaw/ui-kit-components';
import useProject from '@zen/hooks/useProject';
import moment from 'moment';
import React from 'react';
import styled from 'styled-components';
import useTask, { TaskItemExtended } from '../../hooks/useTask';

moment.locale('fr', {
  weekdays: ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'],
  calendar: {
    sameDay: '[Aujourd’hui]',
    nextDay: '[Demain]',
    nextWeek: 'dddd',
    lastDay: '[Hier]',
    lastWeek: 'dddd [dernier]',
    sameElse: 'DD/MM/YYYY'
  }
});

const DueDate = styled.time`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${(props) => props.theme.spacing.xxxxs}px;
`;

type TaskCardDateProps = {
  taskId: TaskItem['id'];
};

const getFormattedDate = (task: TaskItem, hasCompanySiren: boolean) => {
  if (task.is_done) return "C'est fait !";
  if (!hasCompanySiren) return null;
  if (!task.due_date) return '-';
  return moment(task.due_date).calendar();
};

const getTextColor = (task: TaskItemExtended) => {
  const { is_done, isOverDue } = task;
  if (is_done) return 'secondary.main';
  if (isOverDue) return 'error.dark';
  return 'secondary.main';
};

const TaskCardDate: React.FC<TaskCardDateProps> = ({ taskId }) => {
  const project = useProject();
  const task = useTask(taskId);

  const visibleDate = getFormattedDate(task, !!project.info.company_siren);

  return visibleDate ? (
    <DueDate className="date-alignment">
      <Text type="SMALL" color={getTextColor(task)} fontWeightVariant="bold">
        {visibleDate}
      </Text>
    </DueDate>
  ) : null;
};

export default React.memo(TaskCardDate);
