import { Button, Container, Text, Title } from '@yolaw/ui-kit-components';
import React from 'react';
import { useNavigate } from 'react-router-dom';

const Succeed = () => {
  const navigate = useNavigate();

  const navigateToDashboard = () => navigate('/', { replace: true });

  const handleCtaClick = () => {
    navigateToDashboard();
  };

  return (
    <>
      <Title type="H2" color="secondary.main">
        🎉 Super, vous avez généré et téléchargé votre convention de mise à disposition !
      </Title>
      <Container size="S">
        <Text type="BODY" color="neutral.dark">
          💡 Imprimez votre convention et archivez-la dans vos documents. Elle vous sera notamment
          utile en cas de contrôle de l’administration.
        </Text>
      </Container>

      <Container size="XS">
        <Button text="C'est fait !" onClick={handleCtaClick} />
      </Container>
    </>
  );
};

export default Succeed;
