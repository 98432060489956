import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import React, { useEffect } from 'react';
import useProject from '../../../../hooks/useProject';
import ComponentProcessor from '../utils/componentProcessor';

/**
 * Specific methods to compare string values
 */
const parseToStringThenCompare = (value: any, operatorString: string, compareValue?: string) => {
  let strValue = value;
  let strCompareValue = compareValue;

  if (typeof value !== 'string') {
    strValue = JSON.stringify(value);
  }

  if (strValue === undefined) {
    return false;
  }

  if (typeof compareValue !== 'string') {
    strCompareValue = JSON.stringify(compareValue);
  }

  switch (operatorString) {
    case 'STARTS_WITH':
      return strValue.startsWith(strCompareValue);
    case 'NOT_STARTS_WITH':
      return !strValue.startsWith(strCompareValue);
    case 'INCLUDES':
      return strValue.includes(strCompareValue);
    case 'NOT_INCLUDES':
      return !strValue.includes(strCompareValue);
    case 'ENDS_WITH':
      return strValue.endsWith(strCompareValue);
    case 'NOT_ENDS_WITH':
      return !strValue.endsWith(strCompareValue);
    default:
      return false;
  }
};

/** Product Builder considers a variable is empty if its value is
 * - either an empty string `''`
 * - or `null`
 * - or `undefined`
 */
const isEmptyValue = (value: any) => value === '' || value === null || value === undefined;

const operatorHasOneOperand = new Set(['EMPTY', 'FILLED']);

const singleConditionalChecker = (condition: string, answers: QuestionnaireAnswers) => {
  // Parse the `condition` string into
  const [dataKey, operatorString, ...remains] = condition.split(' ');
  if (!dataKey || !operatorString) return false;

  const compareValue = remains.length ? remains.join(' ') : undefined;
  // If it missing operand (compareValue) in an operation needs 2 sides operands
  if (!compareValue && !operatorHasOneOperand.has(operatorString)) return false;

  // Get the value to compare from Funnel context
  const stateValue = _get(answers, dataKey, undefined);

  switch (operatorString) {
    case '===': // DEPRECATED: To make ProductBuilders life easier, this JS `===` will be removed in the future
    case '==':
    case 'EQUAL':
      // eslint-disable-next-line eqeqeq
      return stateValue == compareValue;
    case '!==': // DEPRECATED: To make ProductBuilders life easier, this JS `!==` will be removed in the future
    case '!=':
    case 'NOT_EQUAL':
      // eslint-disable-next-line eqeqeq
      return stateValue != compareValue;
    case '>':
    case 'GREATER_THAN':
      return compareValue && stateValue > compareValue;
    case '<':
    case 'SMALLER_THAN':
      return compareValue && stateValue < compareValue;
    case '>=':
    case 'GREATER_THAN_OR_EQUAL':
      return compareValue && stateValue >= compareValue;
    case '<=':
    case 'SMALLER_THAN_OR_EQUAL':
      return compareValue && stateValue <= compareValue;

    case 'EMPTY':
      return isEmptyValue(stateValue);
    case 'FILLED':
      return !isEmptyValue(stateValue);

    case 'STARTS_WITH':
    case 'NOT_STARTS_WITH':
    case 'INCLUDES':
    case 'NOT_INCLUDES':
    case 'ENDS_WITH':
    case 'NOT_ENDS_WITH':
      return parseToStringThenCompare(stateValue, operatorString, compareValue);
    default:
      return false;
  }
};

/**
 * Check whether the given condition is matched
 */
const conditionalChecker = (
  conditions: ConditionalBlockProps['condition'],
  answers: QuestionnaireAnswers
) => {
  if (typeof conditions === 'string') {
    return singleConditionalChecker(conditions, answers);
  }

  if (typeof conditions === 'object' && !Array.isArray(conditions)) {
    // If an AND array of condition is defined
    if (Array.isArray(conditions.AND) && conditions.AND.length) {
      return conditions.AND.every((condition) => singleConditionalChecker(condition, answers));
    }

    // If an OR array of condition is defined
    if (Array.isArray(conditions.OR) && conditions.OR.length) {
      return conditions.OR.some((condition) => singleConditionalChecker(condition, answers));
    }
  }

  return false;
};

type ConditionObject = {
  AND: string[];
  OR: string[];
};
export type ConditionalBlockProps = {
  /** The behavior to be applied if the `condition` is matched */
  behavior: 'display-if' | 'hide-if';
  /** conditional operator using fields name from funnel data key */
  condition: string | ConditionObject;
  /** elements */
  children: JSX.Element;
};

const ConditionalBlock = ({ behavior, condition, children }: ConditionalBlockProps) => {
  const {
    onboardingQuestionnaire: { answers }
  } = useProject();

  //  Check whether the condition is matched
  const isConditionMatched = conditionalChecker(condition, answers);

  const shouldComponentDisplay =
    (behavior === 'display-if' && isConditionMatched) ||
    (behavior === 'hide-if' && !isConditionMatched);

  // Set input default value taken from Funnel state
  useEffect(() => {
    if (shouldComponentDisplay && !_isEmpty(answers) && children) {
      ComponentProcessor.injectChildrenData(children, answers);
    }
  }, [shouldComponentDisplay]);

  if (!shouldComponentDisplay) {
    return <></>;
  }

  return children;
};

export default ConditionalBlock;
