import { Button, Column, Text, Title } from '@yolaw/ui-kit-components';
import React from 'react';
import styled from 'styled-components';
import { ModalName } from '../../../contexts/app';
import BaseModal from '../BaseModal';
import HourglassImage from '@zen/assets/svgs/hourglass.svg';
import useZenApp from '../../../hooks/useZenApp';

const BodyContainer = styled(Column)`
  align-items: center;
  text-align: center;
`;

const IntroductionPage = () => {
  const app = useZenApp();

  const closeModal = () => {
    app.modal.closeModal();
  };

  return (
    <>
      <img src={HourglassImage} width="50px" alt="hourglass" />

      <Title type="H2">En attente de votre numéro SIREN</Title>

      <Text type="BODY">Cette tâche sera disponible une fois que votre société sera créée.</Text>

      <Text type="BODY">
        Son contenu sera personnalisé selon les informations de votre société.
      </Text>

      <Button onClick={closeModal}>J’ai compris !</Button>
    </>
  );
};

const WaitingSirenModal = () => {
  return (
    <BaseModal id={ModalName.WaitingForSiren}>
      <BodyContainer>
        <IntroductionPage />
      </BodyContainer>
    </BaseModal>
  );
};

export default WaitingSirenModal;
