import { Label, Row } from '@yolaw/ui-kit-components';
import { ArrowRight } from '@yolaw/ui-kit-icons';
import React from 'react';
import styled from 'styled-components';
import { ELEMENT_IDS } from '../../../constants';
import { useZenRoutes } from '../../../hooks';

const TopBarContainer = styled(Row)`
  background-color: white;
  width: 100%;
  padding: ${({ theme }) => theme.spacing.xs}px;
  position: sticky;
  top: 0;
  z-index: 10;
`;

const BackButton = styled(Row)`
  align-items: center;
  gap: ${({ theme }) => theme.spacing.xxxs}px;

  .back-button-icon {
    transform: rotate(180deg);
  }

  * {
    cursor: pointer;
  }
`;
const TaskNavigator = () => {
  const { goToDashboard } = useZenRoutes();
  const onClickBack = () =>
    goToDashboard({ searchParams: '?utm_source=task', navigateOptions: { replace: true } });

  return (
    <TopBarContainer id={ELEMENT_IDS.TASK_VIEW.NAVIGATOR}>
      <BackButton onClick={onClickBack}>
        <ArrowRight className="back-button-icon" color="currentColor" />
        <Label sizeVariant="LARGE" fontWeightVariant="bold">
          Retour
        </Label>
      </BackButton>
    </TopBarContainer>
  );
};

export default TaskNavigator;
