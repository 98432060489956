export enum PaymentOption {
  UseSavedCard = 'USE_SAVED_CARD',
  NewPaymentMethod = 'NEW_PAYMENT_METHOD'
}

export enum PaymentMethod {
  Card = 'CARD',
  GooglePay = 'GOOGLE_PAY',
  ApplePay = 'APPLE_PAY'
}
