import _get from 'lodash/get';
import Handlebars from 'handlebars';
import React from 'react';
import { isEqualString } from '@zen/utils/string';

const SUPPORTED_DYNAMIC_CONTENT_COMPONENTS = ['Title', 'Text', 'Accent'];

/** Check and build semantic text template for component if it's supported */
const _buildSemanticTextTemplateComponent = (
  component: JSX.Element,
  data: QuestionnaireAnswers
) => {
  const { component: componentName, text: componentTextContent } = component.props;

  if (SUPPORTED_DYNAMIC_CONTENT_COMPONENTS.includes(componentName) && componentTextContent) {
    const textTemplate = Handlebars.compile(componentTextContent);
    const textResult = textTemplate(data);
    return React.cloneElement(component, { text: textResult });
  }

  // It's not supported
  return component;
};

let answerDataKey = '';
const componentSupportsDefaultValue = ['TextField', 'Toggle'];

const injectChildrenData = (
  children: JSX.Element,
  data: QuestionnaireAnswers
): { answerDataKey?: string; processedComponents: JSX.Element[] } => {
  const processedComponents = React.Children.map(children, (child) => {
    const {
      component: componentName,
      defaultValue: componentDefaultValue,
      name: componentDataKey
    } = child.props;

    // Remove the MoreInfo component as it should be handled separately in `extractMoreInfoComponent`
    if (componentName === 'MoreInfo') {
      return null;
    }

    if (!child.props.children) {
      if (componentDataKey) {
        // Extract the only answer data key in page
        answerDataKey = componentDataKey;

        // Get previously filled value/answer
        const previousAnswerValue = _get(data, componentDataKey);

        // Input element should be controlled on uncontrolled so set either `value` or `defaultValue`, not both
        const valueProps = componentSupportsDefaultValue.includes(componentName)
          ? {
              defaultValue: previousAnswerValue || componentDefaultValue
            }
          : {
              value: previousAnswerValue
            };

        const newElement = React.cloneElement(child, {
          ...valueProps,
          // defaultValue set from yaml can be integer value
          // => it's safer to check and convert it to string if needed before comparing
          defaultChecked: isEqualString(previousAnswerValue, componentDefaultValue)
        });
        return newElement;
      }
      return _buildSemanticTextTemplateComponent(child, data);
    }

    // Component contains some children => diving deeper into the children
    return React.cloneElement(child, {
      children: injectChildrenData(child.props.children, data).processedComponents
    });
  });

  return { answerDataKey, processedComponents };
};

const extractMoreInfoComponent = (children: JSX.Element | JSX.Element[]): JSX.Element[] => {
  return React.Children.map(children, (child) => {
    if (child.props.component === 'MoreInfo') {
      return child;
    }

    if (child.props.children) {
      extractMoreInfoComponent(child.props.children);
    }

    return null;
  });
};

const ComponentProcessor = {
  injectChildrenData,
  extractMoreInfoComponent
};

export default ComponentProcessor;
