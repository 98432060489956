import { Accent, Button, Row, Text, Title } from '@yolaw/ui-kit-components';
import React from 'react';
import styled, { css } from 'styled-components';
import { PaymentModalStep } from '../context';
import { usePaymentModalContext } from '../hooks';

import TranslatorUtils from '@zen/utils/translator';
import img2 from './assets/Mask group-1.png';
import img3 from './assets/Mask group-2.png';
import img1 from './assets/Mask group.png';

const FeaturesContainer = styled(Row)`
  ${({ theme }) => css`
    .benefit-item {
      box-shadow: ${theme.shadows.blue.small};
      border-radius: ${theme.borderRadius.m}px;
      overflow: hidden;

      .benefit-item_image-container {
        background-color: #f9fcff;
        img {
          max-width: 100%;
          max-height: 135px;
        }
      }

      .benefit-item_text-container {
        padding: ${theme.spacing.xxxs}px ${theme.spacing.s}px;
        > :not(:last-child) {
          margin-bottom: ${theme.spacing.xxxs}px;
        }
      }
    }

    @media (min-width: ${theme.breakpoints.m}px) {
      gap: ${theme.spacing.l}px;
      .benefit-item {
        width: 30%;
      }
    }

    @media (max-width: ${theme.breakpoints.m}px) {
      flex-wrap: wrap;
      gap: ${theme.spacing.m}px;

      .benefit-item {
        width: 100%;
      }
    }
  `}
`;

type BenefitItemProps = {
  imgSrc: string;
  title: string;
  description: string;
};
const BenefitItem = ({ imgSrc, title, description }: BenefitItemProps) => {
  return (
    <div className="benefit-item">
      <div className="benefit-item_image-container">
        <img src={imgSrc} alt="benefit-thumbnail" />
      </div>
      <div className="benefit-item_text-container">
        <Title type="h4">{title}</Title>
        <Text type="body">{description}</Text>
      </div>
    </div>
  );
};

const IntroductionPage = () => {
  const paymentModal = usePaymentModalContext();

  const monthlyPlan = paymentModal.action.getPlan('monthly');

  const goToPlanSelectionPage = () => {
    paymentModal.action.setPaymentModalStep(PaymentModalStep.PlanSelection);
  };

  return (
    <>
      <Title type="H3">
        Débloquez tout le contenu de <Accent color="accentuation.dark">Zen</Accent> pour seulement{' '}
        <Accent color="accentuation.dark">
          {monthlyPlan?.price_et || 0} €&nbsp;<sup>HT</sup>&nbsp;/
          {TranslatorUtils.translate(monthlyPlan?.period_unit)}
        </Accent>
      </Title>
      <FeaturesContainer>
        <BenefitItem
          imgSrc={img1}
          title="Gérez toutes vos obligations"
          description="Accédez à tous nos guides, rappels et commandez vos registres obligatoires"
        />
        <BenefitItem
          imgSrc={img2}
          title="Participez aux webinaires"
          description="Différentes sessions hebdomadaires sur des thèmes tel que la rémunération"
        />
        <BenefitItem
          imgSrc={img3}
          title="Générez vos documents"
          description="Téléchargez vos docs personnalisés  automatiquement dans Zen"
        />
      </FeaturesContainer>

      <Button rightIcon="ArrowRight" onClick={goToPlanSelectionPage}>
        Choisir ma formule
      </Button>
    </>
  );
};

export default IntroductionPage;
