import Subscription from '@zen/types/subscription';

const INACTIVE_STATUSES = [
  Subscription.SubscriptionStatus.Canceled,
  Subscription.SubscriptionStatus.IncompleteExpired,
  Subscription.SubscriptionStatus.Freemium
];

const ACTIVE_STATUSES = Object.values(Subscription.SubscriptionStatus).filter(
  (status) => !INACTIVE_STATUSES.includes(status)
);

const isActiveStatus = (status: Subscription.SubscriptionStatus | ProjectSubscriptionStatus) =>
  ACTIVE_STATUSES.includes(status as Subscription.SubscriptionStatus);

const SubscriptionUtils = {
  isActiveStatus
};

export default SubscriptionUtils;
