import { Container, RadioButtons, Text, Title } from '@yolaw/ui-kit-components';
import React from 'react';
import useTask from '../../../../../hooks/useTask';

const AskForPostponeReason = () => {
  const task = useTask();

  const handlePostponeSelection = async () => {
    await task.handleTaskAction('unemployment_postpone');
  };

  const handleAlreadyDoneSelection = async () => {
    await task.handleTaskAction('unemployment_already_done');
  };

  const handleUpdateSituationSelection = async () => {
    await task.handleTaskAction('unemployment_cancel');
  };

  const handleChange = (event: any) => {
    switch (event.target.value) {
      case 'postpone':
        return handlePostponeSelection();
      case 'already_done':
        return handleAlreadyDoneSelection();
      case 'update_situation':
        return handleUpdateSituationSelection();
    }
  };

  return (
    <>
      <Title type="H2" color="secondary.main">
        Pourquoi n’avez-vous pas besoin de votre attestation de non rémunération ?
      </Title>
      <Container size="S">
        <Text type="BODY" color="neutral.dark">
          ⚠️ Pour toucher vos indemnités Pôle-Emploi, l’attestation de non-rémunération est
          obligatoire.
        </Text>
      </Container>
      <form onChange={handleChange}>
        <RadioButtons
          id="postpone_reason_selector"
          name="postpone_reason"
          radioType="tile"
          options={[
            {
              title: 'Je veux le faire plus tard',
              value: 'postpone'
            },
            {
              title: 'J’ai fait ma propre attestation',
              value: 'already_done'
            },
            {
              title: 'Je ne bénéficie plus du chômage',
              value: 'update_situation'
            }
          ]}
        />
      </form>
    </>
  );
};

export default AskForPostponeReason;
