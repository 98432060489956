import { AxiosResponse } from 'axios';
import Subscription from '@zen/types/subscription';
import coreApi from '../coreApi';

const getSubscriptionDetails = (
  subscriptionTypeSlug: string
): Promise<AxiosResponse<Subscription.SubscriptionItem>> =>
  coreApi.get(`/subscriptions/types/${subscriptionTypeSlug}/`);

const getPlanDetails = (
  subscriptionTypeSlug: string,
  planSlug: string
): Promise<AxiosResponse<Subscription.Plan>> =>
  coreApi.get(`/subscriptions/types/${subscriptionTypeSlug}/plans/${planSlug}`);

const initiateStripePayment = (
  data: Subscription.StripePayment.InitialRequestData
): Promise<AxiosResponse<Subscription.StripePayment.InitialRequestResponse>> =>
  coreApi.post('/subscriptions/payment/stripe/initiate/', data);

const confirmStripePayment = (
  data: Subscription.StripePayment.ConfirmRequestData
): Promise<AxiosResponse<Subscription.StripePayment.SucceedResponse>> =>
  coreApi.post('/subscriptions/payment/stripe/verify/', data);

const SubscriptionApiEndpoint = {
  getSubscriptionDetails,
  getPlanDetails,
  initiateStripePayment,
  confirmStripePayment
};

export default SubscriptionApiEndpoint;
