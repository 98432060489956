import {
  Accent,
  Column,
  Heading,
  Hint,
  HintType,
  Label,
  Row,
  Text,
  Title
} from '@yolaw/ui-kit-components';
import { LockFlat } from '@yolaw/ui-kit-icons';
import TranslatorUtils from '@zen/utils/translator';
import React from 'react';
import styled, { useTheme } from 'styled-components';
import NumberUtils from '../../../../../utils/number';
import { usePaymentModalContext } from '../../hooks';
import PayButton from './PayButton';
import PaymentMethodIcons from './PaymentMethodIcons';
import PaymentMethodSelector from './PaymentMethodSelector';

const StyledHeading = styled(Heading)`
  margin: 0;
`;

const PayWithContainer = styled(Row)`
  align-items: center;

  @media (max-width: ${({ theme }) => theme.breakpoints.m}px) {
    align-items: flex-start;
    flex-direction: column;
    row-gap: 0;
  }
`;

const StyledLabel = styled(Label)`
  text-align: center;
`;

const PadlockIcon = styled(LockFlat)`
  vertical-align: top;
`;

const TOSLink = styled.a`
  color: ${({ theme }) => theme.colors.neutral.light};
`;

const StyledErrorBox = styled(Hint)`
  text-align: left;
`;

const PayButtonContainer = styled.div`
  text-align: center;
  margin: auto;

  button {
    width: 100%;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.m}px) {
    width: 50%;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.m}px) {
    width: 100%;
  }
`;

const PaymentPage = () => {
  const theme = useTheme();
  const paymentModal = usePaymentModalContext();

  return (
    <>
      <Title type="H3">
        M&apos;abonner à <Accent color="accentuation.dark">Zen</Accent>
      </Title>

      <Column style={{ rowGap: `${theme.spacing.s}px` }}>
        <PayWithContainer>
          <StyledHeading type="H4">Payer avec</StyledHeading>
          <PaymentMethodIcons />
        </PayWithContainer>

        <StyledLabel fontWeightVariant="bold">
          <PadlockIcon /> SSL Secure Connection
        </StyledLabel>

        <PaymentMethodSelector />

        <Column>
          {paymentModal.state.paymentError && (
            <StyledErrorBox
              type={HintType.Error}
              description="Veuillez recommencer ou utiliser un autre moyen de paiement."
            >
              {paymentModal.state.paymentError.message ||
                'Il semblerait que votre paiement ai échoué'}
            </StyledErrorBox>
          )}

          <Text type="small" color="neutral.dark" textAlign="left">
            En cliquant sur &quot;M&apos;abonner à Zen&quot; vous attestez avoir lu et accepté les{' '}
            <TOSLink
              href="https://www.legalstart.fr/corp/cgu/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Conditions Générales
            </TOSLink>{' '}
            et la{' '}
            <TOSLink
              href="https://www.legalstart.fr/corp/vie-privee/"
              target="_blank"
              rel="noopener noreferrer"
            >
              charte de la vie privée
            </TOSLink>
            .
          </Text>

          <Text type="BODY" fontWeightVariant="bold" color="accentuation.dark">
            {NumberUtils.currencyFormat(paymentModal.selectedPlan?.price_et || 0)}&nbsp;
            <sup>HT</sup>&nbsp;/
            {TranslatorUtils.translate(paymentModal.selectedPlan?.period_unit)}, soit{' '}
            {NumberUtils.currencyFormat(paymentModal.selectedPlan?.price_it || 0)}&nbsp;
            <sup>TTC</sup>&nbsp;/
            {TranslatorUtils.translate(paymentModal.selectedPlan?.period_unit)}
          </Text>

          <PayButtonContainer>
            <PayButton />
          </PayButtonContainer>
        </Column>
      </Column>
    </>
  );
};

export default React.memo(PaymentPage);
