import ServicesApiEndpoints from '../endpoints/services';
import { commonApiErrorHandler } from './errors';

const triggerEvent = async (serviceId: number, eventSlug: string) => {
  try {
    const response = await ServicesApiEndpoints.triggerEvent(serviceId, eventSlug);
    return response.data;
  } catch (error) {
    return commonApiErrorHandler(error);
  }
};

const ServicesApiHandler = {
  triggerEvent
};

export default ServicesApiHandler;
