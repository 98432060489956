import { generatePath, NavigateOptions, useMatch, useNavigate, useParams } from 'react-router-dom';

import { PATH_PARAMS, PATH_TEMPLATES, TPathParams } from '../services/routes';

type ZenNavigateOptions = {
  searchParams?: URLSearchParams | string;
  navigateOptions?: NavigateOptions;
};

const useZenRoutes = () => {
  const navigate = useNavigate();
  const { projectId, taskId } = useParams<TPathParams>();

  const isOnboardingQuestionnaire = !!useMatch(`${PATH_TEMPLATES.ONBOARDING}/*`);

  const currentTaskPath = taskId
    ? generatePath(PATH_TEMPLATES.TASK_VIEW, {
        projectId,
        taskId: String(taskId)
      })
    : null;

  const _navigateWithOptions = (generatedPath: string, options?: ZenNavigateOptions) => {
    let finalPath = generatedPath;

    if (options?.searchParams) {
      if (typeof options.searchParams === 'string') {
        finalPath += options.searchParams;
      } else {
        const searchString = options.searchParams.toString();
        finalPath += `?${searchString}`;
      }
    }

    navigate(finalPath, options?.navigateOptions);
  };

  const goToDashboard = (options?: ZenNavigateOptions) => {
    const dashboardPath = generatePath(PATH_TEMPLATES.BASE, {
      [PATH_PARAMS.PROJECT_ID]: projectId
    });
    _navigateWithOptions(dashboardPath, options);
  };

  const goToOnboardingQuestionnaire = (options?: ZenNavigateOptions) => {
    const onboardingPath = generatePath(PATH_TEMPLATES.ONBOARDING, {
      [PATH_PARAMS.PROJECT_ID]: projectId
    });
    _navigateWithOptions(onboardingPath, options);
  };

  const goToTask = (destTaskId: TaskItem['id'], options?: ZenNavigateOptions) => {
    const taskPath = generatePath(PATH_TEMPLATES.TASK_VIEW, {
      projectId,
      taskId: String(destTaskId)
    });
    _navigateWithOptions(taskPath, options);
  };

  return {
    projectId,
    taskId,
    isOnboardingQuestionnaire,
    currentTaskPath,
    goToDashboard,
    goToOnboardingQuestionnaire,
    goToTask
  };
};

export default useZenRoutes;
