import UserApiEndpoint from '../endpoints/user';
import { commonApiErrorHandler } from './errors';

const getPaymentMethods = async () => {
  try {
    const response = await UserApiEndpoint.getPaymentMethods();
    return response.data;
  } catch (error) {
    return commonApiErrorHandler(error);
  }
};

const getUserInfo = async () => {
  try {
    const response = await UserApiEndpoint.getUserInfo();
    return response.data;
  } catch (error) {
    return commonApiErrorHandler(error);
  }
};

const refreshToken = async (rfToken: string) => {
  try {
    const response = await UserApiEndpoint.refreshToken(rfToken);
    return response.data;
  } catch (error) {
    return commonApiErrorHandler(error);
  }
};

const UserApiHandler = {
  getPaymentMethods,
  getUserInfo,
  refreshToken
};

export default UserApiHandler;
