import { PaymentMethod, PaymentOption } from '@zen/services/payment';
import LSUser from '@zen/types/user';
import React from 'react';
import Subscription from '@zen/types/subscription';
import { ZenPaymentError } from '../../../hooks/useZenPayment';

export enum PaymentModalStep {
  Introduction = 'INTRODUCTION',
  PlanSelection = 'PLAN_SELECTION',
  Payment = 'PAYMENT'
}

type PaymentModalContextState = {
  cardInputsValidity: boolean;
  currentModalStep: PaymentModalStep;
  isReadyToPay: boolean;
  paymentError: ZenPaymentError | null;
  paymentMethod: PaymentMethod;
  paymentOption: PaymentOption;
  savedPaymentMethods: LSUser.PaymentMethodItem[] | null;
  selectedCard: LSUser.PaymentMethodItem | null;
  selectedPlanSlug: Subscription.Plan['slug'] | null;
  zenSubscription: Subscription.SubscriptionItem | null;
};

const initialState: PaymentModalContextState = {
  cardInputsValidity: false,
  currentModalStep: PaymentModalStep.Introduction,
  isReadyToPay: false,
  paymentError: null,
  paymentMethod: PaymentMethod.Card,
  paymentOption: PaymentOption.NewPaymentMethod,
  savedPaymentMethods: null,
  selectedCard: null,
  selectedPlanSlug: null,
  zenSubscription: null
};

export enum PaymentModalContextAction {
  SetCardInputValidity = 'SET_CARD_INPUT_VALIDITY',
  SetIsReadyToPay = 'SET_IS_READY_TO_PAY',
  SetPaymentError = 'SET_PAYMENT_ERROR',
  SetPaymentMethod = 'SET_PAYMENT_METHOD',
  SetPaymentOption = 'SET_PAYMENT_OPTION',
  SetSavedPaymentMethods = 'SET_SAVED_PAYMENT_METHODS',
  SetSelectedCard = 'SET_SELECTED_CARD',
  SetPaymentModalStep = 'SET_PAYMENT_MODAL_STEP',
  SetSelectedPlanSlug = 'SET_SELECTED_PLAN_SLUG',
  SetZenSubscriptionDetails = 'SET_ZEN_SUBSCRIPTION_DETAILS'
}

type SetCardInputValidityAction = {
  type: PaymentModalContextAction.SetCardInputValidity;
  payload: PaymentModalContextState['cardInputsValidity'];
};

type SetIsReadyToPayAction = {
  type: PaymentModalContextAction.SetIsReadyToPay;
  payload: PaymentModalContextState['isReadyToPay'];
};

type SetPaymentErrorAction = {
  type: PaymentModalContextAction.SetPaymentError;
  payload: PaymentModalContextState['paymentError'];
};

type SetPaymentMethodAction = {
  type: PaymentModalContextAction.SetPaymentMethod;
  payload: PaymentModalContextState['paymentMethod'];
};

type SetPaymentOptionAction = {
  type: PaymentModalContextAction.SetPaymentOption;
  payload: PaymentModalContextState['paymentOption'];
};

type SetSavedPaymentMethodsAction = {
  type: PaymentModalContextAction.SetSavedPaymentMethods;
  payload: PaymentModalContextState['savedPaymentMethods'];
};

type SetSelectedCardAction = {
  type: PaymentModalContextAction.SetSelectedCard;
  payload: PaymentModalContextState['selectedCard'];
};

type SetPaymentModalStepAction = {
  type: PaymentModalContextAction.SetPaymentModalStep;
  payload: PaymentModalContextState['currentModalStep'];
};

type SetSelectedPlanSlugAction = {
  type: PaymentModalContextAction.SetSelectedPlanSlug;
  payload: PaymentModalContextState['selectedPlanSlug'];
};

type SetZenSubscriptionDetailsAction = {
  type: PaymentModalContextAction.SetZenSubscriptionDetails;
  payload: PaymentModalContextState['zenSubscription'];
};

type PaymentModalDispatchAction =
  | SetCardInputValidityAction
  | SetIsReadyToPayAction
  | SetPaymentErrorAction
  | SetPaymentMethodAction
  | SetPaymentOptionAction
  | SetSavedPaymentMethodsAction
  | SetSelectedCardAction
  | SetPaymentModalStepAction
  | SetSelectedPlanSlugAction
  | SetZenSubscriptionDetailsAction;

const reducer = (
  state: PaymentModalContextState,
  action: PaymentModalDispatchAction
): PaymentModalContextState => {
  switch (action.type) {
    case PaymentModalContextAction.SetCardInputValidity:
      return {
        ...state,
        cardInputsValidity: action.payload
      };
    case PaymentModalContextAction.SetIsReadyToPay:
      return {
        ...state,
        isReadyToPay: action.payload
      };
    case PaymentModalContextAction.SetPaymentError:
      return {
        ...state,
        paymentError: action.payload
      };
    case PaymentModalContextAction.SetPaymentMethod:
      return {
        ...state,
        paymentMethod: action.payload
      };
    case PaymentModalContextAction.SetPaymentOption:
      return {
        ...state,
        paymentOption: action.payload,
        // If user select `PaymentOption.NewPaymentMethod`, remove `selectedCard` if any
        selectedCard: action.payload === PaymentOption.NewPaymentMethod ? null : state.selectedCard
      };
    case PaymentModalContextAction.SetSavedPaymentMethods: {
      const defaultPaymentMethod = action.payload.find(
        (method) => method.is_default_payment_method
      );
      return {
        ...state,
        savedPaymentMethods: action.payload,
        // Find and set default payment method
        selectedCard: defaultPaymentMethod,
        paymentOption: defaultPaymentMethod
          ? PaymentOption.UseSavedCard
          : PaymentOption.NewPaymentMethod
      };
    }
    case PaymentModalContextAction.SetSelectedCard:
      return {
        ...state,
        selectedCard: action.payload,
        // These properties should go together
        paymentOption: PaymentOption.UseSavedCard,
        paymentMethod: PaymentMethod.Card
      };
    case PaymentModalContextAction.SetPaymentModalStep:
      return {
        ...state,
        currentModalStep: action.payload
      };
    case PaymentModalContextAction.SetSelectedPlanSlug:
      return {
        ...state,
        selectedPlanSlug: action.payload
      };
    case PaymentModalContextAction.SetZenSubscriptionDetails:
      return {
        ...state,
        zenSubscription: action.payload
      };
    default:
      throw new Error('[PaymentModalContext] unexpected action type');
  }
};

type PaymentModalContextType = {
  state: PaymentModalContextState;
  dispatch: React.Dispatch<PaymentModalDispatchAction>;
};

const Context = React.createContext<PaymentModalContextType>({
  state: initialState,
  dispatch: null
});

Context.displayName = 'PaymentModal';

const PaymentModalContext = {
  Context,
  initialState,
  reducer
};

export default PaymentModalContext;
