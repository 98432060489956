import { useIsMobile } from '@zen/hooks/useIsMobile';
import React from 'react';
import styled, { css } from 'styled-components';

const Image = styled.img<{
  hideOnMobile: boolean;
}>`
  max-width: 100%;
  ${({ theme, hideOnMobile }) =>
    hideOnMobile &&
    css`
      @media (max-width: ${theme.breakpoints.m}px) {
        display: none;
      }
    `}
`;

type ImageViewerProps = {
  sourceUrl:
    | string
    | {
        mobile: string;
        desktop: string;
      };
  alt?: string;
  height?: string | number;
  width?: string | number;
  hideOnMobile?: boolean;
};

const ImageViewer = ({
  sourceUrl,
  alt = '',
  height,
  width,
  hideOnMobile = false
}: ImageViewerProps) => {
  const isMobile = useIsMobile();
  const imgSrc =
    typeof sourceUrl === 'string' ? sourceUrl : isMobile ? sourceUrl.mobile : sourceUrl.desktop;

  return <Image alt={alt} src={imgSrc} height={height} width={width} hideOnMobile={hideOnMobile} />;
};

export default React.memo(ImageViewer);
