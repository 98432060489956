import {
  PaymentIntent,
  PaymentRequestPaymentMethodEvent,
  PaymentMethod as StripePaymentMethod
} from '@stripe/stripe-js';
import { Column, Text } from '@yolaw/ui-kit-components';
import confettiAnimationSrc from '@zen/assets/animation/confetti.json';
import { PaymentMethod } from '@zen/services/payment';
import Subscription from '@zen/types/subscription';
import SubscriptionUtils from '@zen/utils/subscriptions';
import lottie, { AnimationItem } from 'lottie-web';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { ELEMENT_IDS } from '../constants';
import ApiService from '../services/apis';
import useProject from './useProject';
import useSegment from './useSegment';
import useStripePayment from './useStripePayment';
import useZenApp from './useZenApp';

export type ZenPaymentError = {
  source: string;
  message: string;
  code: string;
};

const useZenPayment = (plan?: Subscription.Plan) => {
  const app = useZenApp();
  const project = useProject();
  const segment = useSegment();
  const {
    stripePaymentRequest,
    canMakeApplePayPayment,
    createStripePaymentMethod,
    handleStripeCardAction
  } = useStripePayment();

  const [isProcessingPayment, setIsProcessingPayment] = useState(false);
  const [paymentError, setPaymentError] = useState<ZenPaymentError | null>(null);

  const availablePaymentMethods = [PaymentMethod.Card, PaymentMethod.GooglePay];
  if (canMakeApplePayPayment()) {
    availablePaymentMethods.push(PaymentMethod.ApplePay);
  }

  const onPaymentError = (error: ZenPaymentError) => {
    setPaymentError(error);
  };

  const animatedCrownElement = document.getElementById(ELEMENT_IDS.CONFETTI_ANIMATION_CONTAINER);

  const hideAnimationAndDisplayNotification = (animation: AnimationItem) => {
    animation.destroy();
    animatedCrownElement.style.setProperty('display', 'none');

    if (!toast.isActive('toast-zen-payment-success')) {
      toast.success(
        <Column>
          <Text fontWeightVariant="bold">Votre entreprise vous remercie&nbsp;😉</Text>
          <Text>
            Vous êtes désormais abonné à Zen. Si vous avez une question sur l&apos;outil
            n&apos;hésitez pas à nous contacter.
          </Text>
        </Column>,
        {
          autoClose: false,
          icon: false,
          toastId: 'toast-zen-payment-success'
        }
      );
    }
  };

  const showConfettiAnimation = () => {
    animatedCrownElement.style.setProperty('display', 'block');

    const confettiAnimation = lottie.loadAnimation({
      container: animatedCrownElement,
      renderer: 'svg',
      loop: false,
      autoplay: true,
      animationData: confettiAnimationSrc,
      rendererSettings: {
        preserveAspectRatio: 'xMaxYMax slice'
      }
    });

    setTimeout(() => {
      hideAnimationAndDisplayNotification(confettiAnimation);
    }, 3000);

    confettiAnimation.addEventListener('complete', () =>
      hideAnimationAndDisplayNotification(confettiAnimation)
    );
  };

  const onPaymentSuccess = async () => {
    segment.track('paywall: completed', {
      kbis: project.info.has_kbis,
      subscription_type: 'zen',
      plan: plan?.slug
    });

    await project.refreshProjectAndTasks();
    app.modal.closeModal();
    showConfettiAnimation();
  };

  const confirmStripePayment = async (paymentIntent: PaymentIntent) => {
    try {
      // Send confirm request to BE
      await ApiService.subscription('zen').confirmStripePayment({
        stripe_payment_intent_id: paymentIntent.id
      });

      onPaymentSuccess();
    } catch (error) {
      onPaymentError({
        source: 'ERROR_SOURCES.BACKEND_STRIPE',
        message: error.message,
        code: error.code
      });
    }
  };

  const initiateStripePayment = async (
    stripePaymentMethodId: StripePaymentMethod['id'],
    event?: PaymentRequestPaymentMethodEvent
  ) => {
    // Clear previously payment error if any
    setPaymentError(null);

    try {
      if (!plan) throw new Error('Subscription plan is not provided');

      // send payment request to server
      const initiateStripePaymentResult = await ApiService.subscription(
        plan.type_slug
      ).initiateStripePayment({
        project_id: project.info.id,
        subscription_plan_slug: plan.slug,
        stripe_payment_method_id: stripePaymentMethodId
      });

      const { subscription_status, stripe_client_secret } = initiateStripePaymentResult;

      // Check if the PaymentIntent requires any actions and if so let Stripe.js
      // handle the flow. If using an API version older than "2019-02-11"
      // instead check for: `paymentIntent.status === "requires_source_action"`.
      if (
        subscription_status === Subscription.SubscriptionStatus.Incomplete &&
        stripe_client_secret
      ) {
        const { paymentIntent, error } = await handleStripeCardAction(stripe_client_secret);
        if (error) {
          onPaymentError({
            source: 'ERROR_SOURCES.FRONTEND_STRIPE',
            message: error.message || '',
            code: error.code
          });
        }
        if (paymentIntent) {
          await confirmStripePayment(paymentIntent);
        }
      }

      // Payment success
      if (SubscriptionUtils.isActiveStatus(subscription_status)) {
        await onPaymentSuccess();
      }

      if (event) event.complete('success');
    } catch (exception) {
      // Report to the browser that the payment failed, prompting it to
      // re-show the payment interface, or show an error message and close
      // the payment interface.
      if (event) event.complete('fail');
      onPaymentError({
        source: 'ERROR_SOURCES.BACKEND_STRIPE',
        message: exception.message,
        code: exception.code
      });
    }

    setIsProcessingPayment(false);
  };
  const payWithCard = async (stripePaymentMethodId?: StripePaymentMethod['id']) => {
    setIsProcessingPayment(true);

    if (stripePaymentMethodId) {
      // Using an existing payment method
      initiateStripePayment(stripePaymentMethodId);
    } else {
      const { error, paymentMethod: stripePaymentMethod } = await createStripePaymentMethod();

      if (error) {
        onPaymentError({
          source: 'ERROR_SOURCES.FRONTEND_STRIPE',
          message: error.message || '',
          code: error.code
        });
      }
      initiateStripePayment(stripePaymentMethod.id);
    }
  };

  const payWithApplePay = async (
    stripePaymentMethod: StripePaymentMethod,
    event: PaymentRequestPaymentMethodEvent
  ) => {
    setIsProcessingPayment(true);
    initiateStripePayment(stripePaymentMethod.id, event);
  };

  const payWithGooglePay = async (paymentToken: string) => {
    setIsProcessingPayment(true);

    const { error, paymentMethod: stripePaymentMethod } = await createStripePaymentMethod(
      { token: paymentToken },
      { wallet_name: 'google_pay' }
    );

    if (error) {
      onPaymentError({
        source: 'ERROR_SOURCES.FRONTEND_STRIPE',
        message: error.message || '',
        code: error.code
      });
    }

    if (stripePaymentMethod) {
      initiateStripePayment(stripePaymentMethod.id);
    }
  };

  return {
    stripePaymentRequest,
    availablePaymentMethods,
    isProcessingPayment,
    paymentError,
    payWithCard,
    payWithApplePay,
    payWithGooglePay
  };
};

export default useZenPayment;
