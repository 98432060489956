import { PaymentMethod, PaymentOption } from '@zen/services/payment';
import Subscription from '@zen/types/subscription';
import LSUser from '@zen/types/user';
import { useContext, useEffect } from 'react';
import { ZenPaymentError } from '../../../hooks/useZenPayment';
import PaymentModalContext, { PaymentModalContextAction, PaymentModalStep } from './context';

export const usePaymentModalContext = () => {
  const { state, dispatch } = useContext(PaymentModalContext.Context);
  const { cardInputsValidity, paymentMethod, paymentOption, selectedPlanSlug, zenSubscription } =
    state;

  const getPlan = (slug: string): Subscription.Plan | null => {
    if (!zenSubscription) return null;
    return zenSubscription.plans.find((plan) => plan.slug === slug);
  };

  const selectedPlan = getPlan(selectedPlanSlug);

  const checkReadyToPayStatus = () => {
    if (!selectedPlan?.price_it) return false;

    if (paymentOption === PaymentOption.UseSavedCard) return !!state.selectedCard;

    switch (paymentMethod) {
      case PaymentMethod.Card:
        return state.cardInputsValidity;
      case PaymentMethod.ApplePay:
      case PaymentMethod.GooglePay:
        return true;
      default:
        return false;
    }
  };

  const setIsReadyToPay = (isReady: boolean) =>
    dispatch({
      type: PaymentModalContextAction.SetIsReadyToPay,
      payload: isReady
    });

  const setPaymentError = (paymentError: ZenPaymentError) =>
    dispatch({
      type: PaymentModalContextAction.SetPaymentError,
      payload: paymentError
    });

  useEffect(() => {
    setIsReadyToPay(checkReadyToPayStatus());
  }, [cardInputsValidity, paymentMethod, paymentOption, selectedPlanSlug]);

  const setCardInputsValidity = (validity: boolean) => {
    dispatch({
      type: PaymentModalContextAction.SetCardInputValidity,
      payload: validity
    });

    setIsReadyToPay(validity);
  };

  const setPaymentMethod = (method: PaymentMethod) => {
    dispatch({
      type: PaymentModalContextAction.SetPaymentMethod,
      payload: method
    });
  };

  const setPaymentOption = (option: PaymentOption) => {
    dispatch({
      type: PaymentModalContextAction.SetPaymentOption,
      payload: option
    });
  };

  const setSelectedCard = (card: LSUser.PaymentMethodItem) => {
    dispatch({
      type: PaymentModalContextAction.SetSelectedCard,
      payload: card
    });
  };

  const setPaymentModalStep = (stepName: PaymentModalStep) =>
    dispatch({ type: PaymentModalContextAction.SetPaymentModalStep, payload: stepName });

  const setSelectedPlanSlug = (slug: Subscription.Plan['slug']) =>
    dispatch({
      type: PaymentModalContextAction.SetSelectedPlanSlug,
      payload: slug
    });

  return {
    state,
    selectedPlan,
    /** Available actions in payment modal */
    action: {
      getPlan,
      setCardInputsValidity,
      setPaymentError,
      setPaymentMethod,
      setPaymentOption,
      setSelectedCard,
      setPaymentModalStep,
      setSelectedPlanSlug
    }
  };
};
