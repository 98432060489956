import { PaymentRequestButtonElement } from '@stripe/react-stripe-js';
import {
  PaymentRequestPaymentMethodEvent,
  StripePaymentRequestButtonElementClickEvent
} from '@stripe/stripe-js';
import React, { useEffect } from 'react';
import styled from 'styled-components';
import useZenPayment from '../../../../../../../src/hooks/useZenPayment';
import { usePaymentModalContext } from '../../../hooks';

const ButtonContainer = styled.div<{ disabled: boolean }>`
  .StripeElement {
    ${({ disabled }) => disabled && { opacity: 0.6 }};
  }
`;

type ApplePayButtonProps = {
  disabled?: boolean;
};

const ApplePayButton = ({ disabled }: ApplePayButtonProps) => {
  const paymentModal = usePaymentModalContext();
  const zenPayment = useZenPayment(paymentModal.selectedPlan);

  useEffect(() => {
    paymentModal.action.setPaymentError(zenPayment.paymentError);
  }, [zenPayment.paymentError]);

  const stripePaymentRequest = zenPayment.stripePaymentRequest;
  if (!stripePaymentRequest) return null;

  const amountToPay = paymentModal.selectedPlan.price_it;

  // Handling Stripe Payment Request for Google Pay or Apple Pay
  const handleStripePaymentMethodChange = async (event: PaymentRequestPaymentMethodEvent) => {
    zenPayment.payWithApplePay(event.paymentMethod, event);
  };

  // Handling Stripe Payment Request for Google Pay or Apple Pay
  const handleStripePaymentRequestCancel = () => {
    // Turn off the payment method event listener
    stripePaymentRequest.off('paymentmethod', handleStripePaymentMethodChange);
  };

  const handleButtonClick = (event: StripePaymentRequestButtonElementClickEvent) => {
    if (disabled) {
      event.preventDefault();
    } else {
      stripePaymentRequest.update({
        total: {
          label: "Frais d'abonnement Zen TTC/an",
          amount: amountToPay * 100,
          pending: false
        }
      });

      // Listen to payment method change (payment method is confirmed/authorized)
      stripePaymentRequest.on('paymentmethod', handleStripePaymentMethodChange);

      // Listen to payment request cancel (payment interface is dismissed by user)
      stripePaymentRequest.on('cancel', handleStripePaymentRequestCancel);
    }
  };

  return (
    <ButtonContainer disabled={disabled}>
      <PaymentRequestButtonElement
        options={{
          paymentRequest: stripePaymentRequest,
          style: { paymentRequestButton: { type: 'check-out', height: '50px' } }
        }}
        onClick={handleButtonClick}
      />
    </ButtonContainer>
  );
};

export default ApplePayButton;
