import { Row, Text } from '@yolaw/ui-kit-components';
import React, { useLayoutEffect, useState } from 'react';
import styled from 'styled-components';
import { CONTENT_MAX_WIDTH, ELEMENT_IDS } from '../../../constants';
import CustomButton from '../components/CustomButton';
import OverlayLoader from '../components/OverlayLoader';

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
`;
const IframeContainer = styled.div`
  flex: 1;
  position: relative;
`;

const Iframe = styled.iframe`
  border: none;
  width: 100%;
  height: 100%;
`;

const BottomBarContainer = styled.div`
  background-color: white;
  box-shadow: ${({ theme }) => theme.shadows.blue.medium};
  padding: ${({ theme }) => theme.spacing.xs}px;
  z-index: 1;

  @media (max-width: ${({ theme }) => theme.breakpoints.m}px) {
    --container-border-radius: ${({ theme }) => theme.borderRadius.m}px;
    border-radius: var(--container-border-radius) var(--container-border-radius) 0 0;
  }
`;

const BottomBarBodyContainer = styled(Row)`
  gap: ${({ theme }) => theme.spacing.xxs}px;
  width: 100%;
  margin-inline: auto;

  @media (min-width: ${({ theme }) => theme.breakpoints.m}px) {
    justify-content: flex-end;
    max-width: ${CONTENT_MAX_WIDTH}px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.m}px) {
    flex-direction: column;
  }
`;

type BottomBarProps = {
  buttons: SinglePageDetails['buttons'];
};

const BottomBar = ({ buttons }: BottomBarProps) => {
  if (!buttons) return null;

  return (
    <BottomBarContainer id={ELEMENT_IDS.STICKY_BOTTOM_BAR}>
      <BottomBarBodyContainer>
        {buttons.map((button) => (
          <CustomButton key={button.configs.text} buttonConfig={button} />
        ))}
      </BottomBarBodyContainer>
    </BottomBarContainer>
  );
};

const HubspotPage = ({ page_url, buttons }: SinglePageDetails) => {
  const [iframeLoaded, setIframeLoaded] = useState(false);

  useLayoutEffect(() => {
    const lzAppElement = document.getElementById(ELEMENT_IDS.APP_CONTAINER);
    // Not allow page scroll because of extra space added by some sticky elements (contact lawyer button)
    lzAppElement.style.setProperty('overflow', 'hidden');
    lzAppElement.style.setProperty('height', '100%');
    return () => {
      lzAppElement.style.setProperty('overflow', 'unset');
    };
  }, []);

  const onIframeLoad = () => setIframeLoaded(true);

  return (
    <MainContainer>
      {page_url ? (
        <IframeContainer className="hs_page_iframe_container">
          <Iframe id="hs_page_iframe" src={page_url} onLoad={onIframeLoad} />
          {!iframeLoaded && <OverlayLoader />}
        </IframeContainer>
      ) : (
        <Text color="error.main">Missing `page_url`</Text>
      )}

      <BottomBar buttons={buttons} />
    </MainContainer>
  );
};

export default HubspotPage;
