import { Accent, Button, ServiceItems, Title } from '@yolaw/ui-kit-components';
import React from 'react';
import styled, { css } from 'styled-components';
import { useProject, useSegment } from '../../hooks';

export const ZEN_FEATURES = [
  {
    title: 'Calendrier de vos obligations légales',
    icon: 'CalendarV3'
  },
  {
    title: 'Génération de vos documents administratifs',
    icon: 'ConfidentialityV3'
  },
  {
    title: 'Contenus associés à votre rémunération',
    icon: 'PiggyBankV3'
  }
];

const MainContainer = styled.div`
  text-align: center;

  ${({ theme }) => css`
    color: ${theme.colors.secondary.main};
    border-radius: ${theme.borderRadius.s}px;
    box-shadow: ${theme.shadows.blue.small};
    padding: ${theme.spacing.s}px;
    column-gap: ${theme.spacing.l}px;

    .service-items-wrapper {
      padding-left: unset;
      padding-right: unset;
    }

    @media (max-width: ${theme.breakpoints.m}px) {
      justify-content: center;
      flex-wrap: wrap;

      .service-item {
        min-width: unset;
      }
    }
  `}
`;

const ZenIntroBanner = () => {
  const project = useProject();
  const segment = useSegment();

  const handleCTAClick = () => {
    project.hideZenIntro();
    segment.track('onboarding banner: closed');
  };

  return (
    <MainContainer>
      <Title type="H2">
        Bienvenue dans
        <Accent color="accentuation.dark"> Zen</Accent>, votre copilote pour bien démarrer votre
        activité
      </Title>

      <ServiceItems showTitleOnMobile items={ZEN_FEATURES} />

      <Button onClick={handleCTAClick}>J’ai compris</Button>
    </MainContainer>
  );
};

export default ZenIntroBanner;
