import {
  Accent,
  Button,
  Row,
  ServiceItemLayout,
  ServiceItems,
  Title
} from '@yolaw/ui-kit-components';
import taskCardsPreviewSrc from '@zen/assets/images/task-cards-preview.png';
import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { CONTENT_MAX_WIDTH } from '../constants';
import { useProject, useSegment } from '../hooks';

const ZEN_FEATURES = [
  {
    title: 'Ne ratez aucune échéance',
    description: 'Un calendrier personnalisé et des rappels pour éviter les amendes',
    icon: 'CalendarV3'
  },
  {
    title: 'Optimisez votre rémunération',
    description: 'Du contenu et des webinars exclusifs pour que vous ayez toutes les clés',
    icon: 'PiggyBankV3'
  },
  {
    title: 'Gagnez du temps',
    description: 'Vos documents administratifs pré-complétés et générés automatiquement',
    icon: 'FastV3'
  }
];

const MainContainer = styled.main`
  max-width: ${CONTENT_MAX_WIDTH}px;
  margin-inline: auto;
  padding: ${({ theme }) => theme.spacing.xxxs}px;
`;

const PageContainer = styled.div`
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: ${({ theme }) => theme.spacing.xs}px;

  min-height: calc(100vh - 93px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const BodyContainer = styled(Row)`
  width: 100%;

  ${({ theme }) => css`
    @media (min-width: ${theme.breakpoints.m}px) {
      margin: ${theme.spacing.m}px;
    }

    @media (max-width: ${theme.breakpoints.m}px) {
      .service-items-wrapper {
        padding: ${theme.spacing.m}px 0;
      }
    }
  `}
`;

const ImageContainer = styled.div`
  flex: 1;

  img {
    max-width: 100%;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.m}px) {
    display: none;
  }
`;

const FeaturesContainer = styled.div`
  flex: 1;
`;

const ButtonContainer = styled.div`
  margin: ${({ theme }) => theme.spacing.s}px 0;
`;

const Intro = () => {
  const project = useProject();
  const segment = useSegment();
  const [searchParams] = useSearchParams();

  const handleCTAClick = () => {
    project.hideZenIntro();

    // Do not force redirect to onboarding questionnaire following the needs of https://app.clickup.com/t/2571097/TECH-39311
    // if (!isOnboardingQuestionnaire) {
    //   // Ex: Accidentally triggered in Dashboard
    //   goToOnboardingQuestionnaire({ searchParams });
    // }
  };

  useEffect(() => {
    segment.track('intro: displayed', {
      sub_aj: project.info.aj_subscription_status,
      sub_zen: project.info.zen_subscription_status,
      sub_cs: project.info.cs_subscription_status,
      kbis: project.info.has_kbis,
      from: searchParams.get('utm_source') || 'direct_access'
    });
  }, []);

  return (
    <MainContainer>
      <PageContainer>
        <Title type="H2">
          <Accent color="accentuation.dark">Zen</Accent>, votre copilote pour bien démarrer votre
          activité
        </Title>

        <BodyContainer>
          <ImageContainer>
            <img src={taskCardsPreviewSrc} alt="task-cards-preview" />
          </ImageContainer>
          <FeaturesContainer>
            <ServiceItems
              itemLayout={ServiceItemLayout.Horizontal}
              containerLayout={ServiceItemLayout.Vertical}
              showTitleOnMobile
              showDescriptionOnMobile={false}
              items={ZEN_FEATURES}
            />
          </FeaturesContainer>
        </BodyContainer>

        <ButtonContainer>
          <Button rightIcon="ArrowRight" onClick={handleCTAClick}>
            Découvrir mes obligations légales
          </Button>
        </ButtonContainer>
      </PageContainer>
    </MainContainer>
  );
};

export default Intro;
