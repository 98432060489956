import { useContext } from 'react';

import useTask from '../../../../hooks/useTask';
import useProject from '../../../../hooks/useProject';
import useSegment from '../../../../hooks/useSegment';
import useZenRoutes from '../../../../hooks/useZenRoutes';
import ApiService from '../../../../services/apis';
import { QuestionnaireContext } from '../context';

const useQuestionnaire = () => {
  const project = useProject();
  const task = useTask();

  const {
    state: { currentPage, resumePageSlug, onComplete },
    dispatch
  } = useContext(QuestionnaireContext);
  const segment = useSegment();
  const { isOnboardingQuestionnaire } = useZenRoutes();

  const submitAnswers = async () => {
    await ApiService.project(project.info.id).onboarding.updateAnswers(
      project.onboardingQuestionnaire.answers
    );

    // Perform onComplete action if it's defined on last page
    if (!currentPage.nextPageSlug && onComplete) {
      onComplete();
    }
  };

  const updateCurrentPage = (page: QuestionnaireTimelinePage) => {
    dispatch({
      type: 'SET_CURRENT_PAGE',
      payload: page
    });

    if (isOnboardingQuestionnaire) {
      segment.track('onboarding question: displayed', {
        sub_aj: project.info.aj_subscription_status,
        sub_zen: project.info.zen_subscription_status,
        sub_cs: project.info.cs_subscription_status,
        kbis: project.info.has_kbis,
        page_slug: page.slug
      });

      ApiService.project(project.info.id).onboarding.patch({
        current_page: page.slug
      });
    } else {
      segment.track('task step: displayed', {
        kbis: project.info.has_kbis,
        task_name: task.type.title,
        task_id: task.id,
        task_category: task.type.category,
        task_slug: task.type.slug,
        page_slug: page.slug
      });
    }
  };

  const setLoaderVisible = (visible: boolean) => {
    dispatch({
      type: 'SET_LOADER_VISIBLE',
      payload: visible
    });
  };

  return {
    currentPage,
    resumePageSlug,
    onComplete,
    submitAnswers,
    updateCurrentPage,
    setLoaderVisible
  };
};

export default useQuestionnaire;
