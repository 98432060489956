import { Button, Column, Row, Tag, Text, Title } from '@yolaw/ui-kit-components';
import useProject from '@zen/hooks/useProject';
import useSegment from '@zen/hooks/useSegment';
import Subscription from '@zen/types/subscription';
import NumberUtils from '@zen/utils/number';
import TranslatorUtils from '@zen/utils/translator';
import React from 'react';
import styled, { css } from 'styled-components';
import { PaymentModalStep } from '../context';
import { usePaymentModalContext } from '../hooks';

const PlanListContainer = styled(Row)`
  justify-content: center;

  ${({ theme }) => css`
    gap: ${theme.spacing.l}px;

    .plan-item {
      box-shadow: ${theme.shadows.blue.small};
      border-radius: ${theme.borderRadius.m}px;
      padding: ${theme.spacing.xs}px;
      row-gap: ${theme.spacing.s}px;
      justify-content: flex-end;
      max-width: 320px;
    }

    .plan-item_info {
      > * {
        display: block;
      }
    }

    @media (min-width: ${theme.breakpoints.m}px) {
      .plan-item {
        width: 50%;
      }
    }

    @media (max-width: ${theme.breakpoints.m}px) {
      flex-wrap: wrap;
      row-gap: ${theme.spacing.m}px;

      .plan-item {
        width: 100%;
      }
    }
  `}
`;

type PlanItemProps = {
  plan: Subscription.Plan;
  description: string;
  tagText?: string;
};
const PlanItem = ({ plan, description, tagText }: PlanItemProps) => {
  const project = useProject();
  const segment = useSegment();

  const paymentModal = usePaymentModalContext();

  const handlePlanSelect = () => {
    segment.track('paywall plan: selected', {
      plan: plan.slug,
      kbis: project.info.has_kbis,
      subscription_type: 'zen'
    });
    paymentModal.action.setSelectedPlanSlug(plan.slug);
    paymentModal.action.setPaymentModalStep(PaymentModalStep.Payment);
  };

  return (
    <Column className="plan-item">
      <Column className="plan-item_info">
        {tagText && (
          <div>
            <Tag color="purple" text={tagText} />
          </div>
        )}
        <Title type="H4">
          {NumberUtils.currencyFormat(plan?.price_et || 0)}&nbsp;<sup>HT</sup>&nbsp;/
          {TranslatorUtils.translate(plan?.period_unit)}
        </Title>
        <Text type="body">{description}</Text>
      </Column>
      <div>
        <Button rightIcon="ArrowRight" sizing="small" onClick={handlePlanSelect}>
          Choisir
        </Button>
      </div>
    </Column>
  );
};

const PlanSelection = () => {
  const paymentModal = usePaymentModalContext();
  const monthlyPlan = paymentModal.action.getPlan('monthly');
  const yearlyPlan = paymentModal.action.getPlan('yearly');

  return (
    <>
      <Title type="H3">Choisir ma formule</Title>

      <PlanListContainer>
        <PlanItem
          plan={yearlyPlan}
          description="Une facturation à l’année pour plus de tranquillité"
          tagText="30 JOURS OFFERTS"
        />
        <PlanItem
          plan={monthlyPlan}
          description="Une facturation au mois pour plus de souplesse"
          tagText="RÉSILIEZ QUAND VOUS VOULEZ"
        />
      </PlanListContainer>
    </>
  );
};

export default PlanSelection;
