import GooglePayButtonReact from '@google-pay/button-react';
import React, { useEffect } from 'react';
import useZenPayment from '../../../../../../hooks/useZenPayment';
import GooglePayProvider from '../../../../../../providers/googlePay';
import config from '../../../../../../services/config';
import { usePaymentModalContext } from '../../../hooks';

type GooglePayButtonProps = {
  disabled?: boolean;
};
const GooglePayButton = ({ disabled }: GooglePayButtonProps) => {
  const paymentModal = usePaymentModalContext();
  const zenPayment = useZenPayment(paymentModal.selectedPlan);

  const amountToPay = paymentModal.selectedPlan.price_it;

  useEffect(() => {
    paymentModal.action.setPaymentError(zenPayment.paymentError);
  }, [zenPayment.paymentError]);

  const handleButtonClick = (event: Event) => {
    // Try to validate payment information
    if (disabled) {
      // If the validation is not pass
      // => prevent the Google payment sheets to be opened
      event.preventDefault();
    }
  };

  const onCancel = (reason: google.payments.api.PaymentsError) => {
    console.log('GPay canceled: ', { reason });
  };

  const onPaymentAuthorized = (
    paymentData: google.payments.api.PaymentData
  ):
    | Promise<google.payments.api.PaymentAuthorizationResult>
    | google.payments.api.PaymentAuthorizationResult => {
    const paymentTokenId = JSON.parse(paymentData.paymentMethodData.tokenizationData.token).id;

    if (paymentTokenId) {
      zenPayment.payWithGooglePay(paymentTokenId);

      // close Google Pay payment sheet
      return {
        transactionState: 'SUCCESS'
      };
    }

    // close Google Pay payment sheet
    return {
      transactionState: 'ERROR'
    };
  };

  return (
    <GooglePayButtonReact
      buttonLocale="fr"
      buttonType={'pay'}
      environment={config.NODE_ENV === 'production' ? 'PRODUCTION' : 'TEST'}
      paymentRequest={GooglePayProvider.getGooglePaymentDataRequest(amountToPay.toString())}
      onClick={handleButtonClick}
      onCancel={onCancel}
      onPaymentAuthorized={onPaymentAuthorized}
      buttonSizeMode="fill"
      style={{ height: '50px', width: '100%' }}
    />
  );
};

export default GooglePayButton;
