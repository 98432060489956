import { Button, Column, Container, Row, Text, Title } from '@yolaw/ui-kit-components';
import StampSampleSvg from '@zen/assets/svgs/stamp-sample.svg';
import _get from 'lodash/get';
import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { DocumentGenerationContext } from '../../context';

const EnvelopeContainer = styled(Column)`
  text-align: left;
  width: 520px;

  ${({ theme }) => css`
    border: 1px solid ${theme.colors.neutral.lighter};
    box-shadow: ${theme.shadows.blue.small};
    border-radius: ${theme.borderRadius.m}px;
    padding: ${theme.spacing.xs}px;
    row-gap: ${theme.spacing.s}px;
    @media (max-width: ${theme.breakpoints.m}px) {
      width: 100%;
    }
  `};
`;

const Address = styled.div`
  padding-left: 30%;
`;

const Succeed = () => {
  const navigate = useNavigate();
  const {
    state: { formData }
  } = useContext(DocumentGenerationContext);

  const navigateToDashboard = () => navigate('/', { replace: true });

  const handleCtaClick = async () => {
    navigateToDashboard();
  };

  return (
    <>
      <Title type="H2" color="secondary.main">
        🎉 Super, votre courrier est prêt !
      </Title>
      <Container size="S">
        <Text type="BODY" color="neutral.dark">
          ⚠️ Envoyez vite votre courrier pour que votre changement de régime soit pris en compte
          rapidement :
        </Text>
      </Container>

      <EnvelopeContainer>
        <Row>
          <Text type="SMALL" fontWeightVariant="bold">
            LETTRE RECOMMANDÉE AVEC ACCUSÉ DE RÉCEPTION
          </Text>
          <img src={StampSampleSvg} alt="stamp" />
        </Row>

        <Address>
          <Text type="SMALL">{_get(formData, ['sie_city'])}</Text>
          <Text type="SMALL">{_get(formData, ['sie_address'])}</Text>
        </Address>
      </EnvelopeContainer>

      <Container size="XS">
        <Button text="Fermer" onClick={handleCtaClick} />
      </Container>
    </>
  );
};

export default Succeed;
