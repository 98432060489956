import { Button } from '@yolaw/ui-kit-components';
import { ModalName } from '@zen/contexts/app';
import { useIsMobile } from '@zen/hooks/useIsMobile';
import useProject from '@zen/hooks/useProject';
import useSegment from '@zen/hooks/useSegment';
import useTask from '@zen/hooks/useTask';
import useZenApp from '@zen/hooks/useZenApp';
import React, { useContext } from 'react';
import { DocumentGenerationContext } from '../context';
import useDocumentGenerationTask from '../hooks/useDocumentGenerationTask';

const FreemiumCTA = () => {
  const app = useZenApp();
  const project = useProject();
  const segment = useSegment();
  const task = useTask();

  const buttonText = 'Obtenir mon document';

  const handleCTAClick = () => {
    segment.track('task cta: clicked', {
      kbis: project.info.has_kbis,
      task_id: task.id,
      task_category: task.type.category,
      task_type: task.type.kind,
      task_name: task.type.title,
      button_variant: 'primary',
      button_label: buttonText
    });

    app.modal.openModal(ModalName.ZenSubscriptionPayment, 'doc gen');
  };

  return <Button rightIcon="ArrowRight" text={buttonText} onClick={handleCTAClick} />;
};

const GenerateAndDownloadButton = () => {
  const isMobile = useIsMobile();
  const project = useProject();
  const task = useTask();
  const segment = useSegment();
  const documentContext = useContext(DocumentGenerationContext);

  const { isGeneratingDocument } = documentContext.state;
  const { isFormReady, generateAndDownloadDocument } = useDocumentGenerationTask();

  const {
    details: { buttons }
  } = task as DocumentTask.TaskObject;

  const mainCtaText =
    buttons?.main_cta?.text || (isMobile ? 'Télécharger mon document' : 'Générer et télécharger');

  const handleButtonClick = () => {
    segment.track('task cta: clicked', {
      kbis: project.info.has_kbis,
      task_id: task.id,
      task_category: task.type.category,
      task_type: task.type.kind,
      task_name: task.type.title,
      button_variant: 'primary',
      button_label: mainCtaText
    });

    generateAndDownloadDocument();
  };

  return project.isZenSubscriptionNeeded ? (
    <FreemiumCTA />
  ) : (
    <Button
      rightIcon="ArrowRight"
      text={mainCtaText}
      disabled={!isFormReady || isGeneratingDocument}
      isLoading={isGeneratingDocument}
      onClick={handleButtonClick}
    />
  );
};

export default GenerateAndDownloadButton;
