import { Column, Label, Row, Text, TextArea, TextField } from '@yolaw/ui-kit-components';
import { InformationBubbleFlat } from '@yolaw/ui-kit-icons';
import React, { useContext } from 'react';
import styled, { css, useTheme } from 'styled-components';

import { useSegment } from '../../../../hooks';
import useTask from '../../../../hooks/useTask';
import InputFormContainer from '../components/InputFormContainer';
import SignaturePad from '../components/SignaturePad';
import { DocumentGenerationContext } from '../context';
import useDocumentGenerationTask from '../hooks/useDocumentGenerationTask';

enum QuestionVariableType {
  ComputedNumber = 'computed_number',
  Date = 'date',
  Image = 'image',
  Number = 'number',
  String = 'string',
  Time = 'time',
  Paragraph = 'paragraph'
}

const Link = styled.a`
  ${({ theme }) => css`
    color: ${theme.colors.information.dark};
    text-decoration-color: ${theme.colors.information.dark}; ;
  `}
`;

const InputField = ({ question }: { question: DocumentTask.Question }) => {
  const documentContext = useContext(DocumentGenerationContext);
  const { formData } = documentContext.state;
  const theme = useTheme();

  const { getQuestionType, handleTextInputChange, handleSignatureChange } =
    useDocumentGenerationTask();

  if (question.variable_type === QuestionVariableType.ComputedNumber) {
    return (
      <TextField
        disabled={true}
        placeholder={question.placeholder}
        label={question.title}
        showLabel
        type={'number'}
        name={question.variable_name}
        value={formData[question.variable_name] || 0}
        unit={question.unit}
      />
    );
  }

  if (
    [
      QuestionVariableType.Date,
      QuestionVariableType.Number,
      QuestionVariableType.String,
      QuestionVariableType.Time
    ].includes(question.variable_type)
  ) {
    return (
      <TextField
        disabled={question.disabled}
        placeholder={question.placeholder}
        label={question.title}
        showLabel
        type={getQuestionType(question.variable_type) as 'text'} // FIXME: add `time` to TextField's `type` in ui-kit
        name={question.variable_name}
        onChange={handleTextInputChange}
        defaultValue={formData[question.variable_name]}
        unit={question.unit}
      />
    );
  }
  if (question.variable_type === QuestionVariableType.Paragraph) {
    return (
      <Column style={{ gap: `${theme.spacing.xxxs}px` }}>
        <Label sizeVariant="XSMALL" text={question.title} fontWeightVariant="bold" />
        <TextArea
          disabled={question.disabled}
          placeholder={question.placeholder}
          name={question.variable_name}
          onChange={handleTextInputChange}
          defaultValue={formData[question.variable_name]}
        />
      </Column>
    );
  }
  if (question.variable_type === QuestionVariableType.Image) {
    return (
      <SignaturePad
        onSignatureChange={handleSignatureChange}
        label={question.title}
        name={question.variable_name}
      />
    );
  }
  return null;
};

type QuestionItemProps = {
  question: DocumentTask.Question;
};
const QuestionItem = ({ question }: QuestionItemProps) => {
  const theme = useTheme();
  const segment = useSegment();
  const task = useTask();

  const trackLinkClick = (event: any) => {
    segment.track('task btn: clicked', {
      task_id: task.id,
      task_name: task.type.title,
      task_slug: task.type.slug,
      task_type: task.type.kind,
      task_category: task.type.category,
      button_label: (event.target.innerText || '').trim(),
      button_id: event.target.id,
      button_class: event.target.className
    });
  };

  if (question.hidden) return null;

  return (
    <Column key={question.variable_name} style={{ rowGap: `${theme.spacing.xxxs}px` }}>
      <InputField question={question} />
      {question.description_label_text && (
        <Link href={question.description_link} target={'_blank'} onClick={trackLinkClick}>
          <Text id="rental_value_estimate_link" type="small" fontWeightVariant="bold">
            <InformationBubbleFlat color="currentColor" /> {question.description_label_text}
          </Text>
        </Link>
      )}
    </Column>
  );
};

const Questions = () => {
  const task = useTask();

  const {
    details: {
      form: { questions }
    }
  } = task as DocumentTask.TaskObject;

  return (
    <>
      {questions.map((questionOrQuestionGroup, index) => {
        if (Array.isArray(questionOrQuestionGroup)) {
          return (
            <Row key={`question-group-${index}`}>
              {questionOrQuestionGroup.map((question) => (
                <QuestionItem key={question.variable_name} question={question} />
              ))}
            </Row>
          );
        } else {
          return (
            <QuestionItem
              key={questionOrQuestionGroup.variable_name}
              question={questionOrQuestionGroup}
            />
          );
        }
      })}
    </>
  );
};

const InputForm = () => {
  return (
    <InputFormContainer>
      <Questions />
    </InputFormContainer>
  );
};

export default InputForm;
