import _get from 'lodash/get';
import React from 'react';

type DocumentGenerationContextState = {
  formData: Record<string, string>;
  formFields: Array<string>;
  computableFields: Array<{
    name: string;
    formula: string;
  }>;
  isGeneratingDocument: boolean;
  documentUrl: string | null;
};

const initialState: DocumentGenerationContextState = {
  formData: {},
  formFields: [],
  computableFields: [],
  isGeneratingDocument: false,
  documentUrl: null
};

type SetFormDataAction = {
  type: 'SET_FORM_DATA';
  payload: DocumentGenerationContextState['formData'];
};

type PatchFormDataAction = {
  type: 'PATCH_FORM_DATA';
  payload: Partial<DocumentGenerationContextState['formData']>;
};

type UpdateFormFieldsAction = {
  type: 'UPDATE_FORM_FIELDS';
  payload: DocumentTask.Details['form']['questions'];
};

type PatchContextStateAction = {
  type: 'PATCH_CONTEXT_STATE';
  payload: Partial<DocumentGenerationContextState>;
};

type DocumentGenerationDispatchAction =
  | SetFormDataAction
  | PatchFormDataAction
  | UpdateFormFieldsAction
  | PatchContextStateAction;

const getComputableFields = (template: string) => {
  // Regex to search for the string between "[]". Ex: "[computed]"
  const dynRegex = /(\[[^\]]*\])/gm;
  const fields = template.match(dynRegex);
  return fields ? Array.from(fields) : [];
};

const parseFormula = (rawFormula: string, data: Record<string, string>) => {
  const computableField = getComputableFields(rawFormula);
  let parsedFormula = rawFormula;

  for (const field of computableField) {
    const [, fieldName] = field.split(/[[|\]]/);
    const value = _get(data, [fieldName]);

    if (!value) return null;

    parsedFormula = parsedFormula.replace(field, value);
  }

  return parsedFormula;
};

const updateComputableFields = (
  computableFields: DocumentGenerationContextState['computableFields'],
  newFormData: DocumentGenerationContextState['formData']
): Partial<DocumentGenerationContextState['formData']> => {
  const toUpdateFields: Partial<DocumentGenerationContextState['formData']> = {};
  computableFields.forEach((field) => {
    const parsedFormula = parseFormula(field.formula, newFormData);
    let computedValue;
    if (parsedFormula) {
      computedValue = eval(parsedFormula);
    }
    toUpdateFields[field.name] = parseFloat(String(computedValue || '0')).toFixed(2);
  });
  return toUpdateFields;
};

const extractFormFields = (questions: DocumentTask.Details['form']['questions']) => {
  const formFields: DocumentGenerationContextState['formFields'] = [];
  const computableFields: DocumentGenerationContextState['computableFields'] = [];

  const _processQuestion = (question: DocumentTask.Question) => {
    formFields.push(question.variable_name);
    if (question.variable_type === 'computed_number') {
      computableFields.push({ name: question.variable_name, formula: question.formula });
    }
  };

  questions.forEach((questionOrQuestionGroup) => {
    if (Array.isArray(questionOrQuestionGroup)) {
      // question group => dive deeper
      questionOrQuestionGroup.forEach((question) => _processQuestion(question));
    } else {
      // single question
      _processQuestion(questionOrQuestionGroup);
    }
  });

  return { formFields, computableFields };
};

const reducer = (
  state: DocumentGenerationContextState,
  action: DocumentGenerationDispatchAction
) => {
  switch (action.type) {
    case 'SET_FORM_DATA':
      return {
        ...state,
        formData: action.payload
      };
    case 'PATCH_FORM_DATA': {
      const newFormData = {
        ...state.formData,
        ...action.payload
      };
      return {
        ...state,
        formData: {
          ...newFormData,
          ...updateComputableFields(state.computableFields, newFormData)
        }
      };
    }
    case 'UPDATE_FORM_FIELDS': {
      const { formFields, computableFields } = extractFormFields(action.payload);
      return {
        ...state,
        formFields,
        computableFields
      };
    }
    case 'PATCH_CONTEXT_STATE':
      return {
        ...state,
        ...action.payload
      };

    default:
      throw new Error('unexpected action type');
  }
};

type DocumentGenerationContextType = {
  state: DocumentGenerationContextState;
  dispatch: React.Dispatch<DocumentGenerationDispatchAction>;
};

const DocumentGenerationContext = React.createContext<DocumentGenerationContextType>({
  state: initialState,
  dispatch: () => null
});

DocumentGenerationContext.displayName = 'DocumentGeneration';

export { DocumentGenerationContext, reducer, initialState };
