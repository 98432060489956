import { AxiosResponse } from 'axios';
import zenstartApi from '../legalzenApi';

const getInfo = (projectId: Project['id']): Promise<AxiosResponse<Project>> =>
  zenstartApi.get(`/projects/${projectId}/`);

const logZenRatings = (
  projectId: Project['id'],
  data: ZenRatingData
): Promise<AxiosResponse<ZenRating>> => zenstartApi.post(`/projects/${projectId}/ratings/`, data);

const ProjectApiEndpoint = {
  getInfo,
  logZenRatings
};

export default ProjectApiEndpoint;
