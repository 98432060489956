import useZenApp from '@zen/hooks/useZenApp';
import React, { useEffect } from 'react';
import { ELEMENT_IDS } from '../constants';

const loadScript = () => {
  const tag = document.createElement('script');
  tag.defer = true;
  tag.src = 'https://v2.zopim.com/?5ndrzXYrSVtWRD6bxs7LVwlrijnTIlei';
  const body = document.getElementsByTagName('body')[0];
  body.appendChild(tag);
};

const setBottomOffset = (elements: NodeListOf<HTMLDivElement>, bottomOffset: number) => {
  for (let i = 0; i < elements.length; i++) {
    elements[i].style.setProperty('bottom', `${bottomOffset}px`);
  }
  window.$zopim.livechat.window.setOffsetVertical(bottomOffset);
};

const ChatButton = () => {
  const app = useZenApp();

  useEffect(() => {
    if (!window.$zopim) {
      loadScript();
    }
  }, []);

  useEffect(() => {
    if (app.state.userInfo && window.$zopim) {
      // Prefill the user information
      const { email, first_name, last_name } = app.state.userInfo;
      window.$zopim.livechat.set({
        email,
        name: `${first_name} ${last_name}`
      });
    }
  }, [app.state.userInfo, window.$zopim]);

  if (!window.$zopim) return null;

  const stickyBottomBarElm = document.getElementById(ELEMENT_IDS.STICKY_BOTTOM_BAR);
  const zopimElms = document.querySelectorAll<HTMLDivElement>('.zopim');

  if (stickyBottomBarElm && zopimElms) {
    const options: IntersectionObserverInit = {
      root: null, // set document viewport as root
      rootMargin: '0px', // margin around root
      threshold: 1.0 // 1.0 means that when 100% of the target is visible inside the root, then observer callback is invoked.
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.intersectionRatio > 0) {
          // element is in the viewport
          setBottomOffset(zopimElms, stickyBottomBarElm.clientHeight);

          // Add a listener on the chat window hide
          window.$zopim.livechat.window.onHide(() =>
            setBottomOffset(zopimElms, stickyBottomBarElm.clientHeight)
          );
        } else {
          // element is out the of viewport
          setBottomOffset(zopimElms, 0);
        }
      });
    }, options);

    // Start observing sticky bottom bar visibility
    observer.observe(stickyBottomBarElm);
  }

  return <></>;
};

export default ChatButton;
