import { Button, Row, Text, Title } from '@yolaw/ui-kit-components';
import { ServiceObligationJuridique2V3 } from '@yolaw/ui-kit-icons';
import animationSrc from '@zen/assets/animation/animation_kbis_zen.json';
import { useIsMobile } from '@zen/hooks/useIsMobile';
import useProject from '@zen/hooks/useProject';
import useSegment from '@zen/hooks/useSegment';
import ApiService from '@zen/services/apis';
import { downloadKbisFile } from '@zen/utils/projects';
import lottie, { AnimationItem } from 'lottie-web';
import React, { useEffect } from 'react';
import styled, { css } from 'styled-components';
import PdfViewer from './PdfViewer';

const Header = styled(Row)`
  ${({ theme }) => css`
    background: ${theme.colors.accentuation.light};
    padding: ${theme.spacing.s}px;

    @media (min-width: ${theme.breakpoints.m}px) {
      border-radius: ${theme.borderRadius.m}px ${theme.borderRadius.m}px 0 0;
    }

    @media (max-width: ${theme.breakpoints.m}px) {
      flex-wrap: wrap;
      justify-content: center;
      text-align: center;
    }
  `}
`;

const Body = styled.div`
  max-width: 720px;
  margin-inline: auto;

  ${({ theme }) => css`
    @media (min-width: ${theme.breakpoints.m}px) {
      padding: ${theme.spacing.l}px;
      border-radius: ${theme.borderRadius.m}px;
    }

    @media (max-width: ${theme.breakpoints.m}px) {
      border-radius: 0 0 ${theme.borderRadius.m}px ${theme.borderRadius.m}px;
    }
  `}
`;

const MainContainer = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(4px);

  ${({ theme }) => css`
    @media (min-width: ${theme.breakpoints.m}px) {
      border-radius: ${theme.borderRadius.m}px;
      filter: drop-shadow(${theme.shadows.blue.medium});
    }
  `}

  &.downloaded-animation-on {
    ${Header}, ${Body} {
      opacity: 0;
    }
  }
`;

const UnlockAnimation = styled.div`
  display: none;
  position: absolute;
  margin: ${({ theme }) => theme.spacing.m}px 0;
  left: 50%;
  transform: translate(-50%, 0%);
  max-width: 300px;
`;

const Subtitle = () => {
  return <Text>Vous pouvez télécharger votre Kbis</Text>;
};

type KbisPreviewerProps = {
  onDownloadCompleted: () => void;
};

const KbisPreviewer = ({ onDownloadCompleted }: KbisPreviewerProps) => {
  const project = useProject();
  const isMobile = useIsMobile();
  const segment = useSegment();

  useEffect(() => {
    segment.track('kbis: displayed');
  }, []);

  const hideAnimation = (animation: AnimationItem) => {
    animation.destroy();

    onDownloadCompleted();
  };

  const showDownloadedAnimation = () => {
    // Hide other content
    const previewerElement = document.getElementById('kbis-previewer');
    previewerElement.classList.add('downloaded-animation-on');

    // Display animation
    const animatedElement = document.getElementById('unlock-animation');
    animatedElement.style.setProperty('display', 'block');

    const animation = lottie.loadAnimation({
      container: animatedElement,
      renderer: 'svg',
      loop: false,
      autoplay: true,
      animationData: animationSrc,
      rendererSettings: {
        preserveAspectRatio: 'xMaxYMax slice'
      }
    });

    animation.addEventListener('complete', () => hideAnimation(animation));
  };

  const downloadKbis = () => {
    downloadKbisFile(project.info);

    if (project.info.service_id) {
      // Notify Core Services that Kbis has been downloaded
      ApiService.services().triggerEvent(project.info.service_id, 'user_has_downloaded_kbis');
    }

    segment.track('kbis: downloaded', { from: 'zen' }, { disableEventNamePrefix: true });

    // Start animation
    showDownloadedAnimation();
  };

  return (
    <MainContainer className="kbis-previewer" id="kbis-previewer">
      <UnlockAnimation id="unlock-animation" />
      <Header className="kbis-preview_header">
        <ServiceObligationJuridique2V3 size="54px" />
        <div style={{ flex: 1 }}>
          <Title type="H4">Félicitations, votre société est créée !</Title>
          {!isMobile && <Subtitle />}
        </div>
        {isMobile && <Subtitle />}

        <Button leftIcon="DownloadFlat" sizing="small" onClick={downloadKbis}>
          Télécharger mon Kbis
        </Button>
      </Header>
      <Body className="kbis-preview_body">
        <PdfViewer fileUrl={project.info.kbis_url} />
      </Body>
    </MainContainer>
  );
};

export default KbisPreviewer;
