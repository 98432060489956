import { Label, Row, Text, Title } from '@yolaw/ui-kit-components';
import { ArrowBlack, LegalStartLogo, LSLogo } from '@yolaw/ui-kit-icons';
import React, { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { CONTENT_MAX_WIDTH, ELEMENT_IDS } from '../constants';
import { useIsMobile } from '../hooks';
import useProject from '../hooks/useProject';
import useSegment from '../hooks/useSegment';
import { redirectToWebCMS, redirectToWebUserSpace } from '../services/redirect';

const LeftBlock = styled(Row)`
  align-items: center;
  cursor: pointer;
  gap: ${({ theme }) => theme.spacing.xxxxs}px;

  #header_left-block_back-icon {
    transform: rotate(180deg);
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.m}px) {
    #header_left-block_label {
      display: none;
    }
  }
`;

const Header = styled.header`
  border-bottom: 1px solid ${({ theme }) => theme.colors.neutral.lightest};
  background-color: ${({ theme }) => theme.colors.common.white};
  position: sticky;
  top: 0;
  z-index: 10;
`;

const LogoWrapper = styled.a`
  line-height: 0;
`;

const HeaderLabel = styled(Label)`
  cursor: pointer;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const CenterBlock = styled(Row)`
  flex: 1;
  align-items: center;
  justify-content: center;
  gap: 0;
  column-gap: ${({ theme }) => theme.spacing.xxxs}px;

  cursor: pointer;

  text-align: center;
  overflow: hidden;

  @media (max-width: ${({ theme }) => theme.breakpoints.m}px) {
    flex-wrap: wrap;
    ${HeaderLabel} {
      width: 100%;
    }
  }
`;

const RightBlock = styled(Row)`
  gap: ${({ theme }) => theme.spacing.xxxxs}px;
  cursor: pointer;
  align-items: center;
`;

const Divider = styled.div`
  width: 1px;
  height: 20px;
  background-color: ${({ theme }) => theme.colors.neutral.lighter};
`;

const HeaderContainer = styled(Row)`
  max-width: ${CONTENT_MAX_WIDTH}px;
  margin-inline: auto;
  padding: ${({ theme }) => theme.spacing.xxxs}px ${({ theme }) => theme.spacing.xs}px;

  ${CenterBlock}[disabled] {
    pointer-events: none;
  }
`;

const AppWithHeader: React.FC = () => {
  const project = useProject();
  const isMobile = useIsMobile();
  const segment = useSegment();
  const navigate = useNavigate();

  useEffect(() => {
    const headerCenterBlock = document.getElementById(ELEMENT_IDS.HEADER.CENTER_BLOCK);
    if (project.onboardingQuestionnaire?.status !== 'done') {
      // Prevent user goes to dashboard by click on header center block if the onboarding hasn't been done
      headerCenterBlock?.setAttribute('disabled', '');
    } else {
      headerCenterBlock?.removeAttribute('disabled');
    }
  }, [project.onboardingQuestionnaire?.status]);

  const headerLabelText = `${isMobile ? 'Gestion' : 'Gestion de la société'} ${
    project.info.company_name || ''
  }`;

  const LSIcon = isMobile ? <LSLogo size={'16px'} /> : <LegalStartLogo width={96} height={16} />;

  const backToWebUserSpace = () => redirectToWebUserSpace();

  const goToDashboard = () => {
    segment.track('Logo Clicked', {
      page_url: window.location.href
    });
    navigate('/', { replace: true });
  };

  const goToCMS = () => redirectToWebCMS();

  return (
    <>
      <Header id={ELEMENT_IDS.HEADER.APP_HEADER}>
        <HeaderContainer>
          <LeftBlock onClick={backToWebUserSpace}>
            {/* FIXME: change icon */}
            <ArrowBlack id="header_left-block_back-icon" />
            <Text type="SMALL" fontWeightVariant="bold" id="header_left-block_label">
              Retour à mon compte
            </Text>
          </LeftBlock>
          <CenterBlock onClick={goToDashboard} id={ELEMENT_IDS.HEADER.CENTER_BLOCK}>
            <LogoWrapper>
              <Title type="H4" color="accentuation.dark">
                Zen
              </Title>
            </LogoWrapper>
            {!isMobile && <Divider />}
            <HeaderLabel text={headerLabelText} sizeVariant={'LARGE'} fontWeightVariant={'bold'} />
          </CenterBlock>
          <RightBlock onClick={goToCMS}>
            <Text type="BODY" fontWeightVariant="bold">
              by
            </Text>
            {LSIcon}
          </RightBlock>
        </HeaderContainer>
      </Header>

      <Outlet />
    </>
  );
};

export default AppWithHeader;
