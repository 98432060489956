import { Button, Column, Row, Text, Title } from '@yolaw/ui-kit-components';
import React, { useContext, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import Context, { RatingStep, ReasonItem, ReasonLevel } from './context';
import useProject from '@zen/hooks/useProject';
import useZenApp from '@zen/hooks/useZenApp';
import useSegment from '@zen/hooks/useSegment';

const REASONS: ReasonItem[] = [
  {
    label: 'Je ne suis pas satisfait du contenu',
    subItems: [
      { label: "Il n'y pas assez de contenu" },
      { label: "Le contenu n'est pas adapté à mon activité" },
      { label: "Je m'attendais à plus de contenu" },
      { label: 'Autre', other: true }
    ]
  },
  {
    label: "Je n'en ai pas besoin",
    subItems: [
      { label: "Je ne savais pas que j'étais abonné" },
      { label: "Mon expert comptable s'en occupe déjà" },
      { label: "J'ai déjà un outil similaire " },
      { label: 'Autre', other: true }
    ]
  },
  {
    label: "C'est trop compliqué à utiliser",
    subItems: [
      { label: "J'aimerais mieux distinguer les types de contenu" },
      { label: "Je n'arrive pas à distinguer mes obligations légales des autres" },
      { label: "Je n'arrive pas à identifier les tâches importantes des autres" },
      { label: 'Autre', other: true }
    ]
  },
  {
    label: 'Autre',
    other: true
  }
];

const ReasonListContainer = styled(Column)`
  align-items: flex-start;
  width: 100%;

  ${({ theme }) => css`
    input[type='radio'] {
      accent-color: ${theme.colors.primary.main};
    }
  `}
`;

const OtherComment = styled.textarea`
  ${({ theme }) => css`
    border-color: ${theme.colors.neutral.lighter};
    border-radius: ${theme.borderRadius.s}px;
    padding: ${theme.spacing.xs}px;
    width: 100%;

    &:focus {
      outline: none;
    }
  `}
`;

type ReasonListProps = {
  level: ReasonLevel;
};

const ReasonList = ({ level }: ReasonListProps) => {
  const app = useZenApp();
  const project = useProject();
  const segment = useSegment();
  const { state, dispatch } = useContext(Context);
  const [reasonList, setReasonList] = useState(REASONS);
  const selectedReason = state.notGoodReason?.[level];

  useEffect(() => {
    segment.track('ratings reasons: displayed', {
      grade: state.grade,
      trigger: app.state.openingModal.openedBy,
      sub_zen: project.info.zen_subscription_status,
      kbis: project.info.has_kbis
    });
  }, []);

  useEffect(() => {
    if (level === ReasonLevel.Child && state.notGoodReason[ReasonLevel.Mother].subItems) {
      setReasonList(state.notGoodReason[ReasonLevel.Mother].subItems);
    }
  }, [level]);

  const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedReasonItem = reasonList.find((reason) => reason.label === event.target.value);
    dispatch({
      notGoodReason: {
        ...state.notGoodReason,
        [level]: selectedReasonItem
      }
    });
  };

  const onCommentChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    dispatch({
      notGoodReason: {
        ...state.notGoodReason,
        comment: event.target.value
      }
    });
  };

  return (
    <>
      <ReasonListContainer>
        {reasonList.map((item) => (
          <Row key={item.label}>
            <input
              type="radio"
              name="reason"
              id={item.label}
              onChange={onInputChange}
              value={item.label}
            />
            <label htmlFor={item.label}>{item.label}</label>
          </Row>
        ))}
        {selectedReason?.other && (
          <OtherComment
            maxLength={500}
            placeholder="Dites-nous en plus..."
            onChange={onCommentChange}
            value={state.notGoodReason.comment}
            rows={3}
          />
        )}
      </ReasonListContainer>
    </>
  );
};

enum ButtonType {
  Submit,
  Next
}

const NotGoodReasonSelector = () => {
  const { state, dispatch } = useContext(Context);
  const [reasonLevel, setReasonLevel] = useState(ReasonLevel.Mother);

  const selectedReason = state.notGoodReason?.[reasonLevel];

  const isButtonDisabled =
    // No reason has been selected
    !selectedReason ||
    // Or the "Other" reason is selected but there is no comment entered
    (!!selectedReason.other && !state.notGoodReason.comment);

  const buttonType =
    reasonLevel === ReasonLevel.Child || selectedReason?.other
      ? ButtonType.Submit
      : ButtonType.Next;

  const onButtonClick = () => {
    if (isButtonDisabled) return;

    if (buttonType === ButtonType.Submit) {
      dispatch({
        ratingStep: RatingStep.ThankYou
      });
      return;
    }

    setReasonLevel(ReasonLevel.Child);
  };

  return (
    <>
      <Title type="H3">
        {reasonLevel === ReasonLevel.Mother
          ? 'Aidez-nous à nous améliorer'
          : `Vous avez choisi “${state.notGoodReason[ReasonLevel.Mother].label}”`}
      </Title>
      <Text type="BODY">
        {reasonLevel === ReasonLevel.Mother
          ? 'Votre avis est important pour notre amélioration continue.'
          : 'Pouvez-vous nous donner plus de détails s’il vous plaît ?'}
      </Text>

      <ReasonList level={reasonLevel} />

      <Button
        disabled={isButtonDisabled}
        text={buttonType === ButtonType.Submit ? 'Soumettre mon avis' : 'Continuer'}
        rightIcon="ArrowRight"
        onClick={onButtonClick}
      />
    </>
  );
};

export default NotGoodReasonSelector;
