import { Text } from '@yolaw/ui-kit-components';
import React, { useEffect, useLayoutEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useProject, useSegment } from '../../hooks';
import {
  isDocumentTask,
  isHubspotPageTask,
  isQuestionnaireTask,
  isWebinarTask
} from '../../utils/tasks';
import DocumentGenerationViewer from './DocumentGeneration/DocumentGenerationViewer';
import HubspotPageViewer from './HubspotPage';
import QuestionnaireViewer from './Questionnaire';

type TaskViewerProps = {
  task: TaskItem;
};

const TaskViewer = ({ task }: TaskViewerProps) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const segment = useSegment();
  const project = useProject();

  useLayoutEffect(() => {
    document.body.style.setProperty('background-color', 'white');
  }, []);

  useEffect(() => {
    segment.track('task: displayed', {
      sub_aj: project.info.aj_subscription_status,
      sub_zen: project.info.zen_subscription_status,
      sub_cs: project.info.cs_subscription_status,
      kbis: project.info.has_kbis,
      task_name: task.type.title,
      task_id: task.id,
      task_type: task.type.kind,
      task_category: task.type.category,
      task_slug: task.type.slug,
      from: searchParams.get('utm_source')
    });

    // Clear search params after use to clean the visible URL
    setSearchParams([]);
  }, []);

  const taskType = task.type.kind;

  if (isQuestionnaireTask(task))
    return <QuestionnaireViewer questions={task.details.questionnaire} />;

  if (isHubspotPageTask(task)) return <HubspotPageViewer task={task} />;

  if (isDocumentTask(task)) return <DocumentGenerationViewer taskId={task.id} />;

  if (isWebinarTask(task))
    return <Text color="error.main">{`[TaskType] unhandled ${taskType}`}</Text>;

  return <Text color="error.main">{`[TaskType] unknown ${taskType}`}</Text>;
};

export default TaskViewer;
