import { Button, Column, Label } from '@yolaw/ui-kit-components';
import React, { useContext, useEffect, useRef, useState } from 'react';
import ReactSignatureCanvas from 'react-signature-canvas';
import styled, { css } from 'styled-components';
import { DocumentGenerationContext } from '../context';

const SignatureInput = styled(Column)`
  ${({ theme }) => css`
    row-gap: ${theme.spacing.xxxs}px;

    canvas {
      border: 1px solid ${theme.colors.neutral.lighter};
      border-radius: ${theme.borderRadius.s}px;
      min-height: 128px;
    }
  `}
`;

const ResignButtonContainer = styled.div`
  align-self: flex-end;

  button > div {
    gap: 0px;
  }
`;

type SignaturePadProps = {
  onSignatureChange: (keyName: string, dataUrl: string) => void;
  label?: string;
  name?: string;
};

const SignaturePad = (props: SignaturePadProps) => {
  const signatureCanvasRef = useRef<ReactSignatureCanvas>(null);
  const { onSignatureChange, label, name = 'signature' } = props;

  const documentContext = useContext(DocumentGenerationContext);
  const { formData } = documentContext.state;

  const [hasSigned, setHasSigned] = useState(!!formData[name]);

  useEffect(() => {
    // Display the signature if it was signed
    signatureCanvasRef.current?.fromDataURL(formData[name]);
  }, [signatureCanvasRef.current]);

  useEffect(() => {
    setHasSigned(!!formData[name]);
  }, [formData[name]]);

  const handleSignatureChange = () => {
    const signature = signatureCanvasRef.current.toDataURL();
    onSignatureChange(name, signature);
  };

  const clearSignature = () => {
    signatureCanvasRef.current.clear();
    documentContext.dispatch({
      type: 'PATCH_FORM_DATA',
      payload: {
        [name]: null
      }
    });
  };

  return (
    <SignatureInput>
      {label && <Label sizeVariant="XSMALL" fontWeightVariant="bold" text={label} />}
      <ReactSignatureCanvas
        ref={signatureCanvasRef}
        onEnd={handleSignatureChange}
        clearOnResize={false}
      />
      <ResignButtonContainer>
        <Button
          variant="tertiary"
          leftIcon="RestartFlat"
          text="Recommencer"
          disabled={!hasSigned}
          onClick={clearSignature}
        />
      </ResignButtonContainer>
    </SignatureInput>
  );
};

export default React.memo(SignaturePad);
