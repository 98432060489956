import { Button, Container, Text, Title } from '@yolaw/ui-kit-components';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled, { css } from 'styled-components';

const Iframe = styled.iframe`
  width: 560px;
  ${({ theme }) => css`
    @media (max-width: ${theme.breakpoints.m}px) {
      width: 100%;
    }
  `};
`;

const Succeed = () => {
  const navigate = useNavigate();

  const navigateToDashboard = () => navigate('/', { replace: true });

  const handleCtaClick = () => {
    navigateToDashboard();
  };

  return (
    <>
      <Title type="H2" color="secondary.main">
        🎉 Super, vous avez votre attestation pour le mois de mars&nbsp;! Et maintenant comment
        faire&nbsp;?
      </Title>
      <Container size="S">
        <Text type="BODY" color="neutral.dark">
          💡 Suivez le tutoriel pour envoyer votre attestation à Pole Emploi
        </Text>
      </Container>
      <Iframe
        height="315"
        src="https://www.youtube.com/embed/380wUg2p6FY"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      />
      <Container size="XS">
        <Text type="BODY" color="neutral.dark" fontWeightVariant="bold">
          ⚠️ Vous devez joindre vos statuts à votre attestation de non rémunération.
        </Text>
      </Container>

      <Container size="XS">
        <Button text="C'est fait !" onClick={handleCtaClick} />
      </Container>
    </>
  );
};

export default Succeed;
