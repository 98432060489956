/// <reference types="googlepay" />

import config from '../services/config';

/* eslint-disable prefer-object-spread */

/**
 * Define the version of the Google Pay API referenced when creating your
 * configuration
 *
 * @see {@link https://developers.google.com/pay/api/web/reference/request-objects#PaymentDataRequest|apiVersion in PaymentDataRequest}
 */
const baseRequest = {
  apiVersion: 2,
  apiVersionMinor: 0
};

/**
 * Card networks supported by your site and your gateway
 *
 * @see {@link https://developers.google.com/pay/api/web/reference/request-objects#CardParameters|CardParameters}
 * @todo confirm card networks supported by your site and gateway
 */
const allowedCardNetworks = ['AMEX', 'MASTERCARD', 'VISA'] as google.payments.api.CardNetwork[];

/**
 * Card authentication methods supported by your site and your gateway
 *
 * @see {@link https://developers.google.com/pay/api/web/reference/request-objects#CardParameters|CardParameters}
 * @todo confirm your processor supports Android device tokens for your
 * supported card networks
 */
const allowedCardAuthMethods = [
  'PAN_ONLY',
  'CRYPTOGRAM_3DS'
] as google.payments.api.CardAuthMethod[];

/**
 * Identify your gateway and your site's gateway merchant identifier
 *
 * The Google Pay API response will return an encrypted payment method capable
 * of being charged by a supported gateway after payer authorization
 *
 * @todo check with your gateway on the parameters to pass
 * @see {@link https://developers.google.com/pay/api/web/reference/request-objects#gateway|PaymentMethodTokenizationSpecification}
 */
const tokenizationSpecification: google.payments.api.PaymentMethodTokenizationSpecification = {
  type: 'PAYMENT_GATEWAY',
  parameters: {
    gateway: 'stripe',
    'stripe:version': '2019-12-03',
    'stripe:publishableKey': config.STRIPE_PUBLISHABLE_KEY
  }
};
let amountToPayGooglePay = '0';
/**
 * Describe your site's support for the CARD payment method and its required
 * fields
 *
 * @see {@link https://developers.google.com/pay/api/web/reference/request-objects#CardParameters|CardParameters}
 */
const baseCardPaymentMethod: google.payments.api.IsReadyToPayPaymentMethodSpecification = {
  type: 'CARD',
  parameters: {
    allowedAuthMethods: allowedCardAuthMethods,
    allowedCardNetworks,
    billingAddressRequired: true,
    billingAddressParameters: { format: 'FULL', phoneNumberRequired: true }
  }
};

/**
 * Describe your site's support for the CARD payment method including optional
 * fields
 *
 * @see {@link https://developers.google.com/pay/api/web/reference/request-objects#CardParameters|CardParameters}
 */
const cardPaymentMethod = Object.assign({}, baseCardPaymentMethod, {
  tokenizationSpecification
}) as google.payments.api.PaymentMethodSpecification;

/**
 * An initialized google.payments.api.PaymentsClient object or null if not yet set
 *
 * @see {@link getGooglePaymentsClient}
 */
let paymentsClient: google.payments.api.PaymentsClient | null = null;

/**
 * Configure your site's support for payment methods supported by the Google Pay
 * API.funnelId
 *
 * Each member of allowedPaymentMethods should contain only the required fields,
 * allowing reuse of this base request when determining a viewer's ability
 * to pay and later requesting a supported payment method
 *
 * @returns {object} Google Pay API version, payment methods supported by the site
 */
function getGoogleIsReadyToPayRequest() {
  return Object.assign({}, baseRequest, {
    allowedPaymentMethods: [baseCardPaymentMethod],
    existingPaymentMethodRequired: true
  });
}

/**
 * Provide Google Pay API with a payment amount, currency, and amount status
 *
 * @see {@link https://developers.google.com/pay/api/web/reference/request-objects#TransactionInfo|TransactionInfo}
 * @returns {object} transaction info, suitable for use as transactionInfo property of PaymentDataRequest
 */
function getGoogleTransactionInfo(totalPrice?: string) {
  return {
    countryCode: 'FR',
    currencyCode: 'EUR',
    totalPriceStatus: 'FINAL' as google.payments.api.TotalPriceStatus,
    totalPrice: totalPrice || amountToPayGooglePay,
    totalPriceLabel: 'Total'
  };
}

/**
 * Configure support for the Google Pay API
 *
 * @see {@link https://developers.google.com/pay/api/web/reference/request-objects#PaymentDataRequest|PaymentDataRequest}
 * @returns {object} PaymentDataRequest fields
 */
function getGooglePaymentDataRequest(totalPrice?: string) {
  const paymentDataRequest = Object.assign(
    {},
    baseRequest
  ) as google.payments.api.PaymentDataRequest;
  paymentDataRequest.allowedPaymentMethods = [cardPaymentMethod];
  paymentDataRequest.transactionInfo = getGoogleTransactionInfo(totalPrice);
  paymentDataRequest.merchantInfo = {
    merchantId: '09730011297687663075',
    merchantName: 'Legalstart'
  };
  // paymentDataRequest.shippingAddressRequired = true;
  paymentDataRequest.emailRequired = true;
  paymentDataRequest.callbackIntents = ['PAYMENT_AUTHORIZATION'];
  return paymentDataRequest;
}

/**
 * Show Google Pay payment sheet when Google Pay payment button is clicked
 */
function onGooglePaymentButtonClicked() {
  const paymentDataRequest = getGooglePaymentDataRequest();
  paymentDataRequest.transactionInfo = getGoogleTransactionInfo();

  if (paymentsClient) {
    paymentsClient.loadPaymentData(paymentDataRequest);
  }
}

/**
   * Add a Google Pay
  ://developers.google.com/pay/api/web/guides/brand-guidelines|Google Pay brand guidelines}
   */
function addGooglePayButton() {
  const button = paymentsClient?.createButton({
    onClick: onGooglePaymentButtonClicked
  });
  const googlePayContainerElm = document.getElementById('googlePayContainer');

  if (button && googlePayContainerElm) {
    if (!googlePayContainerElm.hasChildNodes()) {
      googlePayContainerElm.appendChild(button);
    }
  }
}

/**
 * Process payment data returned by the Google Pay API
 *
 * @param {object} paymentData response from Google Pay API after user approves payment
 * @see {@link https://developers.google.com/pay/api/web/reference/response-objects#PaymentData|PaymentData object reference}
 * @returns Promise
 */
// function processPayment(workflowId, paymentData) {
//   return new Promise((resolve) => {
//     setTimeout(() => {
//       Payment.attemptGooglePayment(workflowId, paymentData)
//         .then(resolve({ paymentData }))
//         .catch((error) => {
//           throw error;
//         });
//     }, 500);
//   });
// }

/**
 * Handles authorize payments callback intents.
 *
 * @param {object} paymentData response from Google Pay API after a payer approves payment through user gesture.
 * @see {@link https://developers.google.com/pay/api/web/reference/response-objects#PaymentData object reference}
 *
 * @see {@link https://developers.google.com/pay/api/web/reference/response-objects#PaymentAuthorizationResult}
 * @returns Promise<{object}> Promise of PaymentAuthorizationResult object to acknowledge the payment authorization status.
 */
function onPaymentAuthorized(
  paymentData: google.payments.api.PaymentData,
  onAuthorizedCallback: (data: google.payments.api.PaymentData) => any
) {
  return new Promise((resolve) => {
    // handle the response
    onAuthorizedCallback(paymentData)
      // processPayment(paymentData)
      .then(
        resolve({
          transactionState: 'SUCCESS'
        })
      )
      .catch(
        resolve({
          transactionState: 'ERROR',
          error: {
            intent: 'PAYMENT_AUTHORIZATION',
            message: 'Insufficient funds, try again. Next attempt should work.',
            reason: 'PAYMENT_DATA_INVALID'
          }
        })
      );
  });
}

/**
 * Return an active PaymentsClient or initialize
 *
 * @see {@link https://developers.google.com/pay/api/web/reference/client#PaymentsClient|PaymentsClient constructor}
 * @returns {google.payments.api.PaymentsClient} Google Pay API client
 */
function getGooglePaymentsClient(
  onAuthorizedCallback: (
    paymentData: google.payments.api.PaymentData
  ) => Promise<google.payments.api.PaymentAuthorizationResult>
) {
  if (paymentsClient === null) {
    // eslint-disable-next-line no-undef
    paymentsClient = new google.payments.api.PaymentsClient({
      //   environment: config.NODE_ENV === 'production' ? 'PRODUCTION' : 'TEST',
      paymentDataCallbacks: {
        onPaymentAuthorized: (paymentData) =>
          onPaymentAuthorized(
            paymentData,
            onAuthorizedCallback
          ) as Promise<google.payments.api.PaymentAuthorizationResult>
      }
    });
  }
  return paymentsClient;
}

/**
 * Initialize Google PaymentsClient after Google-hosted JavaScript has loaded
 *
 * Display a Google Pay payment button after confirmation of the viewer's
 * ability to pay.
 */
function onGooglePayLoaded(
  amountToPay: number,
  onAuthorizedCallback: (paymentData: any) => Promise<any>
) {
  amountToPayGooglePay = amountToPay.toString();
  paymentsClient = getGooglePaymentsClient(onAuthorizedCallback);
  paymentsClient.isReadyToPay(getGoogleIsReadyToPayRequest()).then((response) => {
    if (response.result) {
      addGooglePayButton(); // @todo prefetch payment data to improve performance after confirming site functionality // prefetchGooglePaymentData();
    }
  });
}

/**
 * Prefetch payment data to improve performance
 *
 * @see {@link https://developers.google.com/pay/api/web/reference/client#prefetchPaymentData|prefetchPaymentData()}
 */
function prefetchGooglePaymentData() {
  const paymentDataRequest = getGooglePaymentDataRequest(); // transactionInfo must be set but does not affect cache
  paymentDataRequest.transactionInfo = {
    totalPriceStatus: 'NOT_CURRENTLY_KNOWN',
    currencyCode: 'EUR'
  } as google.payments.api.TransactionInfo;

  if (paymentsClient) {
    paymentsClient.prefetchPaymentData(paymentDataRequest);
  }
}

const GooglePayProvider = {
  getGooglePaymentDataRequest,
  onGooglePayLoaded,
  prefetchGooglePaymentData
};

export default GooglePayProvider;
