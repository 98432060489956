import { Column, Label, Row, Tag, Text } from '@yolaw/ui-kit-components';
import { Arrow, LockFlat } from '@yolaw/ui-kit-icons';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { ModalName } from '../../contexts/app';
import { useProject, useSegment } from '../../hooks';
import useTask from '../../hooks/useTask';
import useZenApp from '../../hooks/useZenApp';
import { PATH_SLUGS } from '../../services/routes';
import TaskCardDate from './TaskCardDate';

const TaskDetails = styled(Column)`
  flex: 1;

  ${({ theme }) => css`
    row-gap: ${theme.spacing.xxxs}px;
    @media (min-width: ${theme.breakpoints.m}px) {
      flex-direction: row;
      .date-alignment {
        min-width: 128px;
      }
    }
  `};
`;

const ContentContainer = styled(Row)`
  ${({ theme }) => css`
    justify-content: space-between;
    align-items: center;
    padding: ${theme.spacing.xs}px;
    background: ${theme.colors.common.white};
    border-radius: ${theme.borderRadius.s}px;
    cursor: pointer;
    * {
      cursor: pointer;
    }

    @media (min-width: ${theme.breakpoints.m}px) {
      transition: transform 0.3s ease-in-out;
      :hover {
        transform: scale(1.01);
      }
    }
    @media (max-width: ${theme.breakpoints.m}px) {
      column-gap: ${theme.spacing.xxxs}px;
    }
  `};
`;

const Header = styled(Column)`
  flex-grow: 2;
  gap: ${(props) => props.theme.spacing.xxxxs}px;
`;

const TaskCategory = styled.div`
  @media (min-width: ${(props) => props.theme.breakpoints.m}px) {
    display: flex;
    justify-content: center;
    min-width: 120px;
  }
`;

const StyledTag = styled(Tag)`
  text-transform: uppercase;
`;

const ZenLock = styled(Row)`
  justify-content: center;
  ${({ theme }) => css`
    column-gap: ${theme.spacing.xxxs}px;
    @media (min-width: ${(props) => props.theme.breakpoints.m}px) {
      padding: 0 ${theme.spacing.xs}px;
    }
  `}
`;

const StyledArrow = styled(Arrow)`
  flex-shrink: 0;
  @media (max-width: ${({ theme }) => theme.breakpoints.m}px) {
    display: none;
  }
`;

const TaskCardContainer = styled.div`
  ${({ theme }) => css`
    ${ContentContainer} {
      box-shadow: ${theme.shadows.blue.small};

      @media (min-width: ${theme.breakpoints.m}px) {
        :hover {
          box-shadow: ${theme.shadows.blue.stronger};
        }
      }
    }
  `}
`;

const getCategoryColor = (category: TaskItem['type']['category']) => {
  switch (category.toUpperCase()) {
    case 'ASSURANCE':
      return 'green';
    case 'ADMINISTRATIF':
      return 'gray';
    case 'FISCALITÉ':
      return 'blue';
    case 'RÉMUNÉRATION':
      return 'orange';
    default:
      return 'gray';
  }
};

type TaskCardProps = {
  taskId: TaskItem['id'];
};

const TaskCard: React.FC<TaskCardProps> = ({ taskId }: TaskCardProps) => {
  const app = useZenApp();
  const project = useProject();
  const segment = useSegment();
  const task = useTask(taskId);
  const {
    id,
    type: { category, kind, slug, subtitle, title },
    unlock_url,
    isPendingSiren,
    isAccessible
  } = task;

  const navigate = useNavigate();

  const handleLockedTaskClick = () => {
    const commonEventProperties = {
      sub_aj: project.info.aj_subscription_status,
      sub_zen: project.info.zen_subscription_status,
      sub_cs: project.info.cs_subscription_status,
      kbis: project.info.has_kbis,
      task_category: category,
      task_name: title,
      task_slug: slug,
      task_type: kind
    };

    if (isPendingSiren) {
      segment.track('pending task: clicked', {
        ...commonEventProperties,
        pending: 'siren'
      });
      app.modal.openModal(ModalName.WaitingForSiren, 'pending task');
      return;
    }

    if (unlock_url) {
      segment.track('locked task: clicked', {
        ...commonEventProperties,
        blocked_by: 'aj' // FIXME: there is only AJ has unlock_url for now
      });
      window.location.assign(unlock_url);
      return;
    }

    // FIXME: need a better mechanism to check and decide unlock action
    if (project.isZenSubscriptionNeeded) {
      segment.track('locked task: clicked', {
        ...commonEventProperties,
        blocked_by: 'zen'
      });
      app.modal.openModal(ModalName.ZenSubscriptionPayment, 'locked task');
      return;
    }

    console.error('⚠️ Unlock action is not defined!');
  };

  const handleCardClick = () => {
    if (isPendingSiren || !isAccessible) {
      handleLockedTaskClick();
      return;
    }

    navigate(`./${PATH_SLUGS.TASK}/${id}/?utm_source=dashboard`);
  };

  return (
    <TaskCardContainer>
      <ContentContainer onClick={handleCardClick}>
        <TaskDetails>
          <Header>
            <Label color="secondary.main" text={title} sizeVariant="LARGE" />
            <Text type="SMALL" color="neutral.dark">
              {subtitle}
            </Text>
          </Header>
          <Row style={{ alignItems: 'center' }}>
            <TaskCategory>
              <StyledTag text={category.toUpperCase()} color={getCategoryColor(category)} />
            </TaskCategory>
            {isAccessible ? (
              <TaskCardDate taskId={id} />
            ) : (
              <ZenLock className="date-alignment">
                <Text type="SMALL" color="primary.main" fontWeightVariant="bold">
                  Zen
                </Text>
                <Text type="BODY" color="primary.main" fontWeightVariant="bold">
                  <LockFlat />
                </Text>
              </ZenLock>
            )}
          </Row>
        </TaskDetails>
        <StyledArrow width="12px" />
      </ContentContainer>
    </TaskCardContainer>
  );
};

export default TaskCard;
