import React from 'react';
import { Outlet } from 'react-router-dom';
import ChatButton from '../components/ChatButton';
import useProject from '../hooks/useProject';

/** Display the ChatButton at the bottom right of page */
const LegalAssistantAvailableArea = () => {
  const project = useProject();
  const shouldDisplayChatButton = !!project.info.zen_subscription_status;

  return (
    <>
      <Outlet />
      {shouldDisplayChatButton && <ChatButton />}
    </>
  );
};

export default LegalAssistantAvailableArea;
