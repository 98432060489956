import { useCallback } from 'react';

import useProject from './useProject';

type TrackEventOptions = {
  disableEventNamePrefix?: boolean;
};

const useSegment = () => {
  const project = useProject();

  const identify = useCallback(
    (userId: string | number, traits?: Record<string, any>) => {
      window.analytics.identify(userId, traits);
    },
    [project.info.id]
  );

  const commonProperties = {
    project_id: project.info.id
  };

  const track = useCallback(
    (eventName: string, eventProperties?: Record<string, any>, options?: TrackEventOptions) => {
      const name = (options?.disableEventNamePrefix ? '' : 'zen ') + eventName;

      const properties = {
        ...commonProperties,
        ...eventProperties
      };

      window.analytics.track(name, properties);
    },
    [commonProperties]
  );

  return {
    identify,
    track
  };
};

export default useSegment;
