import { ToastContainer } from 'react-toastify';
import styled, { css } from 'styled-components';

const ZenToastContainer = styled(ToastContainer)`
  ${({ theme }) => css`
    &&&.Toastify__toast-container--top-right,
    &&&.Toastify__toast-container--bottom-right,
    &&&.Toastify__toast-container--top-left,
    &&&.Toastify__toast-container--bottom-left {
      .Toastify__toast {
        border-left: 6px solid;
      }
    }

    &&&.Toastify__toast-container {
      .Toastify__toast--info {
        border-color: ${theme.colors.information.main};
      }
      .Toastify__toast--error {
        border-color: ${theme.colors.error.main};
      }
      .Toastify__toast--success {
        border-color: ${theme.colors.primary.main};
      }
      .Toastify__toast--warning {
        border-color: ${theme.colors.warning.main};
      }
    }

    .Toastify__toast {
      padding: 16px;
      border-radius: ${theme.borderRadius.xs}px;
    }
  `};
`;

export default ZenToastContainer;
