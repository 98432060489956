import { Elements } from '@stripe/react-stripe-js';
import { Button, Column, Text } from '@yolaw/ui-kit-components';
import { useIsMobile } from '@zen/hooks/useIsMobile';
import useSegment from '@zen/hooks/useSegment';
import React, { useEffect, useReducer } from 'react';
import styled, { css } from 'styled-components';
import { SUPPORT_CONTACT } from '../../../constants';
import { ModalName } from '../../../contexts/app';
import usePrevious from '../../../hooks/usePrevious';
import useProject from '../../../hooks/useProject';
import useZenApp from '../../../hooks/useZenApp';
import StripeProvider from '../../../providers/stripe';
import ApiService from '../../../services/apis';
import BaseModal from '../BaseModal';
import PaymentModalContext, { PaymentModalContextAction, PaymentModalStep } from './context';
import { IntroductionPage, PaymentPage, PlanSelectionPage } from './pages';

const BodyContainer = styled(Column)`
  align-items: center;
  text-align: center;

  ${({ theme }) => css`
    row-gap: ${theme.spacing.s}px;

    .phone-support-container {
      button {
        margin-bottom: ${theme.spacing.xxxs}px;
        > .disabled {
          color: ${theme.colors.secondary.main};
        }
      }
    }
  `}

  sup {
    font-size: 0.5em;
  }
`;

const getStepNameForTrackingEvent = (step: PaymentModalStep) => {
  switch (step) {
    case PaymentModalStep.Introduction:
      return 'benefits';
    case PaymentModalStep.PlanSelection:
      return 'plans';
    case PaymentModalStep.Payment:
      return 'form';
  }
};

const ZenSubscriptionModal = () => {
  const app = useZenApp();
  const project = useProject();
  const segment = useSegment();
  const isMobile = useIsMobile();
  const prevOpeningModal = usePrevious(app.state.openingModal);

  const [state, dispatch] = useReducer(
    PaymentModalContext.reducer,
    PaymentModalContext.initialState
  );
  const prevStep = usePrevious(state.currentModalStep);

  const getZenSubscriptionDetails = async () => {
    const zenSubscription = await ApiService.subscription('zen').getSubscriptionDetails();
    dispatch({
      type: PaymentModalContextAction.SetZenSubscriptionDetails,
      payload: zenSubscription
    });
  };

  const getSavedPaymentMethods = async () => {
    const savedPaymentMethods = await ApiService.user().getPaymentMethods();
    dispatch({
      type: PaymentModalContextAction.SetSavedPaymentMethods,
      payload: savedPaymentMethods
    });
  };

  useEffect(() => {
    // Whenever the modal is opened
    if (app.state.openingModal?.name === ModalName.ZenSubscriptionPayment) {
      if (!state.zenSubscription) {
        // Get subscription info if it's not there
        getZenSubscriptionDetails();
      }
      if (!state.savedPaymentMethods) {
        // Get saved payment method if it's not there
        getSavedPaymentMethods();
      }
    }

    // When `ModalName.ZenSubscriptionPayment` modal is closed, reset its step to `PaymentModalStep.Introduction`
    if (prevOpeningModal?.name === ModalName.ZenSubscriptionPayment && !app.state.openingModal) {
      dispatch({
        type: PaymentModalContextAction.SetPaymentModalStep,
        payload: PaymentModalStep.Introduction
      });
    }
  }, [app.state.openingModal]);

  useEffect(() => {
    if (app.state.openingModal?.name === ModalName.ZenSubscriptionPayment) {
      // Only check if the modal is currently opened
      const comeFrom =
        state.currentModalStep === prevStep
          ? app.state.openingModal.openedBy // modal just opened
          : getStepNameForTrackingEvent(prevStep); // jumping between steps

      segment.track('paywall: displayed', {
        kbis: project.info.has_kbis,
        subscription_type: 'zen',
        step: getStepNameForTrackingEvent(state.currentModalStep),
        from: comeFrom
      });
    }
  }, [state.currentModalStep, app.state.openingModal]);

  useEffect(() => {
    const modalDialogElm = document.querySelector<HTMLElement>(
      `#${ModalName.ZenSubscriptionPayment} > .modal-dialog`
    );

    // This is a big modal => take the full possible width
    if (modalDialogElm) {
      modalDialogElm.style.width = '100%';
    }

    return () => {
      modalDialogElm.style.width = 'unset';
    };
  }, []);

  const onClickBackHandler = () => {
    switch (state.currentModalStep) {
      case PaymentModalStep.PlanSelection:
        return () =>
          dispatch({
            type: PaymentModalContextAction.SetPaymentModalStep,
            payload: PaymentModalStep.Introduction
          });
      case PaymentModalStep.Payment:
        return () =>
          dispatch({
            type: PaymentModalContextAction.SetPaymentModalStep,
            payload: PaymentModalStep.PlanSelection
          });
      case PaymentModalStep.Introduction:
      default:
        return undefined;
    }
  };

  const renderPageContent = () => {
    switch (state.currentModalStep) {
      case PaymentModalStep.Introduction:
        return <IntroductionPage />;
      case PaymentModalStep.PlanSelection:
        return <PlanSelectionPage />;
      case PaymentModalStep.Payment:
      default:
        return <PaymentPage />;
    }
  };

  const handlePhoneButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    window.location.href = `tel:${SUPPORT_CONTACT.PHONE_NUMBER}`;
  };

  return (
    <Elements stripe={StripeProvider.stripePromise} options={StripeProvider.stripeElementOptions}>
      <PaymentModalContext.Context.Provider value={{ state, dispatch }}>
        <BaseModal id={ModalName.ZenSubscriptionPayment} onClickBack={onClickBackHandler()}>
          <BodyContainer>
            {renderPageContent()}
            <div className="phone-support-container">
              <Button
                variant="secondary"
                sizing="small"
                leftIcon="IconPhone"
                rightIcon="FlagFr"
                disabled={!isMobile}
                onClick={handlePhoneButtonClick}
              >
                {SUPPORT_CONTACT.PHONE_NUMBER}
              </Button>
              <Text type="small" color="neutral.dark">
                Nos conseillers sont disponibles du lundi au vendredi de 10h à 18h
              </Text>
            </div>
          </BodyContainer>
        </BaseModal>
      </PaymentModalContext.Context.Provider>
    </Elements>
  );
};

export default ZenSubscriptionModal;
