import { Column, Text } from '@yolaw/ui-kit-components';
import useTask from '@zen/hooks/useTask';
import React, { useReducer } from 'react';
import { Route, Routes } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { CONTENT_MAX_WIDTH } from '../../../constants';
import QuestionnaireViewer from '../Questionnaire';
import DocGenPage from './DocGenPage';
import { DocumentGenerationContext, initialState, reducer } from './context';
import { getTaskConfigsBySlug } from './taskConfigs';

const TaskContainer = styled(Column)`
  align-items: center;
  justify-content: center;
  margin-inline: auto;
  text-align: center;
  ${({ theme }) => css`
    @media (min-width: ${theme.breakpoints.m}px) {
      max-width: ${CONTENT_MAX_WIDTH}px;
    }

    @media (max-width: ${theme.breakpoints.m}px) {
      max-width: 100vw;
    }
  `};
`;

type DocumentGenerationViewerProps = {
  taskId: TaskItem['id'];
};

const DocumentGenerationViewer = ({ taskId }: DocumentGenerationViewerProps) => {
  const task = useTask(taskId);

  const [state, dispatch] = useReducer(reducer, initialState);

  const taskLocalConfigs = getTaskConfigsBySlug(task.type.slug);

  if (!taskLocalConfigs) {
    return (
      <Text color="error.main">{`[DocumentGenerationViewer] Unhandled task slug \`${task.type.slug}\``}</Text>
    );
  }

  const taskServerConfigs = task.details as DocumentTask.Details;

  return (
    <DocumentGenerationContext.Provider value={{ state, dispatch }}>
      <TaskContainer>
        <Routes>
          <Route
            path="*"
            element={
              taskServerConfigs.pages ? (
                <QuestionnaireViewer
                  questions={taskServerConfigs.pages}
                  extraComponents={{ DocGenPage }}
                />
              ) : (
                <DocGenPage />
              )
            }
          />

          {taskLocalConfigs.AskForPostponeReason && (
            <Route path="postpone" element={taskLocalConfigs.AskForPostponeReason} />
          )}
          <Route path="succeed" element={taskLocalConfigs.SucceedPage} />
        </Routes>
      </TaskContainer>
    </DocumentGenerationContext.Provider>
  );
};

export default DocumentGenerationViewer;
