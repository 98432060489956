import Subscription from '@zen/types/subscription';
import SubscriptionApiEndpoint from '../endpoints/subscriptions';
import { commonApiErrorHandler } from './errors';

const getSubscriptionDetails = async (subscriptionTypeSlug: string) => {
  try {
    const response = await SubscriptionApiEndpoint.getSubscriptionDetails(subscriptionTypeSlug);
    return response.data;
  } catch (error) {
    return commonApiErrorHandler(error);
  }
};

const getPlanDetails = async (subscriptionTypeSlug: string, planSlug: string) => {
  try {
    const response = await SubscriptionApiEndpoint.getPlanDetails(subscriptionTypeSlug, planSlug);
    return response.data;
  } catch (error) {
    return commonApiErrorHandler(error);
  }
};

const initiateStripePayment = async (data: Subscription.StripePayment.InitialRequestData) => {
  try {
    const response = await SubscriptionApiEndpoint.initiateStripePayment(data);
    return response.data;
  } catch (error) {
    return commonApiErrorHandler(error);
  }
};

const confirmStripePayment = async (data: Subscription.StripePayment.ConfirmRequestData) => {
  try {
    const response = await SubscriptionApiEndpoint.confirmStripePayment(data);
    return response.data;
  } catch (error) {
    return commonApiErrorHandler(error);
  }
};

const SubscriptionApiHandler = {
  getSubscriptionDetails,
  getPlanDetails,
  initiateStripePayment,
  confirmStripePayment
};

export default SubscriptionApiHandler;
