declare const CORE_API_URL: string;
declare const NODE_ENV: string;
declare const SENTRY_DSN: string;
declare const STRIPE_PUBLISHABLE_KEY: string;
declare const WEB_APP_DOMAIN: string;
declare const WEB_CMS_URL: string;
declare const LEGALZEN_API_URL: string;

export default {
  CORE_API_URL,
  NODE_ENV,
  SENTRY_DSN,
  STRIPE_PUBLISHABLE_KEY,
  WEB_APP_DOMAIN,
  WEB_CMS_URL,
  LEGALZEN_API_URL
};
