import { Button } from '@yolaw/ui-kit-components';
import { PaymentMethod, PaymentOption } from '@zen/services/payment';
import React, { useEffect } from 'react';
import useZenPayment from '../../../../../../hooks/useZenPayment';
import { usePaymentModalContext } from '../../../hooks';
import ApplePayButton from './ApplePay';
import GooglePayButton from './GooglePay';

const PayButton = () => {
  const paymentModal = usePaymentModalContext();
  const zenPayment = useZenPayment(paymentModal.selectedPlan);
  const { isReadyToPay, paymentMethod, paymentOption } = paymentModal.state;

  useEffect(() => {
    paymentModal.action.setPaymentError(zenPayment.paymentError);
  }, [zenPayment.paymentError]);

  const handleCardPayment = () => {
    zenPayment.payWithCard(paymentModal.state.selectedCard?.payment_method_id);
  };

  const shouldDisableButton = !isReadyToPay || zenPayment.isProcessingPayment;

  if (paymentOption === PaymentOption.UseSavedCard || paymentMethod === PaymentMethod.Card) {
    return (
      <Button
        disabled={shouldDisableButton}
        isLoading={zenPayment.isProcessingPayment}
        onClick={handleCardPayment}
      >
        M’abonner à Zen
      </Button>
    );
  }

  if (paymentMethod === PaymentMethod.ApplePay) {
    return <ApplePayButton disabled={shouldDisableButton} />;
  }

  if (paymentMethod === PaymentMethod.GooglePay) {
    return <GooglePayButton disabled={shouldDisableButton} />;
  }
};

export default React.memo(PayButton);
