import { AxiosResponse } from 'axios';
import LSUser from '../../../../types/user';
import coreApi from '../coreApi';

const getPaymentMethods = (): Promise<AxiosResponse<LSUser.PaymentMethodItem[]>> =>
  coreApi.get('/users/me/payment-methods');

const getUserInfo = (): Promise<AxiosResponse<LSUser.Info>> => coreApi.get('/users/me/');

const refreshToken = (rfToken: string) =>
  coreApi.post<{ access: string }>('/auth/token/refresh/', {
    refresh: rfToken
  });

const UserApiEndpoint = {
  getPaymentMethods,
  getUserInfo,
  refreshToken
};

export default UserApiEndpoint;
