import React from 'react';
import { defaultTheme } from '@yolaw/ui-kit-components';

function useMediaQuery<T>(query: string, whenTrue: T, whenFalse: T): T {
  const mediaQuery = window.matchMedia(query);
  const [match, setMatch] = React.useState(!!mediaQuery.matches);

  React.useEffect(() => {
    const handler = () => setMatch(!!mediaQuery.matches);
    mediaQuery.addEventListener('change', handler);
    return () => mediaQuery.removeEventListener('change', handler);
  }, []);

  if (typeof window === 'undefined' || typeof window.matchMedia === 'undefined') return whenFalse;

  return match ? whenTrue : whenFalse;
}

export const useIsMobile = () =>
  useMediaQuery<boolean>(`(min-width: ${defaultTheme.breakpoints.m}px)`, false, true);
