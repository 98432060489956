import SubscriptionUtils from '@zen/utils/subscriptions';
import { useContext } from 'react';
import ProjectContext from '../contexts/project';
import ApiService from '../services/apis';
import { saveLastProjectId } from '../utils/projects';
import useZenRoutes from './useZenRoutes';

const useProject = () => {
  const {
    state: {
      onboardingQuestionnaire,
      shouldDisplayZenIntro,
      info,
      isGeneratingTasks,
      tasks,
      preSirenTaskIds,
      postSirenTaskIds,
      overdueTaskIds,
      todayTaskIds,
      nextFifteenDaysTaskIds,
      laterTaskIds,
      doneTaskIds
    },
    dispatch
  } = useContext(ProjectContext.Context);

  const { goToDashboard } = useZenRoutes();

  const getTaskById = (taskId: number) => tasks.find((task) => task.id === taskId);
  const getTasksByIds = (taskIds: number[]) => taskIds.map((taskId) => getTaskById(taskId));

  const preSirenTasks = getTasksByIds(preSirenTaskIds);
  const postSirenTasks = getTasksByIds(postSirenTaskIds);
  const overdueTasks = getTasksByIds(overdueTaskIds);
  const todayTasks = getTasksByIds(todayTaskIds);
  const nextFifteenDaysTasks = getTasksByIds(nextFifteenDaysTaskIds);
  const laterTasks = getTasksByIds(laterTaskIds);
  const doneTasks = getTasksByIds(doneTaskIds);

  const isZenSubscriptionNeeded = !SubscriptionUtils.isActiveStatus(info.zen_subscription_status);

  const setTasks = (newTasks: TaskItem[]) => {
    dispatch({
      type: 'SET_TASKS',
      payload: newTasks
    });
  };

  const updateTask = (task: TaskItem) => {
    dispatch({
      type: 'UPDATE_TASK',
      payload: task
    });
  };

  const markOnboardingQuestionnaireAsDone = async () => {
    dispatch({
      type: 'SET_IS_GENERATING_TASKS',
      payload: true
    });

    // Wait for mark onboarding questionnaire as done request to be completed
    await ApiService.project(info.id).onboarding.markAsDone();

    dispatch({
      type: 'SET_IS_GENERATING_TASKS',
      payload: false
    });

    goToDashboard({
      searchParams: '?utm_source=questionnaire',
      navigateOptions: { replace: true }
    });
  };

  const patchAnswers = (key: string, value: any) => {
    dispatch({
      type: 'PATCH_ANSWERS',
      payload: {
        key,
        value
      }
    });
  };

  const _getOnboardingQuestionnaire = async (projectId: string) => {
    const _onboardingQuestionnaire = await ApiService.project(Number(projectId)).onboarding.get();

    dispatch({
      type: 'SET_ONBOARDING_QUESTIONNAIRE',
      payload: _onboardingQuestionnaire
    });
  };

  const getProjectInfo = async (projectId: string) => {
    const projectInfo = await ApiService.project(Number(projectId)).getInfo();
    dispatch({
      type: 'SET_PROJECT_INFO',
      payload: projectInfo
    });
    // Save project ID to redirect later
    saveLastProjectId(projectId);

    await _getOnboardingQuestionnaire(projectId);
  };

  const getTasks = async (refresh?: boolean) => {
    const taskList = await ApiService.project(info.id).getTasks(refresh);
    // Store latest tasks in context
    setTasks(taskList);
    return taskList;
  };

  /** a.k.a Ask BE to regenerate the tasks */
  const refreshProjectAndTasks = async () => {
    await getProjectInfo(String(info.id));
    await getTasks(true);
  };

  const hideZenIntro = () => {
    dispatch({ type: 'HIDE_ZEN_INTRO' });
  };

  const logZenRatings = async (data: ZenRatingData) => {
    const ratingEntity = await ApiService.project(info.id).logZenRatings(data);
    // Update the project rating
    dispatch({
      type: 'LOG_ZEN_RATINGS',
      payload: ratingEntity
    });
  };

  return {
    onboardingQuestionnaire,
    shouldDisplayZenIntro,
    info,
    isGeneratingTasks,
    isZenSubscriptionNeeded,
    preSirenTasks,
    postSirenTasks,
    overdueTasks,
    todayTasks,
    nextFifteenDaysTasks,
    laterTasks,
    doneTasks,
    setTasks,
    updateTask,
    patchAnswers,
    getTaskById,
    getTasksByIds,
    markOnboardingQuestionnaireAsDone,
    getProjectInfo,
    getTasks,
    refreshProjectAndTasks,
    hideZenIntro,
    logZenRatings
  };
};

export default useProject;
