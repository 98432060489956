import coreApi from '../coreApi';

const triggerEvent = (serviceId: number, eventSlug: string) =>
  coreApi.post(`/services/${serviceId}/trigger-event/${eventSlug}/`);

const ServicesApiEndpoints = {
  triggerEvent
};

export default ServicesApiEndpoints;
