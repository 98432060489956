import useProject from '@zen/hooks/useProject';
import React from 'react';
import ZenIntroBanner from './ZenIntroBanner';
import ZenUpsellBanner from './ZenUpsellBanner';

/** Store the displayed banner name until the page is reloaded */
let displayedBanner: string | null;

/**
 * There should be only one banner in the dashboard
 * displays by the priority order
 */
const DashboardBannerManager = () => {
  const project = useProject();

  if (project.shouldDisplayZenIntro) {
    displayedBanner = ZenIntroBanner.name;
    return <ZenIntroBanner />;
  }

  // Only display this banner if there hasn't been any other banner
  // already displayed yet within the same session
  if (
    project.isZenSubscriptionNeeded &&
    (!displayedBanner || displayedBanner === ZenUpsellBanner.name)
  ) {
    displayedBanner = ZenUpsellBanner.name;
    return <ZenUpsellBanner />;
  }

  return null;
};

export default DashboardBannerManager;
