import { Column, Text, Title } from '@yolaw/ui-kit-components';
import { InformationBubbleFlat } from '@yolaw/ui-kit-icons';
import { ModalName } from '@zen/contexts/app';
import usePrevious from '@zen/hooks/usePrevious';
import useZenApp from '@zen/hooks/useZenApp';
import { downloadKbisFile, hasDownloadedKbis } from '@zen/utils/projects';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import styled, { css } from 'styled-components';
import DashboardBannerManager from '../components/Banners/DashboardBannerManager';
import DashboardSection from '../components/DashboardSection';
import KbisPreviewer from '../components/KbisPreviewer';
import MessageBox from '../components/MessageBox';
import { WaitingSirenModal, ZenFeedbackModal } from '../components/Modals';
import { CONTENT_MAX_WIDTH } from '../constants';
import useProject from '../hooks/useProject';
import useSegment from '../hooks/useSegment';
import { PATH_SLUGS } from '../services/routes';

const MainContainer = styled.div`
  max-width: ${CONTENT_MAX_WIDTH}px;
  margin-inline: auto;
  position: relative;
`;

const DashboardContainer = styled(Column)`
  ${({ theme }) => css`
    row-gap: ${theme.spacing.xs}px;
    padding: ${theme.spacing.m}px;

    @media (min-width: ${theme.breakpoints.m}px) {
      row-gap: ${theme.spacing.l}px;
    }
  `};
`;

const EmptySectionContainer = styled(Column)`
  flex: 1;
  row-gap: ${({ theme }) => theme.spacing.xxxxs}px;
  a {
    color: inherit;
    font-weight: bold;
  }
`;

const EmptyTodayTaskSection = () => {
  return (
    <EmptySectionContainer>
      <Title type="H4" color="secondary.dark">
        👏 Vous n’avez pas de tâches urgentes à faire pour le moment.
      </Title>
      {/* FIXME: check text and link values */}
      <Text color="neutral.dark">
        En attendant vous pouvez consulter{' '}
        <a href="https://www.legalstart.fr/fiches-pratiques/" target="_blank" rel="noreferrer">
          nos fiches pratiques.
        </a>
      </Text>
    </EmptySectionContainer>
  );
};

const CoverContainer = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 1;

  transition: opacity 0.3s ease-out;

  ${({ theme }) => css`
    @media (min-width: ${theme.breakpoints.m}px) {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      padding: ${theme.spacing.s}px;
    }

    @media (max-width: ${theme.breakpoints.m}px) {
      left: 0;
      top: 0;
    }
  `};

  .kbis-previewer {
    height: 100%;
  }
`;

type KbisPreviewerProps = {
  onDownloadCompleted: () => void;
};

const KbisPreviewerCover = ({ onDownloadCompleted }: KbisPreviewerProps) => {
  const project = useProject();
  const shouldDisplayKbisPreviewCover =
    project.info.has_kbis && !!project.info.kbis_url && !hasDownloadedKbis(project.info.id);

  if (!shouldDisplayKbisPreviewCover) return null;

  const hidePreviewerCover = () => {
    const coverElement = document.getElementById('cover-container');
    if (coverElement) {
      coverElement.style.setProperty('opacity', '0');
      setTimeout(() => {
        coverElement.style.setProperty('display', 'none');
      }, 300);
    }
    onDownloadCompleted();
  };

  return (
    <CoverContainer id="cover-container">
      <KbisPreviewer onDownloadCompleted={hidePreviewerCover} />
    </CoverContainer>
  );
};

const Dashboard = () => {
  const app = useZenApp();
  const project = useProject();
  const navigate = useNavigate();
  const segment = useSegment();
  const [searchParams] = useSearchParams();
  const prevDoneTasks = usePrevious(project.doneTasks) || [];

  const [reDownloadKbisMessageBoxVisible, setReDownloadKbisMessageBoxVisible] = useState(
    project.info.has_kbis && !!project.info.kbis_url && hasDownloadedKbis(project.info.id)
  );

  useEffect(() => {
    project.getTasks();

    segment.track('dashboard: displayed', {
      sub_aj: project.info.aj_subscription_status,
      sub_zen: project.info.zen_subscription_status,
      sub_cs: project.info.cs_subscription_status,
      kbis: project.info.has_kbis,
      from: searchParams.get('utm_source') || 'direct_access'
    });

    return () => {
      // Dismiss any active toast when moving to other route
      toast.dismiss();
    };
  }, []);

  const checkAndDisplayZenFeedbackModal = () => {
    // Don't check if it's currently displayed
    if (app.state.openingModal?.name === ModalName.ZenFeedback) return;

    // 1st trigger: FIRST TASK DONE
    if (
      // If there is 1 done task
      // - first task has been marked as done
      prevDoneTasks.length === 0 &&
      project.doneTasks.length === 1 &&
      // And the user has never gave a rating (either rated or dismissed)
      project.info.ratings.length === 0
    ) {
      app.modal.openModal(ModalName.ZenFeedback, '1st task completed');
    }

    // 2nd trigger: 30 DAYS AFTER FIRST RATING DONE
    // If there is only 1 rating log (first rating)
    if (project.info.ratings.length === 1) {
      const lastRating = project.info.ratings[0];
      // Current moment is same or after 30 days from the first rating date
      if (moment().isSameOrAfter(moment(lastRating.created_at).add(30, 'days'))) {
        app.modal.openModal(ModalName.ZenFeedback, '1st month');
      }
    }
  };

  useEffect(() => {
    checkAndDisplayZenFeedbackModal();
  }, [project.doneTasks.length]);

  const handleUpdateOnboardingAnswersCtaClick = () => {
    navigate(`./${PATH_SLUGS.ONBOARDING}/?utm_source=dashboard`, {
      state: { startFromBeginning: true }
    });
  };

  const reDownloadKbis = () => {
    downloadKbisFile(project.info);
    segment.track(
      'kbis: downloaded',
      { from: 'zen-bottom-banner' },
      { disableEventNamePrefix: true }
    );
  };

  const onDownloadKbisFromPreviewerComplete = () => {
    setReDownloadKbisMessageBoxVisible(true);
  };

  return (
    <MainContainer>
      <DashboardContainer>
        <DashboardBannerManager />

        {project.info.company_siren ? (
          <>
            <DashboardSection title="En retard" tasks={project.overdueTasks} />

            <DashboardSection title="À faire à partir d’aujourd’hui" tasks={project.todayTasks}>
              {project.overdueTasks.length ? null : <EmptyTodayTaskSection />}
            </DashboardSection>

            {/* TODO: enable this when the whole epic is ready */}
            {/* {!project.info.has_aj_subscription && <AJBanner />} */}

            <DashboardSection
              title="À faire dans les 15 prochains jours"
              tasks={project.nextFifteenDaysTasks}
            />

            <MessageBox
              message={
                'Votre situation a changé ? Mettez à jour vos informations pour réadapter vos tâches.'
              }
              Icon={InformationBubbleFlat}
              ctaConfigs={{
                text: 'Mettre à jour',
                rightIcon: 'ArrowRight',
                onClick: handleUpdateOnboardingAnswersCtaClick
              }}
            />

            <DashboardSection title="À faire plus tard" tasks={project.laterTasks} />
          </>
        ) : (
          <>
            <DashboardSection
              title="À faire pendant la création de votre société"
              tasks={project.preSirenTasks}
            />
            <DashboardSection
              title="Disponible une fois votre société créée"
              tasks={project.postSirenTasks}
              InfoBox={
                <MessageBox
                  message={
                    <>
                      <p>Un numéro de Siren est requis pour effectuer les actions suivantes.</p>
                      <p>Elles seront débloquées automatiquement une fois votre société créée.</p>
                    </>
                  }
                  Icon={InformationBubbleFlat}
                />
              }
            />
            <WaitingSirenModal />
          </>
        )}

        {/* Done tasks section is always available */}
        <DashboardSection title="Déjà fait !" tasks={project.doneTasks} />

        <ZenFeedbackModal />

        {reDownloadKbisMessageBoxVisible && (
          <MessageBox
            message={"Vous souhaitez télécharger votre Kbis à nouveau ? C'est par ici !"}
            Icon={InformationBubbleFlat}
            ctaConfigs={{
              text: 'Télécharger',
              leftIcon: 'DownloadFlat',
              onClick: reDownloadKbis
            }}
          />
        )}
      </DashboardContainer>

      <KbisPreviewerCover onDownloadCompleted={onDownloadKbisFromPreviewerComplete} />
    </MainContainer>
  );
};

export default Dashboard;
