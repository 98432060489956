import { loadStripe, StripeConstructorOptions, StripeElementsOptions } from '@stripe/stripe-js';
import config from '../services/config';

const stripeConstructorOptions: StripeConstructorOptions = {
  locale: 'fr-FR'
};

const stripePromise = loadStripe(config.STRIPE_PUBLISHABLE_KEY, stripeConstructorOptions);

const stripeElementOptions: StripeElementsOptions = {
  fonts: [
    {
      family: 'proxima-nova sans-serif',
      src: `url(/fonts/ProximaNova-Reg.woff2)`,
      weight: '500'
    }
  ]
};

const StripeProvider = {
  stripePromise,
  stripeElementOptions
};

export default StripeProvider;
