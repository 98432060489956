import { Column, Row, Text, Title } from '@yolaw/ui-kit-components';
import { ModalName } from '@zen/contexts/app';
import usePrevious from '@zen/hooks/usePrevious';
import useProject from '@zen/hooks/useProject';
import useSegment from '@zen/hooks/useSegment';
import useZenApp from '@zen/hooks/useZenApp';
import React, { useContext, useEffect, useReducer } from 'react';
import styled, { css } from 'styled-components';
import BaseModal from '../BaseModal';
import NotGoodReasonSelector from './NotGoodReasonSelector';
import Context, { RatingStep, ReasonLevel, initialState, reducer } from './context';

const GRADE_LABELS = ['😡', '🙁', '😐', '🙂', '🤩'];

const BodyContainer = styled(Column)`
  align-items: center;
  text-align: center;
  ${({ theme }) => css`
    padding: ${theme.spacing.s}px;
  `}
`;

const ItemContainer = styled.span`
  cursor: pointer;

  ${({ theme }) => css`
    @media (min-width: ${theme.breakpoints.m}px) {
      font-size: 60px;
    }

    @media (max-width: ${theme.breakpoints.m}px) {
      font-size: 40px;
    }
  `}
`;

type GradeItemProps = {
  label: string;
  grade: number;
};
const GradeItem = ({ label, grade }: GradeItemProps) => {
  const { dispatch } = useContext(Context);
  const onClick = () => {
    switch (grade) {
      case 1:
      case 2:
        dispatch({
          ratingStep: RatingStep.NotGoodReasonSelection,
          grade
        });
        break;
      case 3:
      case 4:
      case 5:
        dispatch({
          ratingStep: RatingStep.ThankYou,
          grade
        });
        break;
    }
  };
  return <ItemContainer onClick={onClick}>{label}</ItemContainer>;
};

const GradeSelection = () => {
  return (
    <>
      <Title type="H3">Aidez-nous à nous améliorer 🙏</Title>
      <Text type="BODY">Notez votre expérience avec Zen by Legalstart</Text>
      <Row>
        {GRADE_LABELS.map((item, index) => (
          <GradeItem key={`grade-${index + 1}`} label={item} grade={index + 1} />
        ))}
      </Row>
    </>
  );
};

const ThankYou = () => {
  const app = useZenApp();
  const project = useProject();
  const segment = useSegment();
  const { state } = useContext(Context);

  useEffect(() => {
    segment.track('ratings: completed', {
      grade: state.grade,
      trigger: app.state.openingModal.openedBy,
      sub_zen: project.info.zen_subscription_status,
      kbis: project.info.has_kbis,
      reason_mother: state.notGoodReason?.[ReasonLevel.Mother]?.label,
      reason_child: state.notGoodReason?.[ReasonLevel.Child]?.label,
      comment: state.notGoodReason?.comment
    });

    // Save rating info in the database
    project.logZenRatings({
      type: 'rated',
      grade: state.grade,
      mother_reason: state.notGoodReason?.[ReasonLevel.Mother]?.label,
      child_reason: state.notGoodReason?.[ReasonLevel.Child]?.label,
      comment: state.notGoodReason?.comment
    });
  }, []);

  return (
    <>
      <Title type="H1">🥰</Title>
      <Title type="H3">Merci pour votre avis</Title>
      <Text type="BODY">
        Grâce à vous, d’autres entrepreneurs pourront être accompagnés par Zen by Legalstart !
      </Text>
    </>
  );
};

const ZenFeedbackModal = () => {
  const app = useZenApp();
  const project = useProject();
  const segment = useSegment();
  const prevOpeningModal = usePrevious(app.state.openingModal);
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    // Whenever the modal is opened
    if (app.state.openingModal?.name === ModalName.ZenFeedback) {
      segment.track('ratings: displayed', {
        trigger: app.state.openingModal.openedBy,
        sub_zen: project.info.zen_subscription_status,
        kbis: project.info.has_kbis
      });
    }

    // When the modal is closed
    if (prevOpeningModal?.name === ModalName.ZenFeedback && !app.state.openingModal) {
      // User closes the modal without rating
      if (state.ratingStep !== RatingStep.ThankYou) {
        project.logZenRatings({ type: 'dismissed' });
      }
    }
  }, [app.state.openingModal]);

  const renderBody = () => {
    switch (state.ratingStep) {
      case RatingStep.GradeSelection:
        return <GradeSelection />;
      case RatingStep.NotGoodReasonSelection:
        return <NotGoodReasonSelector />;
      case RatingStep.ThankYou:
        return <ThankYou />;
    }
  };

  return (
    <Context.Provider value={{ state, dispatch }}>
      <BaseModal id={ModalName.ZenFeedback}>
        <BodyContainer>{renderBody()}</BodyContainer>
      </BaseModal>
    </Context.Provider>
  );
};

export default ZenFeedbackModal;
