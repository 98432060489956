import { redirectToLoginPage } from '@zen/services/redirect';
import Cookies from 'js-cookie';
import { COOKIES_KEYS } from '../constants';

const getDomainName = () => window.location.hostname.split('.').slice(-2).join('.');
const lsSharedDomain = `.${getDomainName()}`;

export const setAccessToken = (accessToken: string) =>
  Cookies.set(COOKIES_KEYS.ACCESS_TOKEN, accessToken, { domain: lsSharedDomain });

export const getAccessToken = () => Cookies.get(COOKIES_KEYS.ACCESS_TOKEN);

export const getRefreshToken = () => Cookies.get(COOKIES_KEYS.REFRESH_TOKEN);

const _removeAccessToken = () =>
  Cookies.remove(COOKIES_KEYS.ACCESS_TOKEN, { domain: lsSharedDomain });
const _removeRefreshToken = () =>
  Cookies.remove(COOKIES_KEYS.REFRESH_TOKEN, { domain: lsSharedDomain });

const _removeAuthTokens = () => {
  _removeAccessToken();
  _removeRefreshToken();
};

export const reconnect = () => {
  _removeAuthTokens();
  redirectToLoginPage({ backToCurrentPage: true });
};
