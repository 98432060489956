import LSUser from '@zen/types/user';
import React from 'react';

export enum ModalName {
  ZenFeedback = 'ZEN_FEEDBACK_MODAL',
  ZenSubscriptionPayment = 'ZEN_SUBSCRIPTION_PAYMENT_MODAL',
  WaitingForSiren = 'WAITING_FOR_SIREN_MODAL'
}

type OpeningModal = {
  name: ModalName;
  openedBy?: string;
};

type AppContextState = {
  openingModal: OpeningModal | null;
  userInfo: LSUser.Info | null;
};

const initialState: AppContextState = {
  openingModal: null,
  userInfo: null
};

type OpenModalAction = {
  type: 'OPEN_MODAL';
  payload: OpeningModal;
};

type CloseModalAction = {
  type: 'CLOSE_MODAL';
};

type SetUserInfoAction = {
  type: 'SET_USER_INFO';
  payload: LSUser.Info;
};

type AppDispatchAction = OpenModalAction | CloseModalAction | SetUserInfoAction;

const reducer = (state: AppContextState, action: AppDispatchAction): AppContextState => {
  switch (action.type) {
    case 'OPEN_MODAL': {
      const modalElement = document.getElementById(action.payload.name);
      modalElement?.classList.add('opened');
      return {
        ...state,
        openingModal: action.payload
      };
    }
    case 'CLOSE_MODAL': {
      if (state.openingModal) {
        const modalElement = document.getElementById(state.openingModal.name);
        modalElement?.classList.remove('opened');
      }
      return {
        ...state,
        openingModal: null
      };
    }
    case 'SET_USER_INFO':
      return {
        ...state,
        userInfo: action.payload
      };
    default:
      throw new Error('[AppContext] unexpected action type');
  }
};

type AppContextType = {
  state: AppContextState;
  dispatch: React.Dispatch<AppDispatchAction>;
};

const Context = React.createContext<AppContextType>({
  state: initialState,
  dispatch: null
});

Context.displayName = 'AppContext';

const AppContext = {
  Context,
  initialState,
  reducer
};
export default AppContext;
