import { Row } from '@yolaw/ui-kit-components';
import {
  AmericanExpressV3,
  ApplePayLogoV3,
  GooglePayLogoV3,
  MastercardV3,
  VisaV3
} from '@yolaw/ui-kit-icons';
import { PaymentMethod } from '@zen/services/payment';
import React from 'react';
import styled, { css } from 'styled-components';
import useZenPayment from '../../../../../hooks/useZenPayment';

const CARD_ICON_SIZE = '24px';
const WALLET_ICON_SIZE = '3em';

const StyledRow = styled(Row)`
  ${({ theme }) => css`
    align-items: center;
    column-gap: ${theme.spacing.xxxs}px;

    @media (max-width: ${theme.breakpoints.m}px) {
      column-gap: ${theme.spacing.xs}px;
      justify-content: center;
      width: 100%;
    }
  `}
`;

const PaymentMethodIcons = () => {
  const { availablePaymentMethods } = useZenPayment();

  return (
    <StyledRow>
      <>
        <VisaV3 size={CARD_ICON_SIZE} />
        <MastercardV3 size={CARD_ICON_SIZE} />
        <AmericanExpressV3 size={CARD_ICON_SIZE} />
      </>

      {availablePaymentMethods.includes(PaymentMethod.GooglePay) && (
        <GooglePayLogoV3 size={WALLET_ICON_SIZE} />
      )}
      {availablePaymentMethods.includes(PaymentMethod.ApplePay) && (
        <ApplePayLogoV3 size={WALLET_ICON_SIZE} />
      )}
    </StyledRow>
  );
};

export default React.memo(PaymentMethodIcons);
