import { Dropdown } from '@yolaw/ui-kit-components';
import { PaymentMethod, PaymentOption } from '@zen/services/payment';
import React from 'react';
import useZenPayment from '../../../../../../hooks/useZenPayment';
import { usePaymentModalContext } from '../../../hooks';

type DropdownOptionProps = {
  value: string;
  label: string;
};
const PaymentMethodDropdown = () => {
  const paymentModal = usePaymentModalContext();

  const { availablePaymentMethods } = useZenPayment();

  const getOptions = (): DropdownOptionProps[] => {
    const options: DropdownOptionProps[] = [
      {
        value: PaymentMethod.Card,
        label: 'Carte de crédit ou de débit'
      }
    ];

    if (availablePaymentMethods.includes(PaymentMethod.GooglePay)) {
      options.push({
        value: PaymentMethod.GooglePay,
        label: 'Google Pay'
      });
    }
    if (availablePaymentMethods.includes(PaymentMethod.ApplePay)) {
      options.push({
        value: PaymentMethod.ApplePay,
        label: 'Apple Pay'
      });
    }

    return options;
  };

  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    event.preventDefault();
    const selectedPaymentMethod = event.target.value;
    switch (selectedPaymentMethod) {
      case PaymentMethod.Card:
      case PaymentMethod.GooglePay:
      case PaymentMethod.ApplePay:
        paymentModal.action.setPaymentMethod(selectedPaymentMethod);
        break;
      default:
        throw new Error(
          `[Payment Method Dropdown] unexpected payment method: ${selectedPaymentMethod}`
        );
    }
  };

  const onDropdownClick = () => {
    paymentModal.action.setPaymentOption(PaymentOption.NewPaymentMethod);
  };

  return (
    <div className="payment-method-dropdown" onClick={onDropdownClick} aria-hidden="true">
      <Dropdown
        name="payment-method"
        id="payment-method-dropdown"
        placeholder="Choisissez votre mode de paiement"
        value={paymentModal.state.paymentMethod}
        onChange={handleChange}
        options={getOptions()}
      />
    </div>
  );
};

export default React.memo(PaymentMethodDropdown);
