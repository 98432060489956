import axios, { AxiosRequestConfig } from 'axios';
import { getAccessToken } from '@zen/utils/auth';
import config from '../config';

const coreApi = axios.create({
  baseURL: config.CORE_API_URL
});

const setAuthorizationHeader = (requestConfig: AxiosRequestConfig, accessToken: string) => {
  requestConfig.headers.Authorization = `Bearer ${accessToken}`;
};

coreApi.interceptors.request.use(
  (requestConfig) => {
    const userAccessToken = getAccessToken();
    if (userAccessToken) {
      setAuthorizationHeader(requestConfig, userAccessToken);
    }

    return requestConfig;
  },
  (error) => Promise.reject(error)
);

export default coreApi;
