import { Row, Title } from '@yolaw/ui-kit-components';
import { Arrow } from '@yolaw/ui-kit-icons';
import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import TaskCard from './TaskCard';

const TRANSITION_DURATION = '200ms';
const TRANSITION_TIMING_FUNCTION = 'ease';
const DEFAULT_TRANSITION_CONFIG = `${TRANSITION_DURATION} ${TRANSITION_TIMING_FUNCTION}`;

const Section = styled.section`
  display: flex;
  flex-direction: column;
  > :not(:first-child) {
    margin-top: ${({ theme }) => theme.spacing.xs}px;
  }
`;

type BodyProps = {
  isOpen: boolean;
};

const SectionBody = styled.div<BodyProps>`
  max-height: ${(props) => (props.isOpen ? '3000px' : 0)};
  overflow: ${({ isOpen }) => (isOpen ? 'visible' : 'hidden')};
  transition: max-height ${DEFAULT_TRANSITION_CONFIG};
`;

const StyledArrow = styled(Arrow)`
  transition: transform ${DEFAULT_TRANSITION_CONFIG};

  ${Section}.expanded & {
    transform: rotate(90deg);
  }
`;

const SectionTitle = styled.button`
  display: flex;
  align-items: baseline;
  column-gap: ${(props) => props.theme.spacing.xxs}px;
  padding: 0;
  border: none;
  background: none;
  cursor: pointer;
  color: inherit;
  text-align: left;
`;

const BodyContainer = styled(Row)`
  ${({ theme }) => css`
    justify-content: flex-start;
    @media (min-width: ${theme.breakpoints.m}px) {
      padding-left: ${theme.spacing.xxxs}px;
    }

    @media (max-width: ${theme.breakpoints.m}px) {
      padding-left: ${theme.spacing.xxxxs}px;
    }

    &::before {
      content: '';
      display: block;
      width: 3px;
      background-color: black;
      border-radius: ${theme.borderRadius.xs}px;
    }
  `}
`;

const TaskList = styled.ul`
  flex: 1;
  display: flex;
  flex-direction: column;
  row-gap: ${({ theme }) => theme.spacing.xs}px;
  /* Reset ul styles */
  list-style: none;
  margin: 0;
  padding: 0;
`;

type DashboardSectionProps = {
  /** Section title */
  title: string;
  /** List of the task */
  tasks: TaskItem[];
  /** The box displays between the section title and the section body
   * in order to show some information
   */
  InfoBox?: JSX.Element;
  /** Use the `children` to show content if the section doesn't contain any task (empty).
   * If it's not provided, the whole section is hidden.
   */
  children?: JSX.Element;
};

const DashboardSection = (props: DashboardSectionProps) => {
  const { children, title, tasks, InfoBox } = props;
  const [isOpen, setIsOpen] = useState(true);

  const handleToggleClick = () => setIsOpen(!isOpen);

  const getTaskCountText = () => {
    if (!tasks.length) return '';
    return `(${tasks.length} action${tasks.length > 1 ? 's' : ''})`;
  };

  return tasks.length || children ? (
    <Section className={isOpen ? 'expanded' : 'collapsed'}>
      <SectionTitle onClick={handleToggleClick}>
        <StyledArrow size="14px" />
        <Title type="H4">
          {title} {getTaskCountText()}
        </Title>
      </SectionTitle>
      {InfoBox}
      <SectionBody isOpen={isOpen}>
        <BodyContainer>
          {tasks.length ? (
            <TaskList>
              {tasks.map((task) => (
                <li key={`${task.id}-${task.type.title}`}>
                  <TaskCard taskId={task.id} />
                </li>
              ))}
            </TaskList>
          ) : (
            children
          )}
        </BodyContainer>
      </SectionBody>
    </Section>
  ) : null;
};

export default DashboardSection;
