import { CardNumberElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { CreatePaymentMethodCardData, MetadataParam, PaymentRequest } from '@stripe/stripe-js';
import { useEffect, useState } from 'react';

const useStripePayment = () => {
  const stripe = useStripe();
  const elements = useElements();

  const [stripePaymentRequest, setStripePaymentRequest] = useState<PaymentRequest | null>(null);

  useEffect(() => {
    if (stripe) {
      const pr = stripe.paymentRequest({
        country: 'FR',
        currency: 'eur',
        total: {
          label: 'Default',
          amount: 100, // amount should be in subunit => x100 cents
          pending: true
        },
        requestPayerName: true,
        requestPayerEmail: true,
        disableWallets: ['googlePay', 'browserCard']
      });

      // Check the availability of the Payment Request API.
      pr.canMakePayment().then((result) => {
        if (result) {
          setStripePaymentRequest(pr);
        }
      });
    }
  }, [stripe]);

  /** Check whether the Apple Pay is qualified to make payment or not.
   * Available wallets:
   * - applePay
   * - googlePay
   */
  const canMakeApplePayPayment = () => !!stripePaymentRequest;

  const createStripePaymentMethod = (
    cardData?: CreatePaymentMethodCardData['card'],
    metadata?: MetadataParam
  ) => {
    if (!stripe) return Promise.reject(new Error('Stripe is not ready'));

    if (cardData) {
      return stripe.createPaymentMethod({
        type: 'card',
        card: cardData,
        metadata
      });
    }

    // Get card data from card input form
    if (!elements) {
      throw new Error('missing_stripe_elements');
    }

    const cardNumberElement = elements.getElement(CardNumberElement);
    if (!cardNumberElement) {
      throw new Error('missing_stripe_card_number_elements');
    }

    return stripe.createPaymentMethod({
      type: 'card',
      card: cardNumberElement,
      metadata
    });
  };

  const handleStripeCardAction = async (stripeClientSecret: string) => {
    if (!stripe) return;

    // Let Stripe.js handle the rest of the payment flow.
    return stripe.confirmCardPayment(stripeClientSecret);
  };

  return {
    /** It's only available if amount to pay is larger than 0 and browser can make payment via ApplePay */
    stripePaymentRequest,
    canMakeApplePayPayment,
    createStripePaymentMethod,
    handleStripeCardAction
  };
};

export default useStripePayment;
