import { Text } from '@yolaw/ui-kit-components';
import React from 'react';
import { Navigate, NavigateProps, useLocation } from 'react-router-dom';
import { getLastProjectId } from '../utils/projects';

export const PATH_SLUGS = {
  PROJECTS: 'projects',
  DASHBOARD: 'dashboard',
  ONBOARDING: 'onboarding',
  TASK: 'task'
};

export const PATH_PARAMS = {
  PROJECT_ID: 'projectId',
  TASK_ID: 'taskId'
} as const;

export type TPathParamKeys = keyof typeof PATH_PARAMS;
export type TPathParams = typeof PATH_PARAMS[TPathParamKeys];

const PATH_TEMPLATES_BASE = `/${PATH_SLUGS.PROJECTS}/:${PATH_PARAMS.PROJECT_ID}`;

export const PATH_TEMPLATES = {
  BASE: PATH_TEMPLATES_BASE,
  ONBOARDING: `${PATH_TEMPLATES_BASE}/${PATH_SLUGS.ONBOARDING}`,
  TASK_VIEW: `${PATH_TEMPLATES_BASE}/${PATH_SLUGS.TASK}/:${PATH_PARAMS.TASK_ID}`
};

export const RedirectToProjectView = () => {
  const lastProjectId = getLastProjectId();

  if (lastProjectId) {
    return <Navigate to={`/${PATH_SLUGS.PROJECTS}/${lastProjectId}`} replace={true} />;
  }

  return <Text color="error.main">{'[ERROR] Project not found!'}</Text>;
};

export const NoMatch = () => {
  return <Text color="error.main">{'[ERROR] Incorrect path. Nothing here!'}</Text>;
};

/** A custom Navigate component to keep query params when navigating to other place */
export const NavigateWithQuery = ({ to, ...rest }: NavigateProps) => {
  const { search } = useLocation();
  return <Navigate to={to + search} {...rest} />;
};
