import React, { useEffect, useLayoutEffect } from 'react';
import { Outlet, useParams, useSearchParams } from 'react-router-dom';
import { ZenSubscriptionModal } from './components/Modals';
import { SEARCH_PARAMS } from './constants';
import useProject from './hooks/useProject';
import useZenApp from './hooks/useZenApp';
import { PATH_PARAMS } from './services/routes';
import { setAccessToken } from './utils/auth';

const ProjectInitializer: React.FC = () => {
  const app = useZenApp();
  const project = useProject();

  const urlPathParams = useParams();
  const [searchParams, setSearchParams] = useSearchParams();

  const getUserAndProjectInfo = (projectId: string) => {
    // Try to get user information
    app.user.getUserInfo();
    project.getProjectInfo(projectId);
  };

  useEffect(() => {
    if (searchParams.has(SEARCH_PARAMS.USER_TOKEN)) {
      setAccessToken(searchParams.get(SEARCH_PARAMS.USER_TOKEN));
      searchParams.delete(SEARCH_PARAMS.USER_TOKEN);
    }

    if (searchParams.has(SEARCH_PARAMS.LS_USER_ID)) {
      searchParams.delete(SEARCH_PARAMS.LS_USER_ID);
    }

    if (urlPathParams[PATH_PARAMS.PROJECT_ID]) {
      const projectId = urlPathParams[PATH_PARAMS.PROJECT_ID];
      getUserAndProjectInfo(projectId);
    }

    // Update search params with removal of user related params
    setSearchParams(searchParams);
  }, []);

  const documentClickListener = (event: MouseEvent) => {
    const { target } = event;
    // Click on modal background of an opening modal
    if (
      target instanceof HTMLElement &&
      target.classList.contains('modal-container') &&
      target.classList.contains('opened')
    ) {
      app.modal.closeModal();
    }
  };

  useLayoutEffect(() => {
    document.addEventListener('click', documentClickListener);
    return () => {
      document.removeEventListener('click', documentClickListener);
    };
  }, []);

  if (!project.info || !project.onboardingQuestionnaire) return null;

  return (
    <>
      <Outlet />
      <ZenSubscriptionModal />
    </>
  );
};

export default ProjectInitializer;
