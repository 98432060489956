import React from 'react';

export enum RatingStep {
  GradeSelection,
  NotGoodReasonSelection,
  ThankYou
}

export enum ReasonLevel {
  Mother = 'mother',
  Child = 'child'
}

export type ReasonItem = {
  label: string;
  subItems?: ReasonItem[];
  other?: boolean;
};

type State = {
  ratingStep: RatingStep;
  grade: number | null;
  notGoodReason: {
    [ReasonLevel.Mother]: ReasonItem;
    [ReasonLevel.Child]?: ReasonItem;
    comment?: string;
  } | null;
};
export const initialState: State = {
  ratingStep: RatingStep.GradeSelection,
  grade: null,
  notGoodReason: null
};

type ActionPayload = Partial<State>;

export const reducer = (state: State, payload: ActionPayload): State => {
  return {
    ...state,
    ...payload
  };
};

type ContextType = {
  state: State;
  dispatch: React.Dispatch<ActionPayload>;
};
const Context = React.createContext<ContextType>({
  state: initialState,
  dispatch: null
});
Context.displayName = 'ZenFeedbackModal';

export default Context;
