import { withSentryReactRouterV6Routing } from '@sentry/react';
import { defaultTheme } from '@yolaw/ui-kit-components';
import { FontBase, FontFamily } from '@yolaw/ui-kit-fonts';
import React, { useReducer } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { injectStyle as injectToastifyStyle } from 'react-toastify/dist/inject-style';
import styled, { createGlobalStyle, ThemeProvider } from 'styled-components';
import ZenToastContainer from './components/ZenToastContainer';
import { ELEMENT_IDS } from './constants';
import AppContext from './contexts/app';
import ProjectContext from './contexts/project';
import { AppWithHeader } from './layouts';
import LegalAssistantAvailableArea from './layouts/LegalAssistantAvailableArea';
import { Dashboard, Onboarding, TaskView } from './pages';
import ProjectInitializer from './ProjectInitializer';
import {
  NavigateWithQuery,
  NoMatch,
  PATH_PARAMS,
  PATH_SLUGS,
  PATH_TEMPLATES,
  RedirectToProjectView
} from './services/routes';

injectToastifyStyle();

const GlobalStyles = createGlobalStyle`
  ${FontFamily};
  ${FontBase};

  /* Global Reset */
  *, *:before, *:after {
    box-sizing: inherit;
  }

  body,
  h1, h2, h3, h4, h5, h6,
  blockquote, p, pre,
  dl, dd,
  figure,
  hr,
  fieldset, legend {
    margin:  0;
    padding: 0;
  }
  
  button,
  input,
  optgroup,
  select,
  textarea {
    font-family: inherit;
    font-size: 100%;
    margin: 0;
  }
  /* End Global Reset */

  html {
    height: 100%;
    box-sizing: border-box;
    -webkit-text-size-adjust: 100%;
  }

  body {
    height: 100%;
    background-color: ${(props) => props.theme.colors.tertiary.lighter};
    color: ${(props) => props.theme.colors.secondary.main};
    font-family: 'proxima-nova';
    overflow-x: hidden;
  }

  #lz-app {
    height: 100%;
  }

  ::placeholder {
    color: ${(props) => props.theme.colors.neutral.main};
  }
`;

const ConfettiAnimationContainer = styled.div`
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 11;
`;

const SentryRoutes = withSentryReactRouterV6Routing(Routes);

const App = () => {
  const [appState, appDispatch] = useReducer(AppContext.reducer, AppContext.initialState);
  const [projectState, projectDispatch] = useReducer(
    ProjectContext.reducer,
    ProjectContext.initialState
  );

  return (
    <ThemeProvider theme={defaultTheme}>
      <GlobalStyles />
      <AppContext.Context.Provider value={{ state: appState, dispatch: appDispatch }}>
        <ProjectContext.Context.Provider value={{ state: projectState, dispatch: projectDispatch }}>
          <BrowserRouter>
            <SentryRoutes>
              <Route path={PATH_TEMPLATES.BASE} element={<ProjectInitializer />}>
                <Route path={'*'} element={<AppWithHeader />}>
                  <Route element={<LegalAssistantAvailableArea />}>
                    {/* Dashboard */}
                    <Route index element={<Dashboard />} />
                    <Route
                      path={PATH_SLUGS.DASHBOARD}
                      element={<NavigateWithQuery to={`..`} replace={true} />}
                    />
                  </Route>

                  {/* Onboarding questionnaire */}
                  <Route path={`${PATH_SLUGS.ONBOARDING}/*`} element={<Onboarding />} />
                  {/* TODO: ask BE to remove this unnecessary `/questionnaire` */}
                  <Route
                    path={`${PATH_SLUGS.ONBOARDING}/questionnaire`}
                    element={
                      <NavigateWithQuery to={`../${PATH_SLUGS.ONBOARDING}`} replace={true} />
                    }
                  />
                </Route>

                {/* Task view */}
                <Route path={`${PATH_SLUGS.TASK}`} element={<LegalAssistantAvailableArea />}>
                  <Route path={`:${PATH_PARAMS.TASK_ID}/*`} element={<TaskView />} />
                </Route>
              </Route>

              <Route path="/" element={<RedirectToProjectView />} />
              <Route path={PATH_SLUGS.PROJECTS} element={<RedirectToProjectView />} />
              <Route path="*" element={<NoMatch />} />
            </SentryRoutes>
          </BrowserRouter>
          <ConfettiAnimationContainer id={ELEMENT_IDS.CONFETTI_ANIMATION_CONTAINER} />
        </ProjectContext.Context.Provider>
      </AppContext.Context.Provider>
      <ZenToastContainer />
    </ThemeProvider>
  );
};

export default App;
