import { Text } from '@yolaw/ui-kit-components';
import _isEmpty from 'lodash/isEmpty';
import React, { useEffect } from 'react';
import { Route, Routes, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import TaskViewer from '../components/TaskViewer';
import TaskWithNavigator from '../components/TaskViewer/layouts/TaskWithNavigator';
import TaskCompleteConfirmation from '../components/TaskViewer/TaskCompleteConfirmation';
import useProject from '../hooks/useProject';
import useTask from '../hooks/useTask';
import ApiService from '../services/apis';
import { NoMatch, TPathParams } from '../services/routes';

const TaskView = () => {
  const { projectId, taskId } = useParams<TPathParams>();

  const project = useProject();
  const task = useTask(Number(taskId));

  const getTaskFromServer = async () => {
    const _task = await ApiService.project(Number(projectId)).task(Number(taskId)).getOne();
    project.updateTask(_task);
  };

  useEffect(() => {
    // Local task non-exist or missing task's `details`
    if (_isEmpty(task.details)) {
      // Try getting one single task
      getTaskFromServer();
    }
  }, [task.id]);

  useEffect(() => {
    return () => {
      // Dismiss any active toast when moving to other route
      toast.dismiss();
    };
  }, []);

  if (!projectId || !taskId) return <NoMatch />;

  if (!task.type)
    return (
      <Text color="error.main">{'[TaskView] Bad Task format. Can not identify task type'}</Text>
    );

  if (_isEmpty(task.details))
    return <Text color="error.main">{'[TaskView] Missing task `details`'}</Text>;

  if (task.task_completed_waiting) return <TaskCompleteConfirmation />;

  return (
    <Routes>
      <Route element={<TaskWithNavigator />}>
        <Route path="*" element={<TaskViewer task={task} />} />
      </Route>
    </Routes>
  );
};

export default TaskView;
