import moment from 'moment';
import { useContext } from 'react';
import ProjectContext from '../contexts/project';
import ApiService from '../services/apis';
import useProject from './useProject';
import useZenRoutes from './useZenRoutes';

export type TaskItemExtended = {
  /** Whether user can open the task from dashboard or not?
   * - If it's not locked
   * - If it's locked but task type = document
   */
  isAccessible: boolean;
  /**  Not done yet and due date is before today */
  isOverDue: boolean;
  isPendingSiren: boolean;
  displayTaskCompleteConfirmation: VoidFunction;
  handleTaskAction: (
    action: Task.Action.Slug,
    data?: Task.Action.Request.Data
  ) => Promise<void | Task.Action.Response.Data>;
} & TaskItem;

const useTask = (taskId?: number): TaskItemExtended => {
  const {
    state: {
      info: { id: projectId },
      tasks
    },
    dispatch
  } = useContext(ProjectContext.Context);
  const { getTaskById, info } = useProject();
  const { isOnboardingQuestionnaire, goToDashboard, goToTask } = useZenRoutes();

  const { taskId: taskIdFromUrl } = useZenRoutes();
  const tId = taskId || Number(taskIdFromUrl);

  if (!tId && !isOnboardingQuestionnaire) {
    throw new Error(
      '[Hooks] useTask: it looks like you are using this hook outside of a TaskView, please provide a task ID!'
    );
  }

  const currentTask = getTaskById(tId);

  const isAccessible =
    currentTask && (!currentTask.is_locked || currentTask.type.kind === 'document');
  const isOverDue = currentTask && !currentTask.is_done && moment(currentTask.due_date).isBefore();
  const isPendingSiren =
    currentTask &&
    !currentTask.is_done &&
    currentTask.type.need_siren === true &&
    !info.company_siren;

  const displayTaskCompleteConfirmation = () => {
    dispatch({
      type: 'UPDATE_TASK',
      payload: {
        ...currentTask,
        is_done: true,
        task_completed_waiting: true
      }
    });
  };

  const handleTaskAction = async (
    actionSlug: Task.Action.Slug,
    data?: Task.Action.Request.Data
  ) => {
    const response = await ApiService.project(projectId).task(tId).doAction(actionSlug, data);

    switch (response.redirect_to) {
      case 'dashboard':
        goToDashboard({ navigateOptions: { replace: true }, searchParams: '?utm_source=task' });
        break;
      case 'next_task': {
        const tasksBeforeUpdate = tasks;
        dispatch({
          type: 'SET_TASKS',
          payload: tasksBeforeUpdate.concat(response.generated_tasks)
        });

        const shouldRedirectToTask = response.generated_tasks[0];
        if (shouldRedirectToTask) {
          return goToTask(shouldRedirectToTask.id, { navigateOptions: { replace: true } });
        } else {
          return displayTaskCompleteConfirmation();
        }
      }
      case 'congrats':
        displayTaskCompleteConfirmation();
        break;
      case 'external_url':
        window.open(response.redirect_url);
        break;

      case 'document_generated_successfully':
      default:
        return response;
    }
  };

  return {
    ...currentTask,
    isAccessible,
    isOverDue,
    isPendingSiren,
    displayTaskCompleteConfirmation,
    handleTaskAction
  };
};

export default useTask;
