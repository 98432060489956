import ApiService from '@zen/services/apis';
import { useContext } from 'react';
import AppContext, { ModalName } from '../contexts/app';
import useSegment from './useSegment';

const useZenApp = () => {
  const segment = useSegment();
  const { state, dispatch } = useContext(AppContext.Context);

  const openModal = (modalName: ModalName, openedBy?: string) => {
    dispatch({
      type: 'OPEN_MODAL',
      payload: {
        name: modalName,
        openedBy
      }
    });
  };

  const closeModal = () => {
    dispatch({
      type: 'CLOSE_MODAL'
    });
  };

  const getUserInfo = async () => {
    const info = await ApiService.user().getUserInfo();

    dispatch({
      type: 'SET_USER_INFO',
      payload: info
    });

    const { id, email, first_name, last_name, is_staff } = info;
    segment.identify(id, {
      email,
      first_name,
      last_name,
      is_staff
    });
  };

  return {
    state,
    modal: {
      openModal,
      closeModal
    },
    user: {
      getUserInfo
    }
  };
};

export default useZenApp;
