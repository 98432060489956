import { Column, Spinner, Title } from '@yolaw/ui-kit-components';
import _get from 'lodash/get';
import React, { useEffect } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import QuestionnaireViewer from '../components/TaskViewer/Questionnaire';
import useProject from '../hooks/useProject';
import useSegment from '../hooks/useSegment';

const LoaderText = styled(Title).attrs({ type: 'H3' })``;

const LoaderContainer = styled(Column)`
  align-items: center;
  justify-content: center;
  height: 100vh;
  padding: ${({ theme }) => theme.spacing.m}px;

  > ${LoaderText} {
    max-width: 640px;
    text-align: center;
  }
`;

const WaitingForTaskGeneration = () => {
  return (
    <LoaderContainer>
      <Spinner color="accentuation.main" />
      <LoaderText>
        Super, nous mettons à jour le contenu de Zen pour qu&apos;il soit adapté à votre société !
      </LoaderText>
    </LoaderContainer>
  );
};

const Onboarding = () => {
  const project = useProject();
  const [searchParams, setSearchParams] = useSearchParams();
  const segment = useSegment();
  const location = useLocation();

  useEffect(() => {
    segment.track('onboarding: started', {
      sub_aj: project.info.aj_subscription_status,
      sub_zen: project.info.zen_subscription_status,
      sub_cs: project.info.cs_subscription_status,
      kbis: project.info.has_kbis,
      from: searchParams.get('utm_source') || 'direct_access'
    });

    // Clear search params after use to clean the visible URL
    setSearchParams([]);

    // Hide the chat
    window.$zopim?.livechat.hideAll();
    return () => {
      // Show the chat button
      window.$zopim?.livechat.button.show();
    };
  }, []);

  const shouldStartFromBeginning = _get(location.state, 'startFromBeginning');

  return project.isGeneratingTasks ? (
    <WaitingForTaskGeneration />
  ) : (
    <QuestionnaireViewer
      questions={project.onboardingQuestionnaire.questions}
      resumePageSlug={
        shouldStartFromBeginning ? null : project.onboardingQuestionnaire.current_page
      }
      onComplete={project.markOnboardingQuestionnaireAsDone}
      navigatorVisible={true}
    />
  );
};

export default Onboarding;
