import CONFIG from './config';

const { WEB_APP_DOMAIN, WEB_CMS_URL } = CONFIG;

export const redirectToWebUserSpace = () => {
  window.location.href = `${WEB_APP_DOMAIN}/compte`;
};

type RedirectionOptions = {
  backToCurrentPage?: boolean;
};

export const redirectToLoginPage = (options?: RedirectionOptions) => {
  const webLoginPage = WEB_APP_DOMAIN + '/compte/login/';
  const queryString = options?.backToCurrentPage ? '?next=' + window.location.href : '';

  window.location.replace(webLoginPage + queryString);
};

export const redirectToWebCMS = () => {
  window.location.href = WEB_CMS_URL;
};
