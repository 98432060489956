import _set from 'lodash/set';
import _compact from 'lodash/compact';
import React from 'react';
import moment from 'moment';
import { hasSeenZenIntro, setHasSeenZenIntro } from '@zen/utils/projects';

type ProjectContextState = {
  onboardingQuestionnaire: OnboardingQuestionnaire | null;
  shouldDisplayZenIntro: boolean;
  info: Project;
  tasks: Array<TaskItem>;
  preSirenTaskIds: Array<TaskItem['id']>;
  postSirenTaskIds: Array<TaskItem['id']>;
  overdueTaskIds: Array<TaskItem['id']>;
  todayTaskIds: Array<TaskItem['id']>;
  nextFifteenDaysTaskIds: Array<TaskItem['id']>;
  laterTaskIds: Array<TaskItem['id']>;
  doneTaskIds: Array<TaskItem['id']>;
  isGeneratingTasks: boolean;
};

const initialState: ProjectContextState = {
  onboardingQuestionnaire: null,
  shouldDisplayZenIntro: false,
  info: {
    id: 0,
    company_name: null,
    company_type: null,
    company_siren: '',
    has_kbis: false,
    kbis_url: null,
    zen_subscription_status: 'freemium',
    aj_subscription_status: null,
    cs_subscription_status: null,
    service_id: 0,
    ratings: []
  },
  tasks: [],
  preSirenTaskIds: [],
  postSirenTaskIds: [],
  overdueTaskIds: [],
  todayTaskIds: [],
  nextFifteenDaysTaskIds: [],
  laterTaskIds: [],
  doneTaskIds: [],
  isGeneratingTasks: false
};

type SetOnboardingQuestionnaireAction = {
  type: 'SET_ONBOARDING_QUESTIONNAIRE';
  payload: OnboardingQuestionnaire;
};

type PatchAnswersAction = {
  type: 'PATCH_ANSWERS';
  payload: {
    key: string;
    value: Partial<QuestionnaireAnswers>;
  };
};

type HideZenIntroAction = {
  type: 'HIDE_ZEN_INTRO';
};

type ProjectDispatchAction =
  | SetOnboardingQuestionnaireAction
  | PatchAnswersAction
  | HideZenIntroAction
  | {
      type: 'SET_PROJECT_INFO';
      payload: Project;
    }
  | {
      type: 'SET_TASKS';
      payload: ProjectContextState['tasks'];
    }
  | {
      type: 'UPDATE_TASK';
      payload: TaskItem;
    }
  | {
      type: 'SET_IS_GENERATING_TASKS';
      payload: boolean;
    }
  | {
      type: 'LOG_ZEN_RATINGS';
      payload: ZenRating;
    };

const next7Days = moment().add(7, 'days');
const next15Days = moment().add(15, 'days');

const _isPendingSiren = (task: TaskItem, company_siren: string) =>
  task.type.need_siren === true && !company_siren;

const _isOverdue = (due_date: TaskItem['due_date']) => due_date && moment(due_date).isBefore();

const _isDueInNext7Days = (due_date: TaskItem['due_date']) =>
  moment(due_date).isSameOrBefore(next7Days);

const _isDueInBetweenNext7And15Days = (due_date: TaskItem['due_date']) =>
  moment(due_date).isBetween(next7Days, next15Days, undefined, '(]');

const _isDueAfterNext15Days = (due_date: TaskItem['due_date']) =>
  moment(due_date).isAfter(next15Days);

const _isTaskHasNoDueDate = (task: TaskItem) => !task.due_date;

/** Task available during company creation process (before having SIREN) */
const _extractPreSirenTaskIds = (tasks: TaskItem[], company_siren: string) =>
  _compact(tasks.map((task) => !task.is_done && !_isPendingSiren(task, company_siren) && task.id));

/** Task available after the company is created successfully (after having SIREN) */
const _extractPostSirenTaskIds = (tasks: TaskItem[], company_siren: string) =>
  _compact(tasks.map((task) => !task.is_done && _isPendingSiren(task, company_siren) && task.id));

/** Tasks overdue and not done are listed in "Overdue tasks" section. */
const _extractOverdueTaskIds = (tasks: TaskItem[]) =>
  _compact(tasks.map((task) => !task.is_done && _isOverdue(task.due_date) && task.id));

/** Task due for D-n (overdue and not done) to D+7 are listed on "to-do today" section. */
const _extractTodayTaskIds = (tasks: TaskItem[], company_siren: string) =>
  _compact(
    tasks.map(
      (task) =>
        !task.is_done &&
        !_isOverdue(task.due_date) &&
        _isDueInNext7Days(task.due_date) &&
        !_isPendingSiren(task, company_siren) &&
        task.id
    )
  );

/** Task due for D+8 to D+15 are listed on "À faire dans les 15 prochains jours" section. */
const _extractNextFifteenDaysTaskIds = (tasks: TaskItem[], company_siren: string) =>
  _compact(
    tasks.map(
      (task) =>
        !task.is_done &&
        !_isOverdue(task.due_date) &&
        _isDueInBetweenNext7And15Days(task.due_date) &&
        !_isPendingSiren(task, company_siren) &&
        task.id
    )
  );

/** Task due date is greater than D+15 from today */
const _extractLaterTaskIds = (tasks: TaskItem[], company_siren: string) =>
  _compact(
    tasks.map(
      (task) =>
        !task.is_done &&
        !_isOverdue(task.due_date) &&
        (_isTaskHasNoDueDate(task) ||
          _isDueAfterNext15Days(task.due_date) ||
          _isPendingSiren(task, company_siren)) &&
        task.id
    )
  );

const _extractDoneTaskIds = (tasks: TaskItem[]) =>
  _compact(tasks.map((task) => task.is_done && task.id));

const _groupTasks = (tasks: TaskItem[], company_siren: string) => {
  return {
    preSirenTaskIds: _extractPreSirenTaskIds(tasks, company_siren),
    postSirenTaskIds: _extractPostSirenTaskIds(tasks, company_siren),
    overdueTaskIds: _extractOverdueTaskIds(tasks),
    todayTaskIds: _extractTodayTaskIds(tasks, company_siren),
    nextFifteenDaysTaskIds: _extractNextFifteenDaysTaskIds(tasks, company_siren),
    laterTaskIds: _extractLaterTaskIds(tasks, company_siren),
    doneTaskIds: _extractDoneTaskIds(tasks)
  };
};

const reducer = (
  state: ProjectContextState,
  action: ProjectDispatchAction
): ProjectContextState => {
  switch (action.type) {
    case 'SET_ONBOARDING_QUESTIONNAIRE':
      return {
        ...state,
        onboardingQuestionnaire: action.payload
        // as of now, we don't need to display the zen intro again
        // so use the `hasSeenZenIntro` checker instead
        // shouldDisplayZenIntro: action.payload.status !== 'done'
      };
    case 'PATCH_ANSWERS':
      return {
        ...state,
        onboardingQuestionnaire: {
          ...state.onboardingQuestionnaire,
          answers: _set(
            state.onboardingQuestionnaire.answers,
            action.payload.key,
            action.payload.value
          )
        }
      };
    case 'HIDE_ZEN_INTRO':
      // Set a value in localstorage to check again in the future
      setHasSeenZenIntro(state.info.id);
      return {
        ...state,
        shouldDisplayZenIntro: false
      };
    case 'SET_PROJECT_INFO':
      return {
        ...state,
        info: action.payload,
        shouldDisplayZenIntro: !hasSeenZenIntro(action.payload.id) && !!action.payload.company_siren
      };
    case 'SET_TASKS':
      return {
        ...state,
        tasks: action.payload,
        ..._groupTasks(action.payload, state.info.company_siren)
      };
    case 'UPDATE_TASK': {
      const newTasks = state.tasks.length
        ? state.tasks.map((task) => (task.id === action.payload.id ? action.payload : task)) // There is some task or task list is fetched
        : [action.payload]; // There is no task in the list at all
      return {
        ...state,
        tasks: newTasks,
        ..._groupTasks(newTasks, state.info.company_siren)
      };
    }
    case 'SET_IS_GENERATING_TASKS':
      return {
        ...state,
        isGeneratingTasks: action.payload
      };
    case 'LOG_ZEN_RATINGS':
      return {
        ...state,
        info: {
          ...state.info,
          ratings: [...state.info.ratings, action.payload]
        }
      };
    default:
      throw new Error('[ProjectContext] unexpected action type');
  }
};

type ProjectContextType = {
  state: ProjectContextState;
  dispatch: React.Dispatch<ProjectDispatchAction>;
};

const Context = React.createContext<ProjectContextType>({
  state: initialState,
  dispatch: null
});

Context.displayName = 'ProjectContext';

const ProjectContext = {
  Context,
  initialState,
  reducer
};
export default ProjectContext;
