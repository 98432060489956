import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import React from 'react';
import ReactDOM from 'react-dom';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType
} from 'react-router-dom';
import App from './App';
import { ELEMENT_IDS } from './constants';
import config from './services/config';

Sentry.init({
  dsn: config.SENTRY_DSN,
  environment: config.NODE_ENV,
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(
        React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes
      )
    })
  ],
  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0
});

const { CORE_API_URL, WEB_APP_DOMAIN, LEGALZEN_API_URL, NODE_ENV } = config;

const envJson = {
  CORE_API_URL,
  WEB_APP_DOMAIN,
  LEGALZEN_API_URL
};

const renderApp = () => {
  if (window.location.pathname.startsWith('/env_var_configuration') && NODE_ENV !== 'production') {
    return <p id="env-variables">{JSON.stringify(envJson)}</p>;
  }
  return <App />;
};

ReactDOM.render(renderApp(), document.getElementById(ELEMENT_IDS.APP_CONTAINER));
