import { Label, Row } from '@yolaw/ui-kit-components';
import { ZoomIn, ZoomOut } from '@yolaw/ui-kit-icons';
import { useIsMobile } from '@zen/hooks/useIsMobile';
import React, { useEffect, useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf/dist/esm/entry.webpack5';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import styled, { css } from 'styled-components';

const Container = styled.div`
  overflow: hidden;
  ${({ theme }) => css`
    border: 2px solid ${theme.colors.neutral.lighter};
    border-radius: inherit;
    background: white;
  `}
`;

const ToolBar = styled(Row)`
  justify-content: center;
  align-items: center;

  ${({ theme }) => css`
    border-bottom: 2px solid ${theme.colors.neutral.lighter};
    padding: ${theme.spacing.xxs}px;
  `}
`;

const Viewer = styled.div`
  overflow: scroll;

  ${({ theme }) => css`
    background-color: ${theme.colors.neutral.main};
  `}

  .react-pdf__Document {
    margin-left: auto;
    margin-right: auto;
    width: fit-content;
  }
`;

const VerticalSeparator = styled.div`
  width: 1px;
  height: 2em;
  ${({ theme }) => css`
    background-color: ${theme.colors.neutral.lighter};
  `}
`;

const StyledSelect = styled.select`
  cursor: pointer;
  font-weight: bold;

  &:focus {
    outline: none;
  }

  ${({ theme }) => css`
    padding: ${theme.spacing.xxxxs}px ${theme.spacing.xs}px;
    border-radius: ${theme.borderRadius.m}px;
    border-color: ${theme.colors.neutral.light};
  `}
`;

const StyledButton = styled.button`
  border-width: 0px;
  background-color: transparent;
  cursor: pointer;
  ${({ theme }) => css`
    border-radius: ${theme.borderRadius.s}px;
  `}
`;

const ToolItemContainer = styled(Row)`
  align-items: center;
`;

const PageNumberSelectorContainer = styled(ToolItemContainer)`
  ${({ theme }) => css`
    @media (max-width: ${theme.breakpoints.m}px) {
      label {
        display: none;
      }
    }
  `}
`;

type PageSelectorProps = {
  numPages: number;
  onChange: (value: number) => void;
};
const PageNumberSelector = ({ numPages, onChange }: PageSelectorProps) => {
  const options = [];
  for (let pageIndex = 1; pageIndex <= numPages; pageIndex++) {
    options.push(
      <option key={`page-${pageIndex}`} value={pageIndex}>{`${pageIndex}/${numPages}`}</option>
    );
  }

  return (
    <PageNumberSelectorContainer>
      <Label htmlFor="page-number-selector" fontWeightVariant="bold">
        Page
      </Label>
      <StyledSelect
        id="page-number-selector"
        name="page-number-selector"
        onChange={(e) => onChange(Number(e.target.value))}
      >
        {options}
      </StyledSelect>
    </PageNumberSelectorContainer>
  );
};

const MAX_SCALE = 2;
const MIN_SCALE = 0.5;
const SCALE_STEP = 0.25;

const ScaleSelectorContainer = styled(ToolItemContainer)`
  ${({ theme }) => css`
    @media (max-width: ${theme.breakpoints.m}px) {
      select {
        display: none;
      }
    }
  `}
`;

type ZoomPercentageSelectorProps = {
  onChange: (value: number) => void;
  scale: number;
};
const ScaleSelector = ({ onChange, scale }: ZoomPercentageSelectorProps) => {
  const [scaleValue, setScaleValue] = useState(scale);

  const options = [];
  let scaleOption = MIN_SCALE;
  while (scaleOption <= MAX_SCALE) {
    options.push(
      <option key={`scale-${scaleOption}`} value={scaleOption}>{`${scaleOption * 100}%`}</option>
    );
    scaleOption += SCALE_STEP;
  }

  const onZoomInClick = () => {
    setScaleValue(Math.min(scaleValue + SCALE_STEP, MAX_SCALE));
  };

  const onZoomOutClick = () => {
    setScaleValue(Math.max(scaleValue - SCALE_STEP, MIN_SCALE));
  };

  const onScaleValueSelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setScaleValue(Number(event.target.value));
  };

  useEffect(() => {
    onChange(scaleValue);
  }, [scaleValue]);
  return (
    <ScaleSelectorContainer>
      <StyledButton onClick={onZoomOutClick} disabled={scaleValue === MIN_SCALE}>
        <ZoomOut size="2em" />
      </StyledButton>
      <StyledSelect
        id="scale-selector"
        name="scale-selector"
        value={scaleValue}
        onChange={onScaleValueSelect}
      >
        {options}
      </StyledSelect>
      <StyledButton onClick={onZoomInClick} disabled={scaleValue === MAX_SCALE}>
        <ZoomIn size="2em" />
      </StyledButton>
    </ScaleSelectorContainer>
  );
};

type PdfViewerProps = {
  fileUrl: string;
};
const PdfViewer = ({ fileUrl }: PdfViewerProps) => {
  const isMobile = useIsMobile();
  const [docScale, setDocScale] = useState(isMobile ? 0.5 : 1);
  const [numPages, setNumPages] = useState(1);
  const [pageNumber, setPageNumber] = useState(1);

  const onDocumentLoadedSuccess = async (pdf: pdfjs.PDFDocumentProxy) => {
    setNumPages(pdf.numPages);
  };

  const onPageLoadedSuccess = (pdf: pdfjs.PDFPageProxy) => {
    const viewport = pdf.getViewport();

    const viewer = document.getElementById('pdf-viewer');
    viewer.style.height = `${viewport.viewBox[3]}px`;
  };

  return (
    <Container>
      <ToolBar>
        <ScaleSelector scale={docScale} onChange={setDocScale} />
        <VerticalSeparator />
        <PageNumberSelector numPages={numPages} onChange={setPageNumber} />
      </ToolBar>
      <Viewer id="pdf-viewer">
        <Document file={fileUrl} onLoadSuccess={onDocumentLoadedSuccess}>
          <Page pageNumber={pageNumber} scale={docScale} onLoadSuccess={onPageLoadedSuccess} />
        </Document>
      </Viewer>
    </Container>
  );
};

export default React.memo(PdfViewer);
