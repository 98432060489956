import Subscription from '@zen/types/subscription';
import OnboardingApiHandler from './handlers/onboarding';
import ProjectApiHandler from './handlers/projects';
import ServicesApiHandler from './handlers/services';
import SubscriptionApiHandler from './handlers/subscriptions';
import TaskApiHandler from './handlers/tasks';
import UserApiHandler from './handlers/user';

const taskService = (projectId: Project['id']) => (taskId: TaskItem['id']) => {
  return {
    getOne: () => TaskApiHandler.getOne(projectId)(taskId),
    doAction: (actionSlug: Task.Action.Slug, data?: Task.Action.Request.Data) =>
      TaskApiHandler.doAction(projectId)(taskId, actionSlug, data)
  };
};

const onboardingService = (projectId: Project['id']) => {
  return {
    get: () => OnboardingApiHandler.get(projectId),
    patch: (data: Partial<OnboardingQuestionnaire>) => OnboardingApiHandler.patch(projectId)(data),
    markAsDone: () => OnboardingApiHandler.markAsDone(projectId),
    updateAnswers: (answers: QuestionnaireAnswers) =>
      OnboardingApiHandler.updateAnswers(projectId)(answers)
  };
};

const projectService = (projectId: Project['id']) => {
  return {
    getInfo: () => ProjectApiHandler.getInfo(projectId),
    // Ratings
    logZenRatings: (data: ZenRatingData) => ProjectApiHandler.logZenRatings(projectId, data),
    // Onboarding
    onboarding: onboardingService(projectId),
    // Task
    getTasks: (refresh?: boolean) => TaskApiHandler.getAll(projectId)(refresh),
    task: taskService(projectId)
  };
};

const subscriptionService = (subscriptionTypeSlug: string) => {
  return {
    getSubscriptionDetails: () =>
      SubscriptionApiHandler.getSubscriptionDetails(subscriptionTypeSlug),
    getPlanDetails: (planSlug: string) =>
      SubscriptionApiHandler.getPlanDetails(subscriptionTypeSlug, planSlug),
    initiateStripePayment: ({
      project_id,
      subscription_plan_slug,
      stripe_payment_method_id
    }: Omit<Subscription.StripePayment.InitialRequestData, 'subscription_type_slug'>) =>
      SubscriptionApiHandler.initiateStripePayment({
        project_id,
        subscription_type_slug: subscriptionTypeSlug,
        subscription_plan_slug,
        stripe_payment_method_id
      }),
    confirmStripePayment: ({
      // project_id,
      stripe_payment_intent_id
    }: Subscription.StripePayment.ConfirmRequestData) =>
      SubscriptionApiHandler.confirmStripePayment({
        // project_id,
        stripe_payment_intent_id
      })
  };
};

const userService = () => {
  return {
    getPaymentMethods: () => UserApiHandler.getPaymentMethods(),
    getUserInfo: () => UserApiHandler.getUserInfo(),
    refreshToken: (rfToken: string) => UserApiHandler.refreshToken(rfToken)
  };
};

const servicesService = () => {
  return {
    triggerEvent: ServicesApiHandler.triggerEvent
  };
};

const ApiService = {
  project: projectService,
  subscription: subscriptionService,
  user: userService,
  services: servicesService
};

export default ApiService;
