import html2canvas from 'html2canvas';
import _get from 'lodash/get';
import React, { useContext, useEffect, useRef, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import styled, { css, useTheme } from 'styled-components';
import DocumentPreviewContainer from '../components/DocumentPreviewContainer';
import { DocumentGenerationContext } from '../context';

import { Column } from '@yolaw/ui-kit-components';
import WatermarkSVG from '@zen/assets/svgs/zen-freemium-watermark.svg';
import { ELEMENT_IDS } from '../../../../constants';
import useProject from '@zen/hooks/useProject';
import useDocumentGenerationTask from '../hooks/useDocumentGenerationTask';

const HiddenPreviewContainer = styled.div`
  position: absolute;
  width: 100%;
  max-width: 620px;
  bottom: 100%;
`;

const TweakStyles = styled(Column)`
  ${({ theme }) => css`
    .dynamic-info {
      color: ${theme.colors.accentuation.dark};
      font-weight: bold;
    }

    .signature-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid ${theme.colors.neutral.lighter};
      border-radius: ${theme.borderRadius.s}px;
      width: 180px;
      height: 96px;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      padding: ${theme.spacing.xxxs}px;
    }

    ul {
      margin: 0;
    }
  `};
`;

const findDynamicFields = (template: string) => {
  // Regex to search for the string between "[]". Ex: "[signature|SIGNATURE]"
  const dynRegex = /(\[[^\]]*\])/gm;
  const fields = template.match(dynRegex);
  return fields ? Array.from(fields) : [];
};

const fillData = (template: string, data: Record<string, string>) => {
  const dynamicFields = findDynamicFields(template);
  let newTemplate = template;

  for (const field of dynamicFields) {
    const [, dataKey, placeholder] = field.split(/[[|\]]/);
    const value = _get(data, [dataKey]);

    let replaceValue = `<span class='dynamic-info'>${value || `[${placeholder}]`}</span>`;

    if (dataKey.startsWith('signature')) {
      replaceValue = `<div class='signature-wrapper' ${
        value && `style="background-image: url(${value});"`
      }>${!value ? replaceValue : ''}</div>`;
    }

    newTemplate = newTemplate.replace(field, replaceValue);
  }

  return newTemplate;
};

const watermarkImgElement = () => {
  const img = new Image(); // Create new img element
  img.src = WatermarkSVG; // Set source path
  img.alt = 'Zen Freemium version';
  return img;
};

type ProtectedPreviewProps = {
  template: string;
};
const ProtectedPreview = ({ template }: ProtectedPreviewProps) => {
  const theme = useTheme();
  const canvasRef = useRef<HTMLCanvasElement>(null);

  const wImg = watermarkImgElement();

  const addWatermark = () => {
    const canvas = canvasRef.current;
    if (!canvas || !canvas.getContext) return;

    const context = canvas.getContext('2d', { willReadFrequently: true });
    if (!context) return;

    // Calculate the number of watermark items to draw
    const itemWidth = wImg?.offsetWidth || 300;
    const itemHeight = wImg?.offsetHeight || 200;
    const lines = canvas.height / itemHeight;
    const items = canvas.width / itemWidth + 1;

    for (let line = 0; line < lines; line++) {
      const y = line * itemHeight;
      for (let item = 0; item < items; item++) {
        // Left-shift 50% of `itemWidth` on odd lines
        const x = item * itemWidth - ((line % 2) * itemWidth) / 2;
        // If watermark image is loaded, draw it, otherwise draw text
        if (wImg.naturalWidth) {
          context.drawImage(wImg, x, y);
        } else {
          // Draw the text which is rotated 30 degrees counterclockwise
          context.save();
          context.font = 'bold 30px proxima-nova';
          context.fillStyle = theme.colors.neutral.lighter;
          context.translate(x, y);
          context.rotate((-30 * Math.PI) / 180);
          context.translate(-x, -y);
          context.fillText(wImg.alt, x, y);
          context.restore();
        }
      }
    }
  };

  // Be sure to add watermark after the image is loaded
  wImg.onload = () => addWatermark();

  const previewElm = document.getElementById(`${ELEMENT_IDS.DOC_GEN.PREVIEW_CONTAINER}-markdown`);

  const setCanvas = async () => {
    const canvas = canvasRef.current;
    if (!canvas || !previewElm) return;

    const context = canvas.getContext('2d', { willReadFrequently: true });
    if (!context) return;

    const capturedCanvas = await html2canvas(previewElm, {
      backgroundColor: null,
      scale: 1
    });

    // Update canvas dimensions if the original ones were changed
    if (
      context.canvas.width !== previewElm.clientWidth ||
      context.canvas.height !== previewElm.clientHeight
    ) {
      context.canvas.width = previewElm.clientWidth;
      context.canvas.height = previewElm.clientHeight;
    }

    // Clear old content
    context.clearRect(0, 0, canvas.width, canvas.height);

    // draw captured content on the preview canvas
    context.drawImage(capturedCanvas, 0, 0);

    // Add watermarks
    addWatermark();
  };

  useEffect(() => {
    setCanvas();
  }, [template]);

  useEffect(() => {
    if (canvasRef.current) {
      // Disable right click on preview canvas
      canvasRef.current.addEventListener('contextmenu', (event) => {
        event.preventDefault();
      });
    }
  }, []);

  return (
    <>
      <DocumentPreviewContainer>
        <canvas ref={canvasRef} id={`${ELEMENT_IDS.DOC_GEN.PREVIEW_CONTAINER}-canvas`} />
      </DocumentPreviewContainer>

      <HiddenPreviewContainer>
        <TweakStyles id={`${ELEMENT_IDS.DOC_GEN.PREVIEW_CONTAINER}-markdown`}>
          <ReactMarkdown rehypePlugins={[rehypeRaw]}>{template}</ReactMarkdown>
        </TweakStyles>
      </HiddenPreviewContainer>
    </>
  );
};

const DocumentPreview = () => {
  const {
    state: { formData }
  } = useContext(DocumentGenerationContext);

  const project = useProject();
  const docGenTask = useDocumentGenerationTask();

  const decryptedTemplate = docGenTask.getDecryptedDocumentTemplate();

  const [template, setTemplate] = useState(decryptedTemplate);

  useEffect(() => {
    if (formData) {
      setTemplate(fillData(decryptedTemplate, formData));
    }
  }, [formData]);

  return project.isZenSubscriptionNeeded ? (
    <ProtectedPreview template={template} />
  ) : (
    <DocumentPreviewContainer>
      <TweakStyles>
        <ReactMarkdown rehypePlugins={[rehypeRaw]}>{template}</ReactMarkdown>
      </TweakStyles>
    </DocumentPreviewContainer>
  );
};

export default DocumentPreview;
