import axios, { AxiosRequestConfig } from 'axios';
import ApiService from '.';
import * as AuthUtils from '../../utils/auth';
import config from '../config';

const zenstartApi = axios.create({
  baseURL: config.LEGALZEN_API_URL
});

const setAuthorizationHeader = (requestConfig: AxiosRequestConfig, accessToken: string) => {
  requestConfig.headers.Authorization = `Bearer ${accessToken}`;
};

zenstartApi.interceptors.request.use(
  (requestConfig) => {
    const userAccessToken = AuthUtils.getAccessToken();
    if (userAccessToken) {
      setAuthorizationHeader(requestConfig, userAccessToken);
    }

    return requestConfig;
  },
  (error) => Promise.reject(error)
);

zenstartApi.interceptors.response.use(
  (response) => response,
  async (error) => {
    // Handle unauthorized request (status === 401)
    if (error.response?.status === 401) {
      const errorCode = error.response.data.code;
      const refreshToken = AuthUtils.getRefreshToken();

      // Refresh token if the error code is `token_not_valid`
      if (errorCode === 'token_not_valid' && refreshToken) {
        // Try to request a new access token
        const response = await ApiService.user().refreshToken(refreshToken);

        if (response.access) {
          // Save the access token into cookies
          AuthUtils.setAccessToken(response.access);

          // Set the new access token to the original request headers
          setAuthorizationHeader(error.config, response.access);

          // Retry original request
          return zenstartApi.request(error.config);
        }
      }
      return AuthUtils.reconnect();
    }

    return Promise.reject(error);
  }
);

export default zenstartApi;
