import { SeeMore } from '@yolaw/ui-kit-components';
import React, { useEffect } from 'react';
import styled, { useTheme } from 'styled-components';
import { ELEMENT_IDS } from '../../../../constants';

const SEE_MORE_BUTTON_HEIGHT = '80px';

const SeeMoreButtonContainer = styled.div`
  > div {
    height: ${SEE_MORE_BUTTON_HEIGHT};
  }
`;

const BelowTheFold = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
`;

type MoreInfoProps = {
  seeMoreText: string;
  children: JSX.Element;
};

const MoreInfo = ({ seeMoreText, children }: MoreInfoProps): JSX.Element => {
  const theme = useTheme();
  useEffect(() => {
    const form = document.getElementById(ELEMENT_IDS.QUESTIONNAIRE.FORM);
    const taskNavigator = document.getElementById(ELEMENT_IDS.TASK_VIEW.NAVIGATOR);
    form.style.minHeight = `calc(100vh - ${taskNavigator.clientHeight}px - ${SEE_MORE_BUTTON_HEIGHT} - ${theme.spacing.xs}px)`;
  }, []);

  const scrollToSeeMoreBlock = () => {
    const element = document.querySelector(`#below-the-fold`);
    element.scrollIntoView({ block: 'start', behavior: 'smooth' });
  };

  return (
    <>
      <SeeMoreButtonContainer>
        <SeeMore onClick={scrollToSeeMoreBlock} text={seeMoreText} visibility />
      </SeeMoreButtonContainer>

      <BelowTheFold id="below-the-fold">{children}</BelowTheFold>
    </>
  );
};

export default MoreInfo;
