/* eslint-disable @typescript-eslint/no-namespace */
export namespace Subscription {
  type PeriodUnit = 'day' | 'week' | 'month' | 'year';
  export interface Plan {
    id: number;
    slug: string;
    type_slug: string;
    period: number;
    period_unit: PeriodUnit;
    price_et: number;
    price_it: number;
    trial_period: number;
    trial_period_unit: PeriodUnit;
  }

  export interface SubscriptionItem {
    id: number;
    slug: string;
    name: string;
    description: string;
    icon: string;
    version: number;
    plans: Array<Plan>;
  }

  export enum SubscriptionStatus {
    // Active statuses
    Active = 'active',
    Incomplete = 'incomplete',
    PastDue = 'past_due',
    PausedCollection = 'paused_collection',
    Trialing = 'trialing',
    Unpaid = 'unpaid',

    // Inactive statuses
    Canceled = 'canceled',
    IncompleteExpired = 'incomplete_expired',
    Freemium = 'freemium' // special case for Zen
  }

  export namespace StripePayment {
    export type InitialRequestData = {
      project_id: Project['id'];
      subscription_type_slug: string;
      subscription_plan_slug: string;
      stripe_payment_method_id: string;
    };

    export interface SucceedResponse {
      subscription_status: SubscriptionStatus;
    }

    export interface RequiresActionResponse {
      stripe_client_secret?: string;
    }
    export type InitialRequestResponse = SucceedResponse & RequiresActionResponse;

    export type ConfirmRequestData = {
      stripe_payment_intent_id: string;
    };
  }
}

export default Subscription;
