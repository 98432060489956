import { Button, Column, Row, Text, Title } from '@yolaw/ui-kit-components';
import dancingLockSrc from '@zen/assets/animation/dancingLock.json';
import lottie from 'lottie-web';
import React, { useEffect } from 'react';
import styled, { css } from 'styled-components';
import { ModalName } from '../../contexts/app';
import { useIsMobile } from '@zen/hooks/useIsMobile';
import useZenApp from '@zen/hooks/useZenApp';

const Thumbnail = styled.div`
  width: 100px;
  margin: -${({ theme }) => theme.spacing.xxxs}px;
`;

const BoxContainer = styled(Row)`
  ${({ theme }) => css`
    align-items: center;
    color: ${theme.colors.secondary.main};
    border: 2px solid ${theme.colors.accentuation.dark};
    border-radius: ${theme.borderRadius.m}px;
    box-shadow: ${theme.shadows.purple.medium};
    padding: ${theme.spacing.s}px;
    justify-content: flex-start;
    column-gap: ${theme.spacing.xs}px;

    @media (max-width: ${theme.breakpoints.m}px) {
      justify-content: center;
      flex-direction: column;
      > button {
        width: 100%;
      }
    }
  `}
`;

const BannerTitle = () => <Title type="H3">Débloquez tout le potentiel de Zen</Title>;

const ZenUpsellBanner = () => {
  const app = useZenApp();
  const isMobile = useIsMobile();

  const handleSubscribeZenClick = () => {
    app.modal.openModal(ModalName.ZenSubscriptionPayment, 'banner');
  };

  useEffect(() => {
    const animatedCrownElement = document.getElementById('animated-thumbnail');
    lottie.loadAnimation({
      container: animatedCrownElement,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData: dancingLockSrc
    });
  }, []);

  return (
    <BoxContainer>
      <Row>
        <Thumbnail id="animated-thumbnail" />
        {isMobile && <BannerTitle />}
      </Row>
      <Column style={{ rowGap: 0, flex: 1 }}>
        {!isMobile && <BannerTitle />}
        <Text type="BODY" color="neutral.dark">
          Toutes vos obligations administratives au même endroit, la génération automatique de vos
          documents pré-complétés et du contenu pour votre rémunération...
        </Text>
      </Column>
      <Button
        text="Débloquer"
        rightIcon="ArrowRight"
        sizing="medium"
        onClick={handleSubscribeZenClick}
      />
    </BoxContainer>
  );
};

export default ZenUpsellBanner;
